<div class="docs-primary-header component-page-header">
  <button mat-button class="sidenav-toggle">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="row-display">
    <h1 class="width40P">Adjustments Status Summary</h1>
    <div class="period-date period-date-placement width60P"><button mat-raised-button (click)="exportCurrentViewTables(viewType)">Export as Excel</button> &nbsp;&nbsp; Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy' }}</div>
  </div>
</div>
<div class="content-padding-24">

  <!-- View Row Starts -->

  <div class="row-display">
    <div class="width20P">
      <mat-form-field>
        <mat-select placeholder="View" [(ngModel)]="viewType" name="viewType" (selectionChange)="viewSelectionChange($event)" userSessionExists>
          <mat-option value="Current">Current</mat-option>
          <mat-option value="History">History</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="new-request-btns width80P" *ngIf="utilsService.tier | async as tier">
      <ng-container *ngIf="(tier.indexOf(Tiers.Tier4)>-1 || tier.indexOf(Tiers.Tier2) > -1) && viewType=='Current'">
        <button mat-raised-button (click)="newRequestRoute(e, AdjustmentType.Deferral)" >New Deferral</button>
        &nbsp;&nbsp;
        <button mat-raised-button (click)="newRequestRoute(e, AdjustmentType.Accrual)" >New Accrual</button>
        &nbsp;&nbsp;
        <button mat-raised-button (click)="newRequestRoute(e, AdjustmentType.BusinessUnitAdjustment)" >New Business Unit Correction</button>
        &nbsp;&nbsp;
        <button mat-raised-button (click)="newRequestRoute(e, AdjustmentType.BusinessUnitAdjustmentTransfer)" >New Business Unit Transfer</button>
        &nbsp;&nbsp;
        <button mat-raised-button (click)="newRequestRoute(e, AdjustmentType.MiscellaneousIncome)" >New Miscellaneous Income</button>
      </ng-container>

      <ng-container *ngIf="viewType=='History'">
        <mat-form-field>
          <mat-select [placeholder]="isHistoryYearOptionDisabled ? 'No records found' : 'Year'" [(ngModel)]="historyYear" name="historyYear" [disabled]="isHistoryYearOptionDisabled" (selectionChange)="yearSelectionChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of historyYearOptions; let in=index" [value]="currentVal">
              {{ currentVal }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;
        <mat-form-field>
          <mat-select [placeholder]="isHistoryMonthOptionDisabled ? 'No records found': 'Month'" [(ngModel)]="historyMonth" name="historyMonth" [disabled]="isHistoryMonthOptionDisabled" (selectionChange)="monthSelectionChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of historyMonthOptions; let in=index" [value]="currentVal">
              {{ currentVal }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;
        <button mat-raised-button (click)="getHistoryRecordsBtnClick($event)" >Get History Records</button>
      </ng-container>
    </div>
  </div>

  <!-- View Row Ends -->

  <!-- Search Row Starts -->

  <form #searchForm="ngForm">
    <div class="row-display search-row">

      <!-- <fieldset class="width100P search-fieldset">

      <legend>Search</legend> -->


      <mat-form-field class="width20P field-with-hint">
        <input matInput placeholder="Transaction Number" [matAutocomplete]="transactionNumberAutoComplete" #transactionNumber="ngModel"
          [(ngModel)]="searchTransactionNumber" name="searchTransactionNumber" (ngModelChange)="autoCompleteTransactionNumbers()"
          (blur)="transactionNumberBlur($event)" userSessionExists>
        <mat-hint *ngIf="isTransactionNumberOptionsExists">Please type any 3 letters to search transaction</mat-hint>
        <mat-hint *ngIf="!isTransactionNumberOptionsExists">No transactions found</mat-hint>
      </mat-form-field>

      <mat-autocomplete #transactionNumberAutoComplete="matAutocomplete">
        <mat-option *ngFor="let trNo of transactionNumberOptions | async" [value]="trNo.transactionNumber">
          {{trNo.transactionNumber}}
        </mat-option>
      </mat-autocomplete>

      <mat-form-field class="width40P field-with-hint">
        <input matInput placeholder="Insured Name" [matAutocomplete]="insuredAutoComplete" #insuredName="ngModel" [(ngModel)]="searchInsuredName"
          name="searchInsuredName" (ngModelChange)="autoCompleteInsureds()" userSessionExists>
        <mat-hint *ngIf="isInsuredOptionsExists">Please type any 3 letters to search insured</mat-hint>
        <mat-hint *ngIf="!isInsuredOptionsExists">No data found</mat-hint>
      </mat-form-field>

      <mat-autocomplete #insuredAutoComplete="matAutocomplete">
        <mat-option *ngFor="let insured of insuredOptions | async" [value]="insured.name">
          {{insured.name}}
        </mat-option>
      </mat-autocomplete>

      <!-- Adjustment Filter dropdown -->
      <div class="width20P">
        <mat-form-field>
          <mat-select placeholder="Adjustment Type" [(ngModel)]="searchAdjustmentType" name="adjustmentFilter" multiple required userSessionExists>
            <mat-option [value]="1">Accrual</mat-option>
            <mat-option [value]="2">Deferral</mat-option>
            <mat-option [value]="3">Business Unit Correction</mat-option>
            <mat-option [value]="4">Business Unit Transfer</mat-option>
            <mat-option [value]="5">Miscellaneous Income</mat-option>
          </mat-select>
          <mat-error>Please select adjustment type</mat-error>
        </mat-form-field>
      </div>

      <div class="width20P search-btn-sect new-request-btns">
        <button mat-raised-button class="search-btn" (click)="searchRecordsBtnClick($event, searchForm)" >Search</button>
        &nbsp;
        <button mat-raised-button (click)="resetSearchFields($event)" >Clear</button>
      </div>

      <!-- </fieldset> -->

    </div>
  </form>

  <!-- Search Row Ends -->

  <mat-accordion [multi]='true'>

    <!-- Current View Starts -->

    <mat-expansion-panel *ngIf="(viewType=='Current')" [expanded]='true'>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Adjustments to Review
        </mat-panel-title>
      </mat-expansion-panel-header>
            
      <table mat-table [dataSource]="statusesToReviewSummary" class="mat-elevation-z8 summaryTable">
          <ng-container>  
              <ng-container matColumnDef="requestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let element; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.requestId}}</td>
              </ng-container>
              
              <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.type}} </td>
              </ng-container>
        
              <ng-container matColumnDef="insuredName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Insured </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.insuredName}} </td>
              </ng-container>
        
              <ng-container matColumnDef="currency">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.currency}} </td>
              </ng-container>
        
              <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.amount | amountPipe}} </td>
              </ng-container>
        
              <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.description}} </td>
              </ng-container>
        
              <ng-container matColumnDef="costCentre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
                  <td mat-cell *matCellDef="let element ; let i = index" > {{element.costCentre}} </td>
              </ng-container>              
        
              <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.status}} </td>
              </ng-container>
        
              <ng-container matColumnDef="processedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Processed By </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusToReviewspans)" [style.display]="getRowSpan('Id', i, statusToReviewspans) ? '' : 'none'"> {{element.processedBy}} </td>
              </ng-container>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row" [ngClass]="'active'+row.requestId" (click)='viewSummary($event, row)' (mouseenter)="AddBackground(row)" (mouseleave)="RemoveBackground(row)"></tr>
      </table>
      <div *ngIf="!isRecordsFound(statusesToReviewSummary)" class="adjustment-table-no-record-msg">No Records Found.</div>
      <mat-paginator #statusInReview [length]='statusInReveiwPaginationOptions.totalCount' [pageSize]='statusInReveiwPaginationOptions.pageSize' (page)="adjustMentToReviewPageEvent($event)"></mat-paginator>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="viewType=='Current'" [expanded]='true'>

      <mat-expansion-panel-header>
        <mat-panel-title>
          Adjustments in Progress
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="statusesInProgressSummary" class="mat-elevation-z8 summaryTable">
          <ng-container>  
              <ng-container matColumnDef="requestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let element; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.requestId}}</td>
              </ng-container>
              
              <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.type}} </td>
              </ng-container>
        
              <ng-container matColumnDef="insuredName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Insured </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.insuredName}} </td>
              </ng-container>
        
              <ng-container matColumnDef="currency">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.currency}} </td>
              </ng-container>
        
              <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.amount | amountPipe}} </td>
              </ng-container>
        
              <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.description}} </td>
              </ng-container>
        
              <ng-container matColumnDef="costCentre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
                  <td mat-cell *matCellDef="let element ; let i = index" > {{element.costCentre}} </td>
              </ng-container>
        
              
        
              <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.status}} </td>
              </ng-container>
        
              <ng-container matColumnDef="processedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Processed By </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, statusInProgressspans)" [style.display]="getRowSpan('Id', i, statusInProgressspans) ? '' : 'none'"> {{element.processedBy}} </td>
              </ng-container>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row" (click)='viewSummary($event, row)'[ngClass]="'active'+row.requestId" (mouseenter)="AddBackground(row)" (mouseleave)="RemoveBackground(row)"></tr>
      </table>


      <div *ngIf="!isRecordsFound(statusesInProgressSummary)" class="adjustment-table-no-record-msg">No Records Found.</div>
      <mat-paginator #statusInProgress [length]="statusInProgressPaginationOptions.totalCount" [pageSize]='statusInProgressPaginationOptions.pageSize' (page)="adjustMentInProgressPageEvent($event)"></mat-paginator>
    </mat-expansion-panel>

    <!-- Current View Ends -->

    <!-- Impact View Starts -->

    <mat-expansion-panel *ngIf="viewType=='Current'">

      <mat-expansion-panel-header>
        <mat-panel-title>Impact</mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="width100P field-with-hint">
        <input matInput (keyup)="filterBusinessUnit($event.target.value)" placeholder="Business Unit">
      </mat-form-field>
      <div class="table-sticky-header table-impact-view">
        <table mat-table [dataSource]="filteredImpactViewData" class="mat-elevation-z8 impact-view-table">

          <ng-container matColumnDef="businessUnit">
            <th mat-header-cell *matHeaderCellDef> Business Unit </th>
            <td mat-cell *matCellDef="let element"> {{element.businessUnit}} </td>
          </ng-container>

          <ng-container matColumnDef="settCurrency">
            <th mat-header-cell *matHeaderCellDef> Sett. Currency </th>
            <td mat-cell class="center-col" *matCellDef="let element"> {{element.settCurrency}} </td>
          </ng-container>

          <ng-container matColumnDef="curBudgeSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewCurrentMonth ? impactViewCurrentMonth+ '- Budget (Sett)' : 'Budget (Sett)' }}  </th>
              <td mat-cell class="current-month-cols number-col" *matCellDef="let element"> {{element.curBudgeSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="curSystemRevenueSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewCurrentMonth ? impactViewCurrentMonth+ '- System Revenue (Sett)' : 'System Revenue (Sett)' }}  </th>
              <td mat-cell class="current-month-cols number-col" *matCellDef="let element"> {{element.curSystemRevenueSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="curAdjustedRevenueSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewCurrentMonth ? impactViewCurrentMonth+ ' - Adjusted Revenue (Sett)' : 'Adjusted Revenue (Sett)'  }} </th>
              <td mat-cell class="current-month-cols number-col" *matCellDef="let element"> {{element.curAdjustedRevenueSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="curVarianceSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{impactViewCurrentMonth ? impactViewCurrentMonth+ '- Variance' : 'Variance' }} </th>
              <td mat-cell class="current-month-cols number-col" *matCellDef="let element"> {{element.curVarianceSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="nextBudgeSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewNextMonth ? impactViewNextMonth+ ' - Budget (Sett) ' : 'Budget (Sett)' }}</th>
              <td mat-cell class="next-month-cols number-col" *matCellDef="let element"> {{element.nextBudgeSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="nextSystemRevenueSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewNextMonth ? impactViewNextMonth+' - System Revenue (Sett)' : 'System Revenue (Sett)' }} </th>
              <td mat-cell class="next-month-cols number-col" *matCellDef="let element"> {{element.nextSystemRevenueSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="nextAdjustedRevenueSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewNextMonth ? impactViewNextMonth+ '- Adjusted Revenue (Sett)' : 'Adjusted Revenue (Sett)'}} </th>
              <td mat-cell class="next-month-cols number-col" *matCellDef="let element"> {{element.nextAdjustedRevenueSett}} </td>
            </ng-container>
  
            <ng-container matColumnDef="nextVarianceSett">
              <th mat-header-cell class="" *matHeaderCellDef> {{ impactViewNextMonth ? impactViewNextMonth+ ' - Variance' : 'Variance' }} </th>
              <td mat-cell class="next-month-cols number-col" *matCellDef="let element"> {{element.nextVarianceSett}} </td>
            </ng-container>

          <tr mat-header-row *matHeaderRowDef="impactViewDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: impactViewDisplayedColumns;"></tr>
        </table>
        <div *ngIf="impactViewData?.length == 0" class="adjustment-table-no-record-msg">No Records Found.</div>
      </div>
      

    </mat-expansion-panel>

    <!-- Impact View Ends -->

    <!-- History View Starts -->

    <mat-expansion-panel *ngIf="viewType=='History'" [expanded]='true'>

      <mat-expansion-panel-header>
        <mat-panel-title>
          History Adjustments Approved
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="statusesHistoryApprovedSummary" class="mat-elevation-z8 summaryTable">
        <ng-container>  
          <ng-container matColumnDef="requestId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.requestId}}</td>
          </ng-container>
          
          <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.type}} </td>
          </ng-container>
    
          <ng-container matColumnDef="insuredName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Insured </th>
              <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.insuredName}} </td>
          </ng-container>
    
          <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
              <td mat-cell *matCellDef="let element ; let i = index"> {{element.currency}} </td>
          </ng-container>
    
          <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
              <td mat-cell *matCellDef="let element ; let i = index"> {{element.amount | amountPipe}} </td>
          </ng-container>
    
          <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
              <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.description}} </td>
          </ng-container>
    
          <ng-container matColumnDef="costCentre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
              <td mat-cell *matCellDef="let element ; let i = index"> {{element.costCentre}} </td>
          </ng-container>
    
          
    
          <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.status}} </td>
          </ng-container>
    
          <ng-container matColumnDef="processedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Processed By </th>
              <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, approvedHistoryAdjSapns)" [style.display]="getRowSpan('Id', i, approvedHistoryAdjSapns) ? '' : 'none'"> {{element.processedBy}} </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row" (click)='viewSummary($event, row)'[ngClass]="'active'+row.requestId" (mouseenter)="AddBackground(row)" (mouseleave)="RemoveBackground(row)"></tr>  
      </table>

      <div *ngIf="!isRecordsFound(statusesHistoryApprovedSummary)" class="adjustment-table-no-record-msg">No Records Found.</div>
      <mat-paginator #historyApprovedSummary [length]='statusHistoryApprovedSummaryOptions.totalCount' [pageSize]='statusHistoryApprovedSummaryOptions.pageSize' (page)='statusHistoryApprovedSummaryPageEvent($event)'></mat-paginator>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="viewType=='History'" [expanded]='true'>

      <mat-expansion-panel-header>
        <mat-panel-title>
          History Adjustments Others
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="statusesHistoryOthersSummary" class="mat-elevation-z8 summaryTable">
          <ng-container>  
              <ng-container matColumnDef="requestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let element; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.requestId}}</td>
              </ng-container>
              
              <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.type}} </td>
              </ng-container>
        
              <ng-container matColumnDef="insuredName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Insured </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.insuredName}} </td>
              </ng-container>
        
              <ng-container matColumnDef="currency">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.currency}} </td>
              </ng-container>
        
              <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.amount | amountPipe}} </td>
              </ng-container>
        
              <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.description}} </td>
              </ng-container>
        
              <ng-container matColumnDef="costCentre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
                  <td mat-cell *matCellDef="let element ; let i = index"> {{element.costCentre}} </td>
              </ng-container>
        
              
        
              <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.status}} </td>
              </ng-container>
        
              <ng-container matColumnDef="processedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Processed By </th>
                  <td mat-cell *matCellDef="let element ; let i = index" [attr.rowspan]="getRowSpan('Id',i, otherHistoryAdjSpans)" [style.display]="getRowSpan('Id', i, otherHistoryAdjSpans) ? '' : 'none'"> {{element.processedBy}} </td>
              </ng-container>
            </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row" (click)='viewSummary($event, row)' [ngClass]="'active'+row.requestId" (mouseenter)="AddBackground(row)" (mouseleave)="RemoveBackground(row)"></tr>
      </table>
      
      <div *ngIf="!isRecordsFound(statusesHistoryOthersSummary)" class="adjustment-table-no-record-msg">No Records Found.</div>
      <mat-paginator #historyOthersSummary [length]='statusHistoryOtherSummaryOptions.totalCount' [pageSize]='statusHistoryOtherSummaryOptions.pageSize' (page)='statusHistoryOtherSummaryPageEvent($event)'></mat-paginator>
    </mat-expansion-panel>

    <!-- History View Ends -->

  </mat-accordion>

</div>
