<header class="docs-header-background">
  <div class="docs-header-section">
    <div class="docs-header-headline">
      <h1 class="mat-h1">Accelerator Data Capture</h1>
    </div>
    <!-- <div class="docs-header-start">
      <a mat-raised-button class="docs-button" *ngIf="!isAuthenticated" (click)="utilsService.login()" routerLink="/login">Login</a>
      <a mat-raised-button class="docs-button" *ngIf="isAuthenticated" (click)="utilsService.logout()">Logout</a>
    </div> -->
  </div>
</header>

<div class="docs-homepage-promo">
  <div class="docs-homepage-row">
    <div class="docs-homepage-promo-img">
      <app-svg-viewer src="../../../assets/CP010_COSMOS-11.svg" scaleToContainer="true"></app-svg-viewer>
    </div>
    <div class="docs-homepage-promo-desc">
      <h2>Deferrals</h2>
      <p>An adjustment to postpone revenue that has been processed and earned to future period(s)</p>
    </div>
  </div>
  <div class="docs-homepage-row docs-homepage-reverse-row">
    <div class="docs-homepage-promo-img">
      <app-svg-viewer src="../../../assets/CP010_COSMOS-11.svg" scaleToContainer="true"></app-svg-viewer>
    </div>
    <div class="docs-homepage-promo-desc">
      <h2>Accruals</h2>
      <p>An adjustment for revenues that have been earned but not yet processed on the system</p>
    </div>
  </div>
  <div class="docs-homepage-row">
    <div class="docs-homepage-promo-img">
      <app-svg-viewer src="../../../assets/CP010_COSMOS-11.svg" scaleToContainer="true"></app-svg-viewer>
    </div>
    <div class="docs-homepage-promo-desc">
      <h2>Business Unit Adjustments</h2>
      <p>The only other adjustment that has been put forward as a requirement from the business is the ability to change the
        business unit that revenue has been allocated to</p>
    </div>
  </div>
  <div class="docs-homepage-row docs-homepage-reverse-row">
    <div class="docs-homepage-promo-img">
      <app-svg-viewer src="../../../assets/CP010_COSMOS-11.svg" scaleToContainer="true"></app-svg-viewer>
    </div>
    <div class="docs-homepage-promo-desc">
      <h2>Adjustment Status Screens</h2>
      <p>View the requests you need to take action on and also track the requests in progress</p>
    </div>
  </div>
  <div class="docs-homepage-bottom-start">
    <!--<a mat-raised-button class="docs-button" routerLink="/">Login</a>-->
  </div>
</div>
