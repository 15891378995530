import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import {MatButtonModule} from '@angular/material/button';
 import {MatCardModule} from '@angular/material/card';
 import {MatIconModule} from '@angular/material/icon';

import { UserAuthorizationComponent } from './user-authorization.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule
  ],
  declarations: [UserAuthorizationComponent]
})
export class UserAuthorizationModule { }
