import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class userAuthorizationService {

  headerTitle: string = "User not Authorised";
  errorCode: string = "401";
  errorMessage: string = "You are not authorised to access the requested page.";

  constructor() {
   }
}
