import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { UtilsService } from '../../shared/services/utils.service';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AdjustmentService {
  
  summaryTransactionUrl = this.appConfig.apiUrl+ 'api/Summary/Transactions';
  summaryInsuredUrl = this.appConfig.apiUrl+ 'api/Summary/GetInsureds';
  allRequestsUrl = this.appConfig.apiUrl + 'api/Summary/GetAdjustmentRequestsByUser';
  impactViewUrl = this.appConfig.apiUrl +'api/Summary/impactview';
  historyYearUrl = this.appConfig.apiUrl +'api/Summary/GetYears';
  historyMonthUrl = this.appConfig.apiUrl +'api/Summary/GetMonthsForYear';
  historyRequestsUrl = this.appConfig.apiUrl +'api/Summary/GetHistoryAdjustments';

  //Export Tables as Excel
  exportCurrentViewTables = this.appConfig.apiUrl + 'api/Summary/ExportAsExcel';
  exportHistoryViewTables = this.appConfig.apiUrl + 'api/Summary/ExportHistoryAsExcel';

  constructor(private http: HttpClient, public utilsService: UtilsService, private appConfig: AppConfig) { }

  getHistoryYears(): Observable<any> {
    return this.http.get(this.historyYearUrl);
  }

  getHistoryMonths(selectedYear: number): Observable<any> {
    let url = this.historyMonthUrl + '?year=' + selectedYear;
    return this.http.get(url);
  }

  /**
   * getAdjustmentTableData() is to make a GET call to get active adjustments list.
   * @param inReviewData 
   * @param inProgressData 
   * @param isImpactViewRequired 
   */
  getAdjustmentTablesData(inReviewData: object, inProgressData:object, isImpactViewRequired: boolean) {
    let url = this.allRequestsUrl +'?userEmail='+localStorage.getItem('userEmail');
    let statusToReviewUrl, statusInProgressUrl, impactViewUrl, requestList = [];
    if(inReviewData && !this.utilsService.isEmpty(inReviewData)) {
      statusToReviewUrl = this.http.get(this.getDataUrls(url, inReviewData), {observe: 'response'});
      requestList.push(statusToReviewUrl);
    }
    if( inProgressData && !this.utilsService.isEmpty(inProgressData)) {
      statusInProgressUrl = this.http.get(this.getDataUrls(url,inProgressData), {observe: 'response'});
      requestList.push(statusInProgressUrl)
    }
    if(isImpactViewRequired) {
      impactViewUrl = this.http.get(this.impactViewUrl + '?userEmail=' + localStorage.getItem('userEmail'));
      requestList.push(impactViewUrl);
    }

    return forkJoin(requestList);
  }

  /**
   * getHistoryTablesData() is to make a GET call to get history adjustments list.
   * @param historyAdjustmentsOthers 
   * @param historyAdjustmentsApproved 
   */
  getHistoryTablesData( historyAdjustmentsOthers: object, historyAdjustmentsApproved: object) {
    let url = this.historyRequestsUrl + '?userEmail=' + localStorage.getItem('userEmail');
    let historyApprovedAdjustments, historyOthersAdjustments, historyRequestList = [];
    if(historyAdjustmentsOthers && !this.utilsService.isEmpty(historyAdjustmentsOthers)) {
      historyOthersAdjustments = this.http.get(this.getDataUrls(url, historyAdjustmentsOthers), {observe: 'response'});
      historyRequestList.push(historyOthersAdjustments);
    }

    if(historyAdjustmentsApproved && !this.utilsService.isEmpty(historyAdjustmentsApproved)) {
      historyApprovedAdjustments = this.http.get(this.getDataUrls(url, historyAdjustmentsApproved), {observe: 'response'});
      historyRequestList.push(historyApprovedAdjustments);
    }

    return forkJoin(historyRequestList);
  }

  getSummaryInsureds(currentVal: string): Observable<object> {

    let url = this.summaryInsuredUrl;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get<any>(url);
  }

  /**
   * getDataUrl() is to construct url based on provided data.
   * @param url 
   * @param data 
   */
  getDataUrls(url, data) {
    for(let option in data) {
      if(data[option] != null) {
        url += '&'+ option+'='+data[option];
      }
    }
    return url;
  }

  exportTableData(data:object, type) {
    let url;
    if(type === 'Current') {
      url = this.exportCurrentViewTables + '?userEmail=' + localStorage.getItem('userEmail');
    } else {
      url = this.exportHistoryViewTables + '?userEmail=' + localStorage.getItem('userEmail');
    }
    url = this.getDataUrls(url, data);
    return this.http.get(url, {observe: 'response', responseType: 'arraybuffer' });
  }

}
