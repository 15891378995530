import { AdjustmentType, AdjustmentStatus } from '../../shared/models/adjustment.model';
import * as _ from 'lodash';

export default class DeferralModel{

  type: string = AdjustmentType.Deferral;

  id: number;
  userRoles: string[];
  reDeferralAllowed: boolean;
  redeferredRequestId:number;
  requestDate: Date = new Date();
  journalType: number = 1;
  
  transactionNumber: string;
  
  insureds: object[] = [{
    code: <string>null,
    name: <string>null
  }];
  
  effectiveDate: Date;
  
  reasonForDeferralId: number;
  reasonForDeferral: string;
  otherReason: string;
  
  brokerageDetails: object[] = [{
    id: <number>null,
    accountCode: <string>null,
    name: <string>null,
    brokerageAmount: <any>null,//@ToDo: Move this from any to number or string
    brokerageRecogDate: <Date>null,
    currencyID: <number>null,
    currencyCode: <string>null,
    businessUnitGroup2: <string>null,
    businessUnitGroup3: <string>null,
    businessUnitGroup4: <string>null
  }];
  
  deferredBrokerageAccountID: number; //Use this to save and retrieve the selected brokerage account
  
  brokerageAccount: string;
  accountCode: string;
  currencyID: number;
  currency: string;
  amount: any;//@ToDo: Move this from any to number or string
  incomeBrokerageRecognitionDate: Date;

  businessUnitGroup2: string;
  businessUnitGroup3: string;
  businessUnitGroup4: string;

  incomeTypeID: number;
  incomeType: string;

  description: string ="";
  workFlowType: number;

  selectedBrokerageDetails = [{}];

  deferralRequests: object[] = [{
    curencyId: <number>null,
    currency: <string>null,
    brokerage: <any>0,//@ToDo: Move this from any to number or string
    brokerageRecognitionDate: <Date>null,
    isCurrentPeriodId:<boolean>false
  }];
  
  slipFile: File;
  slipFileName: string;
  slipExistingFileName: string;//This is to hold already uploaded file
  
  status: string = AdjustmentStatus.Incomplete;
  statusId: number;
  
  //@ToDo: move comments out and use updated variables like submittedComments etc.. as per status
  comments: string;
  //@ToDo: move processedBy out and use updated variables like authorizedBy etc..
  processedBy: string;
  requestedBy: string;
  requestedById: number;
  submittedBy: string;
  submittedById: number;
  submittedComments: string;
  authorizedBy: string;
  authorizedById: number;
  authorizedComments: string;
  approvedBy: string;
  approvedById: number;
  approvedComments: string;
  rejectedBy: string;
  rejectedById: number;
  rejectedComments: string;
  //Adding more fields below with values to be received from transaction number details
  adjTypeID: number = 2;
  adjDeferralDetailID: number = 0;
  deferredAdjLedgerID: number;
  businessUnitAdjLedgerID: number;
  multiCurrencies: string[] = [];
  actionType: any;
  redeferralAllowed:boolean;
  constructor(){  }

  setValuesFromAPIObj(requestObj: object): void{
    if(requestObj) {
      //this.id = requestObj['requestId'];
      this.id = (this.actionType && this.actionType == 'reDeferral') ? null : requestObj['requestId'];
      this.redeferredRequestId=(this.actionType && this.actionType == 'reDeferral') ?  requestObj['requestId']:null;
      this.transactionNumber = requestObj['transactionNumber'];
      
      this.adjTypeID = requestObj['adjTypeID'];
      
      this.deferredAdjLedgerID = requestObj['deferredAdjLedgerID'];
      this.businessUnitAdjLedgerID = requestObj['deferredAdjLedgerID'];
      
      this.reasonForDeferralId = requestObj['reasonId'];
      this.reasonForDeferral = requestObj['reason'];
      this.otherReason = requestObj['otherReason'];
      
      this.statusId = requestObj['statusId'];
      this.status = requestObj['status'];
      
      this.incomeTypeID = requestObj['incomeTypeId'];
      this.incomeType = requestObj['incomeName'];

      this.insureds = requestObj['insureds'];
      this.reDeferralAllowed = (requestObj.hasOwnProperty('reDeferralAllowed')) ? requestObj['reDeferralAllowed'] : false;
      this.brokerageDetails = requestObj['brokerageDetails'];
      this.brokerageDetails.forEach((value) => {
        this.multiCurrencies.push(value["currencyCode"]);
      })
      this.deferredBrokerageAccountID = requestObj['deferredBrokerageAccountID'];
      let selectedOptionArr = this.brokerageDetails.filter(
        currentVal => {
          return currentVal['id'] == this.deferredBrokerageAccountID;
        }
      )
      if(selectedOptionArr.length>0){
        this.brokerageAccount = selectedOptionArr[0]['name'];
        this.accountCode = selectedOptionArr[0]['accountCode'];
        this.businessUnitGroup2 = selectedOptionArr[0]['businessUnitGroup2'];
        this.businessUnitGroup3 = selectedOptionArr[0]['businessUnitGroup3'];
        this.businessUnitGroup4 = selectedOptionArr[0]['businessUnitGroup4'];
        this.currency = selectedOptionArr[0]['currencyCode'];
        this.currencyID = selectedOptionArr[0]['currencyID'];
        this.amount = this.sumCurrencyData(selectedOptionArr);
        this.incomeBrokerageRecognitionDate = selectedOptionArr[0]['brokerageRecogDate'];
      }
      
      this.effectiveDate = requestObj['effectiveDate'];

      this.slipExistingFileName = requestObj['slipFileName'];

      if(requestObj["description"]) this.description = requestObj["description"];
      if(requestObj["journalType"]) this.journalType = requestObj["journalType"];
      if(requestObj["workFlowType"]) this.workFlowType = requestObj["workFlowType"];

      this.adjDeferralDetailID = requestObj["adjDeferralDetailID"];
      
      this.requestedById = (requestObj['requestedBy'] && requestObj['requestedBy']['id']) ? requestObj['requestedBy']['id'] : null;
      this.requestedBy = (requestObj['requestedBy'] && requestObj['requestedBy']['name']) ? requestObj['requestedBy']['name'] : null;

      this.submittedById = (requestObj['submittedBy'] && requestObj['submittedBy']['id']) ? requestObj['submittedBy']['id'] : null;
      this.submittedBy = (requestObj['submittedBy'] && requestObj['submittedBy']['name']) ? requestObj['submittedBy']['name'] : null;
      this.submittedComments = (requestObj['submittedBy'] && requestObj['submittedBy']['comments']) ? requestObj['submittedBy']['comments'] : null;

      this.authorizedById = (requestObj['authorizedBy'] && requestObj['authorizedBy']['id']) ? requestObj['authorizedBy']['id'] : null;
      this.authorizedBy = (requestObj['authorizedBy'] && requestObj['authorizedBy']['name']) ? requestObj['authorizedBy']['name'] : null;
      this.authorizedComments = (requestObj['authorizedBy'] && requestObj['authorizedBy']['comments']) ? requestObj['authorizedBy']['comments'] : null;

      this.approvedById = (requestObj['approvedBy'] && requestObj['approvedBy']['id']) ? requestObj['approvedBy']['id'] : null;
      this.approvedBy = (requestObj['approvedBy'] && requestObj['approvedBy']['name']) ? requestObj['approvedBy']['name'] : null;
      this.approvedComments = (requestObj['approvedBy'] && requestObj['approvedBy']['comments']) ? requestObj['approvedBy']['comments'] : null;

      this.rejectedById = (requestObj['rejectedBy'] && requestObj['rejectedBy']['id']) ? requestObj['rejectedBy']['id'] : null;
      this.rejectedBy = (requestObj['rejectedBy'] && requestObj['rejectedBy']['name']) ? requestObj['rejectedBy']['name'] : null;
      this.rejectedComments = (requestObj['rejectedBy'] && requestObj['rejectedBy']['comments']) ? requestObj['rejectedBy']['comments'] : null;
      this.selectedBrokerageDetails = [];
      this.selectedBrokerageDetails = _.filter(requestObj['brokerageDetails'], (value) => {
        return value.id === requestObj['deferredBrokerageAccountID']
      });
      this.deferralRequests = [];
      let currentBrokerageEarningDateObj = {};//This will hold the object where data is equal to current brokerage earning date
      requestObj['deferralRequests'].forEach(element => {
        // if (element['brokerageDate'] == this.incomeBrokerageRecognitionDate){
        //   currentBrokerageEarningDateObj = {
        //     curencyId: element['currency']['currencyID'],
        //     currency: element['currency']['code'],
        //     brokerage: element['brokerageAmount'],
        //     brokerageRecognitionDate: element['brokerageDate'],
        //     isDisabled: false
        //   };
        // }else{
          this['deferralRequests'].push({
            curencyId: element['currency']['currencyID'],
            currency: element['currency']['code'],
            brokerage: element['brokerageAmount'],
            brokerageRecognitionDate: element['brokerageDate'],
            isCurrentPeriodId:element['isCurrentPeriodId'],
            isDisabled: false
          });
        //}    
      });

      this.brokerageDetails.forEach((value) => {
        var arr = this.deferralRequests.filter((val) => {
          if(val['currency'] === value['currencyCode']) {
            return true;
          }
        });
        arr[0]['isDisabled'] = true;
      })
      if(!this.isEmpty(currentBrokerageEarningDateObj)) {
        this['deferralRequests'].unshift(currentBrokerageEarningDateObj);//This is to ensure that date with current brokerage earning date object remains at the top, that is, always the first element of array
      }
    }
  }

  updateFieldsFromTransactionNumberDetails(unset: boolean, data) {

    if(unset){
      
      this.adjTypeID = null;
      this.deferredAdjLedgerID = null;
      this.businessUnitAdjLedgerID = null;

      this.effectiveDate = null;

      this.incomeTypeID = null;
      this.reDeferralAllowed=false;

      this.insureds = [{
        code: null,
        name:null
      }];

      this.brokerageDetails = [{
        id: null,
        accountCode: null,
        name: null,
        brokerageAmount: null,
        brokerageRecogDate: null,
        currencyID: null,
        currencyCode: null,
        businessUnitGroup2: null,
        businessUnitGroup3: null,
        businessUnitGroup4: null
      }];

      this.deferredBrokerageAccountID = null;
      this.brokerageAccount = null;
      this.accountCode = null;
      this.businessUnitGroup2 = null;
      this.businessUnitGroup3 = null;
      this.businessUnitGroup4 = null;
      this.currency = null;
      this.currencyID = null;
      this.amount = null;
      this.incomeBrokerageRecognitionDate = null;

      this.deferralRequests = [{
        curencyId: null,
        currency: null,
        brokerage: 0,
        brokerageRecognitionDate: null,
        isCurrentPeriodId:null
      }];

      this.multiCurrencies = [];
    }else{
      
      this.adjTypeID = data['adjTypeID'];
      this.deferredAdjLedgerID = data['deferredAdjLedgerID'];
      this.businessUnitAdjLedgerID = data['deferredAdjLedgerID'];

      this.effectiveDate = data['effectiveDate'];

      this.incomeTypeID = data['incomeTypeID'];

      this.insureds = data['insureds'];
    
      this.brokerageDetails = [... data['brokerageDetails']];

      let groupByData;
      //_.forEach(data['brokerageDetails'], (val, index, arr) => {
        groupByData = _.filter(data['brokerageDetails'], (value) => {
          return value.id === data['brokerageDetails'][0]['id'];
        });
        this.selectedBrokerageDetails = [...groupByData]
      //})

      //Setting the default values to the first record
      if (this.brokerageDetails[0]) {
        this.deferredBrokerageAccountID = this.brokerageDetails[0]['id'];
        this.brokerageAccount = this.brokerageDetails[0]['name'];
        this.accountCode = this.brokerageDetails[0]['accountCode'];
        this.businessUnitGroup2 = this.brokerageDetails[0]['businessUnitGroup2'];
        this.businessUnitGroup3 = this.brokerageDetails[0]['businessUnitGroup3'];
        this.businessUnitGroup4 = this.brokerageDetails[0]['businessUnitGroup4'];
        this.currency = this.brokerageDetails[0]['currencyCode'];
        this.currencyID = this.brokerageDetails[0]['currencyID'];
        if(this.brokerageDetails.length > 1) {
          this.amount = this.sumCurrencyData(this.selectedBrokerageDetails);
          this.amount = parseFloat(this.amount.toFixed(2));
        } else {
          this.amount = this.brokerageDetails[0]['brokerageAmount'];
        }
        this.incomeBrokerageRecognitionDate = this.brokerageDetails[0]['brokerageRecogDate'];

        //Giving default values to deferral requests first row
        // this.deferralRequests = [{
        //   currencyId: this.currencyID,
        //   currency: this.currency,
        //   brokerage: this.amount,
        //   brokerageRecognitionDate: this.incomeBrokerageRecognitionDate
        // }];
        this.deferralRequests = [];
        this.multiCurrencies = [];
        this.selectedBrokerageDetails.forEach((val, index) => {
          let obj = {
            curencyId: val['currencyID'],
            currency: val["currencyCode"],
            brokerage: val["brokerageAmount"],
            brokerageRecognitionDate: val["brokerageRecogDate"],
            isDisabled: true
          }
          this.deferralRequests.push(obj);
          this.multiCurrencies.push(obj.currency);
        });
        //this.deferralRequests = [...this.brokerageDetails];
      }
    }    
  }

  getSaveObject(): object{
    let userEmail = localStorage.getItem('userEmail');
    let dateObj;
    let dateTimeMs;
    let timeZoneDate;
    let brokerageAmountWithoutComma;
    let adjustments = [];
    let userRoleStatus: string;
    this.deferralRequests.forEach(value => {
      
      dateObj = new Date(value['brokerageRecognitionDate']);
      //timeZoneDate.setHours(timeZoneDate.getHours() - timeZoneDate.getTimezoneOffset() / 60);
      dateTimeMs = dateObj.getTime() - (dateObj.getTimezoneOffset() * 60 * 1000);
      timeZoneDate = new Date(dateTimeMs);
      brokerageAmountWithoutComma = (value['brokerage'] && value['brokerage'].replace) ? value['brokerage'].replace(/,/g, '') : value['brokerage'];

      adjustments.push({
        currencyID: value['curencyId'],
        brokerageAmount: brokerageAmountWithoutComma,
        brokerageRecognitionDate: timeZoneDate
      });
    });

    if(this.userRoles.length > 0 && this.userRoles.indexOf('Tier 4') !== -1){
      userRoleStatus = "RequestedToAuthorised"
    } else if( this.userRoles.length > 0 && this.userRoles.indexOf('Tier 2') !== -1) {
      userRoleStatus = "IncompleteToSumitted"
    }
    
    return {
      requestId: this.id,
      adjTypeID: this.adjTypeID,
      deferredAdjLedgerID: this.deferredAdjLedgerID,
      deferredBrokerageAccountID: this.deferredBrokerageAccountID,
      reasonID: this.reasonForDeferralId,
      otherReason: this.otherReason,
      //incomeTypeID: this.incomeTypeID,
      currencyID: this.currencyID,
      brokerageAmount: this.amount,
      brokerageRecognitionDate: this.incomeBrokerageRecognitionDate,
      adjustments: adjustments,
      userEmail: userEmail,
      insuredName: this.insureds[0]["name"],
      journalType: this.journalType,
      transactionNumber: this.transactionNumber,
      brokerageAccountName: this.selectedBrokerageDetails[0]["name"],
      transition: userRoleStatus,
      reasonForDeferral: this.reasonForDeferral,
      currency: this.currency,
      workFlowType: this.workFlowType,
      adjDeferralDetailId: (this.actionType && this.actionType == 'reDeferral') ? null : this.adjDeferralDetailID,
      redeferralAllowed:(this.actionType && this.actionType == 'reDeferral') ? true :false,
      redeferredRequestId:this.redeferredRequestId
    };
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  sumCurrencyData(data) {
    let sum = 0;
    for(let i =0; i < data.length; i++) {
      let brokerageAmountWithoutComma = (data[i]['brokerageAmount'] && data[i]['brokerageAmount'].replace) ? data[i]['brokerageAmount'].replace(/,/g, '') : data[i]['brokerageAmount'];
      sum = sum + parseFloat(brokerageAmountWithoutComma);
    }
    return sum;
  }

  // groupBy (xs, key) {
  //   return values.reduce((a, e) => {
  //     a[e.id] = e.id in a ? ++a[e.id] : 0;
  //     return a;
  //   }, {});
  // };

  getBrokerageEarningDateReDeferral(requestObj: object){

    
    let currentBrokerageEarningDate = requestObj['accrualRequest']['brokerageDate'];

    if(this.actionType!='reDeferral'){
      return currentBrokerageEarningDate;
    }
    
    let currentDateObj = new Date(currentBrokerageEarningDate);
    
    let 
      fullYear = currentDateObj.getFullYear(), 
      month = currentDateObj.getMonth(), 
      date = currentDateObj.getDate(),
      hours = currentDateObj.getHours(),
      minutes = currentDateObj.getMinutes(),
      seconds = currentDateObj.getSeconds();

    let 
      newDate,
      newMonth,
      newFullYear;
    
    newMonth = (month==11) ? 0 : month+1;
    newFullYear = (newMonth==0) ? fullYear+1 : fullYear;
    newDate = date;

    if (newMonth == 3 || newMonth == 5 || newMonth == 8 || newMonth == 10){//Case when maximum day can be 30 for a month

      if (date==31){
        newDate = 30;
      }
    }else if(newMonth == 1){//Feb case

      if(newFullYear%4==0){//Leap year case with Feb
        if (date == 30 || date==31){
          newDate = 29;
        }
      }else{
        if (date == 29 || date == 30 || date==31){
          newDate = 28;
        }
      }
    }
    
    return new Date(newFullYear, newMonth, newDate, hours, minutes, seconds);
  }
}
