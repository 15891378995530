import { AdjustmentType, AdjustmentStatus } from '../../shared/models/adjustment.model';

export default class BusinessUnitAdjustmentModel {

  type: string = AdjustmentType.BusinessUnitAdjustment;
  id: number;

  requestDate: Date = new Date();

  userRoles: string[];

  journalType: number = 2;
  description: string ="";
  
  adjTypeID: number = 3;
  deferredAdjLedgerID: number;
  adjBusinessUnitDetailID: number = 0;
  
  transactionNumber: string;
  businessUnitAdjLedgereID: number;
  
  reversalTransactionNumber: string;
  businessUnitReversalAdjLedgerID: number;
  
  correctedTransactionNumber: string;
  businessUnitCorrectedAdjLedgerID: number;
  
  effectiveDate: Date;
  
  incomeTypeID: number;
  incomeType: string;
  
  currency: string;
  defferalIncomeCurrency: string;
  
  reasonForAdjustmentId: number;
  reasonForAdjustment: string;
  otherReason: string;

  workFlowType: number;
  
  insureds: object[] = [{
    code: <string>null,
    name: <string>null
  }];

  //@ToDo: remove the following as the details have been moved to brokerage section
  businessUnit1: string;
  businessUnit2: string;
  businessUnit3: string;

  brokerageDetails: object[] = [{
    id: <number>null,
    accountCode: <string>null,
    name: <string>null,
    brokerageAmount: <any>null,
    brokerageRecogDate: <Date>null,
    currencyID: <number>null,
    currencyCode: <string>null,
    businessUnitGroup2: <string>null,
    businessUnitGroup3: <string>null,
    businessUnitGroup4: <string>null
  }];

  //@ToDo: remove the following as the details have been moved to corrected brokerage section
  adjustmentRequestBusinessUnit1: string;
  adjustmentRequestBusinessUnit2: string;
  adjustmentRequestBusinessUnit3: string;

  correctedBrokerageDetails: object[] = [{
    id: <number>null,
    accountCode: <string>null,
    name: <string>null,
    brokerageAmount: <any>null,
    brokerageRecogDate: <Date>null,
    currencyID: <number>null,
    currencyCode: <string>null,
    businessUnitGroup2: <string>null,
    businessUnitGroup3: <string>null,
    businessUnitGroup4: <string>null
  }];

  slipFile: File;
  slipFileName: string;
  slipExistingFileName: string;//This is to hold already uploaded file
  
  status: string = AdjustmentStatus.Incomplete;
  statusId: number;
  
  //@ToDo: move comments out and use updated variables like submittedComments etc.. as per status
  comments: string;
  //@ToDo: move processedBy out and use updated variables like authorizedBy etc..
  processedBy: string;
  requestedBy: string;
  requestedById: number;
  submittedBy: string;
  submittedById: number;
  submittedComments: string;
  authorizedBy: string;
  authorizedById: number;
  authorizedComments: string;
  approvedBy: string;
  approvedById: number;
  approvedComments: string;
  rejectedBy: string;
  rejectedById: number;
  rejectedComments: string;

  formData: FormData = null;

  constructor() { }

  setValuesFromAPIObj(requestObj: object): void {

    if(requestObj) {

      this.id = requestObj['requestId']; 
      
      this.transactionNumber = requestObj['transactionNumber'];
      this.businessUnitAdjLedgereID = requestObj['businessUnitAdjLedgerID'];//Data Missing

      this.reversalTransactionNumber = requestObj['reversedTransaction']['transactionNumber'];
      this.businessUnitReversalAdjLedgerID = requestObj['reversedTransaction']['adjLedgerID'];
      
      this.correctedTransactionNumber = requestObj['correctedTransaaction']['transactionNumber'];
      this.businessUnitCorrectedAdjLedgerID = requestObj['correctedTransaaction']['adjLedgerID'];
      
      this.effectiveDate = requestObj['effectiveDate'];
      
      this.incomeTypeID = requestObj['incomeTypeId'];
      this.incomeType = requestObj['incomeName'];
      
      this.insureds = requestObj['insureds'];

      this.currency = requestObj[''];
      this.defferalIncomeCurrency = requestObj[''];
      
      this.reasonForAdjustmentId = requestObj['reasonId'];
      this.reasonForAdjustment = requestObj['reason'];
      this.otherReason = requestObj['otherReason'];
      
      //@ToDo: remove the following as the details have been moved to corrected brokerage section
      //this.businessUnit1 = requestObj['businessUnitGroup2'];
      //this.businessUnit2 = requestObj['businessUnitGroup3'];
      //this.businessUnit3 = requestObj['businessUnitGroup4'];
      
      this.brokerageDetails = requestObj['brokerageDetails'];

      this.correctedBrokerageDetails = requestObj['correctedTransaaction']['brokerageDetails'];

      //@ToDo: remove the following as the details have been moved to corrected brokerage section
      //this.adjustmentRequestBusinessUnit1 = requestObj['correctedTransaaction']['businessUnitGroup2'];
      //this.adjustmentRequestBusinessUnit2 = requestObj['correctedTransaaction']['businessUnitGroup3'];
      //this.adjustmentRequestBusinessUnit3 = requestObj['correctedTransaaction']['businessUnitGroup4'];

      this.slipExistingFileName = requestObj['slipFileName'];

      this.adjBusinessUnitDetailID = requestObj['adjBusinessUnitDetailID'];

      if(requestObj["description"]) this.description = requestObj["description"];
      if(requestObj["journalType"]) this.journalType = requestObj["journalType"];

      if(requestObj["workFlowType"]) this.workFlowType = requestObj["workFlowType"];
      
      this.status = requestObj['status'];
      this.statusId = requestObj['statusId'];
      
      this.comments = requestObj[''];
      this.processedBy = requestObj[''];

      this.requestedById = (requestObj['requestedBy'] && requestObj['requestedBy']['id']) ? requestObj['requestedBy']['id'] : null;
      this.requestedBy = (requestObj['requestedBy'] && requestObj['requestedBy']['name']) ? requestObj['requestedBy']['name'] : null;

      this.submittedById = (requestObj['submittedBy'] && requestObj['submittedBy']['id']) ? requestObj['submittedBy']['id'] : null;
      this.submittedBy = (requestObj['submittedBy'] && requestObj['submittedBy']['name']) ? requestObj['submittedBy']['name'] : null;
      this.submittedComments = (requestObj['submittedBy'] && requestObj['submittedBy']['comments']) ? requestObj['submittedBy']['comments'] : null;

      this.authorizedById = (requestObj['authorizedBy'] && requestObj['authorizedBy']['id']) ? requestObj['authorizedBy']['id'] : null;
      this.authorizedBy = (requestObj['authorizedBy'] && requestObj['authorizedBy']['name']) ? requestObj['authorizedBy']['name'] : null;
      this.authorizedComments = (requestObj['authorizedBy'] && requestObj['authorizedBy']['comments']) ? requestObj['authorizedBy']['comments'] : null;

      this.approvedById = (requestObj['approvedBy'] && requestObj['approvedBy']['id']) ? requestObj['approvedBy']['id'] : null;
      this.approvedBy = (requestObj['approvedBy'] && requestObj['approvedBy']['name']) ? requestObj['approvedBy']['name'] : null;
      this.approvedComments = (requestObj['approvedBy'] && requestObj['approvedBy']['comments']) ? requestObj['approvedBy']['comments'] : null;

      this.rejectedById = (requestObj['rejectedBy'] && requestObj['rejectedBy']['id']) ? requestObj['rejectedBy']['id'] : null;
      this.rejectedBy = (requestObj['rejectedBy'] && requestObj['rejectedBy']['name']) ? requestObj['rejectedBy']['name'] : null;
      this.rejectedComments = (requestObj['rejectedBy'] && requestObj['rejectedBy']['comments']) ? requestObj['rejectedBy']['comments'] : null;
    }
  }

  updateFieldsFromTransactionNumberDetails(data, transactionType){
    //@ToDo: Move this to another single method of updating from data. Currently done like this to keep things isolated and changes at one not affecting others
    switch(transactionType){
      
      case 'default':

        //@ToDo: remove the following as the details have been moved to corrected brokerage section
        //this.businessUnit1 = data['businessUnitGroup2'];
        //this.businessUnit2 = data['businessUnitGroup3'];
        //this.businessUnit3 = data['businessUnitGroup4'];
        
        this.effectiveDate = data['effectiveDate'];
        
        this.incomeTypeID = data['incomeTypeID'];
        this.incomeType = data['currentIncomeType'];
        
        this.currency = data['currentIncomeCurrency'];
        this.defferalIncomeCurrency = data['defferalIncomeCurrency'];
        
        this.adjTypeID = data['adjTypeID'];
        this.businessUnitAdjLedgereID = data['deferredAdjLedgerID'];//@ToDo: Remove this if not used anywhere
        this.deferredAdjLedgerID = data['deferredAdjLedgerID'];
        
        this.insureds = data['insureds'];

        this.brokerageDetails = data['brokerageDetails'];
      break;
      
      case 'corrected':
              
        this.businessUnitCorrectedAdjLedgerID = data['adjLedgerID'];
        
        this.correctedBrokerageDetails = data['brokerageDetails'];
        
        //@ToDo: remove the following as the details have been moved to corrected brokerage section
        //this.adjustmentRequestBusinessUnit1 = data['businessUnitGroup2'];
        //this.adjustmentRequestBusinessUnit2 = data['businessUnitGroup3'];
        //this.adjustmentRequestBusinessUnit3 = data['businessUnitGroup4'];
      break;

      case 'reversal':
      
        this.businessUnitReversalAdjLedgerID = data['adjLedgerID'];
      break;
    }    
  }

  getSaveObject(): object {
    
    let userEmail = localStorage.getItem('userEmail');
    let userRoleStatus: string;
    if(this.userRoles && this.userRoles.indexOf('Tier 4') !== -1){
      userRoleStatus = "RequestedToAuthorised";
    } else if( this.userRoles && this.userRoles.indexOf('Tier 2') !== -1) {
      userRoleStatus = "IncompleteToSumitted";
    }

    return {
      requestId: this.id,
      adjTypeID: 3,//@ToDo: Receive it from backend and pass it back
      businessUnitAdjLedgereID: this.businessUnitAdjLedgereID,
      businessUnitReversalAdjLedgerID: this.businessUnitReversalAdjLedgerID,
      businessUnitCorrectedAdjLedgerID: this.businessUnitCorrectedAdjLedgerID,
      deferredAdjLedgerID: this.deferredAdjLedgerID,
      reasonID: this.reasonForAdjustmentId,
      otherReason: this.otherReason,
      //incomeTypeID: this.incomeTypeID,
      //currencyID: this.currencyID,//@ToDo: For now, this has been removed, Add it back in case required
      userEmail: userEmail,
      journalType: this.journalType,
      businessUnitGrp3: this.brokerageDetails[0]["businessUnitGroup3"],
      corectedBusinessUnitGrp3: this.correctedBrokerageDetails[0]["businessUnitGroup3"],
      transactionNumber: this.transactionNumber,
      brokerageAccountName: this.correctedBrokerageDetails[0]["name"],
      insuredName: this.insureds[0]["name"],
      transition: userRoleStatus,
      reasonForAdjustment: this.reasonForAdjustment,
      currentBrokerageAccountName: this.brokerageDetails[0]["name"],
      correctedBrokerageIncome: this.correctedBrokerageDetails[0]["brokerageAmount"],
      currency: this.correctedBrokerageDetails[0]["currencyCode"],
      uploadFileData: this.formData,
      workFlowType: this.workFlowType,
      adjBusinessUnitDetailID: this.adjBusinessUnitDetailID
    };
  }
}
