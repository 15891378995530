import { Injectable } from '@angular/core';

import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { Router } from '@angular/router';

import { WindowRef } from '../../shared/services/window-ref.service';

import {
  MatSnackBar
} from '@angular/material/snack-bar';

import { AdjustmentType } from '../models/adjustment.model';
import { Tiers } from '../models/adjustment.model';
import { MsalService } from "@azure/msal-angular";
import { SnackBarComponent } from 'src/app/pages/data-capture/snackBarDefault.component';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  tiersArr: string[];

  adjustmentsViewType: string = 'Current';
  adjustmentsHistoryYear: number;
  adjustmentsHistoryMonth: string;
  adjustmentsSearchTransactionNumber: string;
  adjustmentsSearchInsuredName: string;
  userDetails: object;

  displayLoadingIcon: Subject<boolean> = new Subject<boolean>();
  isAuthenticated: Subject<boolean> = new Subject<boolean>();
  setTierItems: BehaviorSubject<any> = new BehaviorSubject(null);
  isLogin: boolean = false;

  constructor(public snackBar: MatSnackBar,
    private appConfig: AppConfig,
    private authService: MsalService,
    public winRef: WindowRef,
    private router: Router) { }


  showMessageTip(message: string, action: string = 'close', duration: number = 5000) {
    //let config = new MatSnackBarConfig();
    this.snackBar.open(message, action, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
  showMessageTipOnFormComponent(data, duration: number = 10000) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: data,
      duration: duration,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    })
  }

  get tier(): Observable<any> {
    return this.setTierItems.asObservable();
  }

  showLoadingIcon(res: boolean) {

    this.displayLoadingIcon.next(res);
  }
  AuthenticationStatus(res: boolean) {
    this.isAuthenticated.next(res);
  }

  setTier(unset: boolean, tiersArr: any[]): void {

    if (unset) {
      this.tiersArr = [];
      localStorage.setItem('tier', '');
    } else {

      this.tiersArr = [];
      if (tiersArr.indexOf(54) > -1) {
        this.tiersArr.push(Tiers.Tier1);
      }
      if (tiersArr.indexOf(53) > -1) {
        this.tiersArr.push(Tiers.Tier2);
      }
      if (tiersArr.indexOf(52) > -1) {
        this.tiersArr.push(Tiers.Tier3);
      }
      if (tiersArr.indexOf(51) > -1) {
        this.tiersArr.push(Tiers.Tier4);
      }
      this.setTierItems.next(this.tiersArr);
    }
  }

  getUserRolesDisplay(): string {

    let rolesString: string = null;
    let tiersArr = this.setTierItems.getValue();

    if (tiersArr.indexOf(Tiers.Tier4) > -1) {
      rolesString = "Business";
    }

    if (tiersArr.indexOf(Tiers.Tier3) > -1) {
      if (rolesString) {
        rolesString += ", Head Business";
      } else {
        rolesString = "Head Business";
      }
    }

    if (tiersArr.indexOf(Tiers.Tier2) > -1) {
      if (rolesString) {
        rolesString += ", Finance";
      } else {
        rolesString = "Finance";
      }
    }

    if (tiersArr.indexOf(Tiers.Tier1) > -1) {
      if (rolesString) {
        rolesString += ", Head Finance";
      } else {
        rolesString = "Head Finance";
      }
    }

    return rolesString;
  }

  getFormatedDate(date) {
    let year: number = Math.floor(date / 100);
    let month: number = Math.floor(date % 100);
    return new Date(year, month - 1, 1);
  }

  chooseAdjustmentRequestType(type, id) {
    switch (type) {
      case AdjustmentType.Deferral:
        this.router.navigate(['deferral-data-capture/edit', id]);
        break;
      case AdjustmentType.Accrual:
        this.router.navigate(['accrual-data-capture/edit', id]);
        break;
      case AdjustmentType.BusinessUnitAdjustment:
        this.router.navigate(['business-unit-adjustment-data-capture/edit', id]);
        break;
      case AdjustmentType.BusinessUnitAdjustmentTransfer:
        this.router.navigate(['business-unit-adjustment-transfer/edit/', id]);
        break;
      case AdjustmentType.MiscellaneousIncome:
        this.router.navigate(['miscellaneous-income/edit/', id]);
        break;
      default:
        this.router.navigate(['adjustments']);
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  login() {
    //this.router.navigateByUrl('login');
    //this.oktaAuth.loginRedirect('/profile');
    this.router.navigate(['/adjustments']);
  }

  async logout() {
    this.showLoadingIcon(true);
    localStorage.setItem('username', '');
    localStorage.setItem('userEmail', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('appInsightLogged', '');
    this.setTier(true, null);
    //localStorage.setItem('tier', '');
    // Terminates the session with Okta and removes current tokens.
    this.AuthenticationStatus(false);
   await this.authService.instance.logoutRedirect()
    
    // this.router.navigate(['']);
    //this.winRef.nativeWindow.location.href = '/';
    //this.router.navigateByUrl('/');
  }

  validateIncomeAmount(amount) {
    //this.brokerageAmountErrMsg = '';
    let numberRegex = /^-?[0-9]\d*(\.\d+)?$/g;
    if (amount) {
      if (numberRegex.test(amount.toString().replace(/,/g, ""))) {
        return {
          valid: true,
          msg: ""
        };
      } else {
        return {
          valid: false,
          message: "Please enter amount as a numeric value."
        };
      }
    } else {
      return {
        valid: false,
        message: "Please enter amount details"
      };
    }
  }
  validateNumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46 || charCode === 45) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getPeriodDetails() {
    if (this.userDetails && this.userDetails["activePeriodID"]) {
      let activePeriodDate = this.getFormatedDate(this.userDetails["activePeriodID"]);
      return activePeriodDate;
    }
  }

 async isSessionExists() {
    return await this.authService.instance.getAllAccounts().length > 0;
  }

  showTimedOutMessage() {
    this.showMessageTip('Session has expired, please re-login to continue.');
    setTimeout(
      () => {
        this.logout();
      }, 3000
    );
  }
}
