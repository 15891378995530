import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../shared/services/utils.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isAuthenticated: boolean;

  constructor(
    public router: Router,
    public utilsService: UtilsService,
    private title: Title,
  ) {
    // Subscribe to authentication state changes
    // this.oktaAuth.$authenticationState.subscribe(
    //   (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    // );
  }

  async ngOnInit() {
    this.title.setTitle('Home');
    // Get the authentication state for immediate use
    // this.isAuthenticated = await this.utilsService.isSessionExists();
  }



}
