import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {
  private config: AppInsights.IConfig = {
    instrumentationKey: this.appConfig.appInsights.instrumentationKey
  };

  constructor(private appConfig: AppConfig) {
    this.setAppInsightsData();
   }

  
  public logPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    AppInsights.trackPageView(name, url, this.AddGlobalProperties(properties), measurements, duration);
  }

  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackEvent(name, this.AddGlobalProperties(properties), measurements);
  }

  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackException(error, null, this.AddGlobalProperties(properties), measurements);
  }

  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    // add your custom properties such as app version

    return properties;
  }

  public setAppInsightsData() {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
      AppInsights.clearAuthenticatedUserContext();
      this.setAuthenticatedUserContext(localStorage.getItem('userEmail'));
    }
  }

  public setAuthenticatedUserContext(userId) {
    AppInsights.setAuthenticatedUserContext(userId);
  }
}
