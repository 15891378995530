import { Directive, ElementRef, HostListener } from "@angular/core";
import { UtilsService } from "../services/utils.service";

@Directive({
    selector: '[userSessionExists]'
})
export class userSessionDirective {
    
    constructor(private el: ElementRef,
                private utilsService: UtilsService) {}

    @HostListener('focus', ['$event']) onElementFocus(e: Event){
        this.isSessionExists(e);
    }

    async isSessionExists(e) {
        const isSessionExists = await this.utilsService.isSessionExists();
        if(!isSessionExists) {
            e.preventDefault();
            this.utilsService.showTimedOutMessage();
            e.stopPropagation();
        }
    }
}
