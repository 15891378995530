import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundService {

  headerTitle: string = "Page not found";
  errorCode: string = "404";
  errorMessage: string = "Requested record could not be found.";

  constructor() {
   }
}
