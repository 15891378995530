import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { WindowRef } from '../../shared/services/window-ref.service';

import { DataCaptureFormsMaterialModule } from './data-capture-forms-material.module';

import { AmountPipe } from '../../shared/pipes/amount.pipe';

import { DeferralComponent } from './deferral.component';
import { AccrualComponent, IsRequestExists } from './accrual.component';
import { BusinessUnitAdjustmentComponent } from './business-unit-adjustment.component';
import { BusinessUnitAdjustmentTransferComponent } from './business-unit-adjustment-transfer.component';

import { SummaryActionComponent } from './summary-action.component';
import { DataCaptureFormsService } from './data-capture-forms.service';
import { MiscellaneousIncomeComponent } from './miscellaneous-income.component';
import DirectiveModuel from 'src/app/shared/directives/directive.moudle';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    DataCaptureFormsMaterialModule,
    DirectiveModuel,
    PipesModule
  ],
  declarations: [
    DeferralComponent,
    AccrualComponent,
    BusinessUnitAdjustmentComponent,
    BusinessUnitAdjustmentTransferComponent,
    MiscellaneousIncomeComponent,
    SummaryActionComponent,
    //AmountPipe,
    IsRequestExists
  ],
  providers: [
    WindowRef,
    DataCaptureFormsService
  ],
  exports: [IsRequestExists]
})
export class DataCaptureFormsModule { }
