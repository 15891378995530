<div class="docs-primary-header component-page-header">
    <button mat-button class="sidenav-toggle">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="row-display">
        <h1 class="width40P">{{ request.type }} Data Capture</h1>
        <div class="width40P request-date request-date-placement">Request Date: {{ request.requestDate | date:'d/MM/yyyy' }} &nbsp;|&nbsp; Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy'}}</div>
    </div>
</div>

<div class="col-display content-main-container">
    <div class="form-container" [hidden]="!hideSummary">
        <form #miscellaneousIncomeForm="ngForm">
            <div class="col-display">
                <mat-form-field class="width50P">
                    <mat-select required placeholder="Reason for Adjustment" [(ngModel)]="request.reasonForMiscellaneousId" name="reasonForAdjustment" (selectionChange)="reasonsForAdjustmentChange()" userSessionExists>
                        <mat-option *ngFor="let currentVal of reasonForAdjustmentOptions; let in=index" [value]="currentVal.reasonID">
                        {{ currentVal.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error>Please select a reason</mat-error>
                </mat-form-field>

                <mat-form-field class="width50P" *ngIf="request.reasonForMiscellaneous=='Other'">
                    <textarea required matInput placeholder="Enter details in case of other" [(ngModel)]="request.otherReason" name="otherReason" userSessionExists></textarea>
                    <mat-error>
                        Please provide details</mat-error>
                </mat-form-field>

                <mat-form-field class="width50P">
                    <mat-select required placeholder="Income Type" [(ngModel)]="request.incomeTypeId" name="incomeTypeId" (selectionChange)="incomeTypeChange($event)" userSessionExists>
                        <mat-option *ngFor="let currentVal of incomeTypeOptions; let in=index" [value]="currentVal.incomeTypeId">
                        {{ currentVal.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error>Please select a Income type</mat-error>
                </mat-form-field>

                <div class="row-display income-type-row">
                        <mat-form-field class="width25P">
                            <mat-select required placeholder="Currency" [(ngModel)]="request.currencyTypeId" name="currencyTypeId" (selectionChange)="currencyTypeChange($event)" userSessionExists>
                            <mat-option *ngFor="let currentVal of currencyTypeOptions; let in=index" [value]="currentVal.currencyID">
                                {{ currentVal.code }}
                            </mat-option>
                            </mat-select>
                            <mat-error>Please select Currency</mat-error>
                        </mat-form-field>
        
                        <mat-form-field class="width25P">
                            <input matInput required placeholder="Amount" [ngModel]="request.amount | amountPipe" (ngModelChange)="request.amount=$event" [ngModelOptions]="{updateOn:'blur'}"
                            name="amount" (keypress)="utilsService.validateNumberOnly($event)" (keyup)="validateAmountField($event.target.value)" userSessionExists>
                        </mat-form-field>
        
                        <mat-form-field class="width25P">
                            <input matInput [min]="activePeriodID" required [matDatepicker]="incomeBrokerageEarningDate" placeholder="Brokerage Earning Date" [(ngModel)]="request.brokerageEarningDate"
                                name="brokerageEarningDate" userSessionExists>
                            <mat-datepicker-toggle matSuffix [for]="incomeBrokerageEarningDate"></mat-datepicker-toggle>
                            <mat-datepicker #incomeBrokerageEarningDate></mat-datepicker>
                            <mat-error>Please select a date in active period.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row-display error-msg" [hidden]='!brokerageAmountErrMsg'>
                        <div class="brokerage-amount-error-msg-businessTransfer" [hidden]='!brokerageAmountErrMsg' [innerHTML]="brokerageAmountErrMsg"></div>
                    </div>

                    <div class="row-display">
                        <mat-form-field class="width60P field-with-hint">
                            <input required type="text" placeholder="Cost Centre" matInput [matAutocomplete]="costCentreAutoComplete"
                            [(ngModel)]= 'request.brokerageDetails[0]["costCentre"]' name="brokerageCostCentre" (ngModelChange)="autoCompleteCostCenter()" (blur)="onCostCentreBlur()" userSessionExists>
                            <mat-hint *ngIf="isCostCenterOptionsExists">Please type any 3 letters to search cost centre</mat-hint>
                            <mat-hint *ngIf="!isCostCenterOptionsExists">No Data found</mat-hint>
                            <mat-autocomplete #costCentreAutoComplete="matAutocomplete" (optionSelected)="costCenterSelected($event)">
                                <mat-option *ngFor="let costCenter of costCenterOptions | async" [value]="costCenter['costCentre']">
                                    {{costCenter.costCentre}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field class="width25P" *ngIf="costCentreCompanyCodes.length === 0 || costCentreCompanyCodes.length === 1 ">
                            <input matInput disabled placeholder="Company Code" required [(ngModel)]= 'request.brokerageDetails[0]["companyCode"]' name="companyCodeDisabled">
                        </mat-form-field>
                        
                        <mat-form-field class="width25P" *ngIf="costCentreCompanyCodes.length > 1">
                            <mat-select required placeholder="Company Code" name="companyCode" (selectionChange)="companyCodeSelected($event)" userSessionExists>
                                <mat-option *ngFor="let currentVal of costCentreCompanyCodes; let in=index" [value]="currentVal.fyBusinessUnitID">
                                    {{ currentVal.companyCode }}
                                </mat-option>
                            </mat-select>
                            <mat-error>Please select a Company Code</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row-display">
                        <mat-form-field class="width25P">
                            <input matInput disabled placeholder="Business Unit" required [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup2']" name="businessUnitGroup2">
                        </mat-form-field>
    
                        <mat-form-field class="width25P">
                            <input matInput disabled required [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup3']" name="businessUnitGroup3">
                        </mat-form-field>
    
                        <mat-form-field class="width25P">
                            <input matInput disabled required [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup4']" name="businessUnitGroup4">
                        </mat-form-field>
                    </div>

                    <h2>Supporting Documentation</h2>

                    <div class="row-display">
                        <div class="width70P">
                            Upload Slip: &nbsp;&nbsp;
                            <button mat-raised-button (click)="chooseFile($event)" class="fileuploadButton" >Choose</button>
                            <input type="text" (click)="chooseFile($event)" [value]="request.slipFileName ? request.slipFileName : ''"/>
                            <input type='file' id="slipfileUpload" class="displaynone" (change)="slipFileUploadChange($event.target.files)" #slipFileUploadField userSessionExists>
                            <mat-icon class="adc-remove-icon" (click)="slipFileRemove($event, slipFileUploadField)">delete</mat-icon>
                        </div>
                    </div>
                    <div class="data-capture-info-text" *ngIf="slipUploaded">*Uploading new file will replace existing file. If file exists</div>

                    <div class="row-display existing-file-row" *ngIf="request.slipExistingFileName">
                        <div class="width70P">
                            Current File: &nbsp;&nbsp;
                            <a href="javascript:void(0);" userSessionExists (click)="onDownloadMiscIncomeCurrentFile()">{{ request.slipExistingFileName }}</a>
                        </div>
                    </div>

                    <div *ngIf="errMsg" class="row-display error-msg error-msg-accrual" [hidden]='!errMsg' [innerHTML]="errMsg">
                    </div>

                    <div class="row-display btn-row">
                        <!-- <button mat-raised-button (click)="saveConfirmRequest($event, businessUnitAdustmentTransferForm, 'save') ">Save</button>
                        &nbsp;&nbsp;&nbsp;&nbsp; -->
                        <button mat-raised-button userSessionExists (click)="toggleMiscellaneousIncomeFormSummaryDisplay($event, miscellaneousIncomeForm)">Next</button>
                    </div>
            </div>
        </form>
    </div>
    <div class="summary-container " [hidden]="hideSummary ">
        <div class="col-display">
            <mat-card>
              <mat-card-title>
                Summary
              </mat-card-title>
              <mat-card-content>
                <div class="row-display summary-row">
                    <div class="width20P summary-label">Reason for Adjustment: </div>
                    <div class="width20P summary-value">{{ request.reasonForMiscellaneous }}</div>
                    <div class="width20P summary-value" *ngIf="request.reasonForMiscellaneous=='Other'">{{ request.otherReason }}</div>
                </div>
                <div class="row-display summary-row">
                    <div class="width20P summary-label">Income Type: </div>
                    <div class="width20P summary-value">{{ request.incomeType }}</div>
                </div>
    
                <div class="row-display summary-row">
                    <div class="width20P summary-label">Income Amount: </div>
                    <div class="width20P summary-value">{{ request.currencyTypeCode }}&nbsp;&nbsp;{{ request.amount | amountPipe }}</div>
                </div>
    
                <div class="row-display summary-row">
                    <div class="width20P summary-label">Earning Date: </div>
                    <div class="width20P summary-value">{{ request.brokerageEarningDate | date:'d/MM/yyyy' }}</div>
                </div>

                <div class="row-display summary-row">
                    <div class="width20P summary-label">Cost Centre: </div>
                    <div class="width20P summary-value">{{ request.brokerageDetails[0]['costCentre'] }}</div>
                    <div class="width20P summary-label">Company Code: </div>
                    <div class="width20P summary-value">{{ request.brokerageDetails[0]['companyCode'] }}
                    </div>
                </div>

                <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Business Unit: </div>
                    <div class="width70P summary-value ">{{ request.brokerageDetails[0]['businessUnitGroup2'] }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup3']
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup4'] }}</div>
                </div>

                <h2>Supporting Documentation</h2>

                <div class="row-display summary-row" *ngIf="request.slipFileName">
                    <div class="width20P summary-label">Slip: </div>
                    <div class="width20P summary-value">{{ request.slipFileName }}</div>
                </div>

                <div class="row-display summary-row" *ngIf="request.slipExistingFileName">
                    <div class="width20P summary-label">Current Slip: </div>
                    <div class="width60P summary-value">
                    <a href="javascript:void(0);" (click)="onDownloadMiscIncomeCurrentFile()" userSessionExists>{{ request.slipExistingFileName }}</a>
                    </div>
                </div>

                <div class="row-display btn-row summary-btn-row">
                    <button mat-raised-button (click)="toggleMiscellaneousIncomeFormSummaryDisplay($event, miscellaneousIncomeForm)" >Back</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="saveConfirmMiscellaneousIncomeRequest($event, miscellaneousIncomeForm, 'confirm')" >Confirm</button>
                </div>
              </mat-card-content>
            </mat-card>
    </div>
</div>
</div>