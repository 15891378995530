import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent, DialogContent } from './nav-bar.component';
import { RouterModule } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import { SvgViewerModule } from '../svg-viewer/svg-viewer.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    RouterModule,
    MatDialogModule,
    SvgViewerModule
  ],
  declarations: [NavBarComponent, DialogContent],
  exports: [NavBarComponent, DialogContent]
})
export class NavBarModule { }
