import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home';
import { AdjustmentListComponent } from './pages/adjustment';
import {
  DeferralComponent, AccrualComponent, BusinessUnitAdjustmentComponent,
  SummaryActionComponent, BusinessUnitAdjustmentTransferComponent
} from './pages/data-capture';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MiscellaneousIncomeComponent } from './pages/data-capture/miscellaneous-income.component';
import { UserAuthorizationComponent } from './shared/user-authorization';
import { MsalGuard } from '@azure/msal-angular';

//Following function to redirect in case not logged in
/*
export function onAuthRequired({ oktaAuth, router }) {
   router.navigate(['/login']);
 }
*/

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'adjustments',
    component: AdjustmentListComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'deferral-data-capture',
    component: DeferralComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'deferral-data-capture/edit/:id',
    component: DeferralComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'deferral-data-capture/edit/:id/:actionType',
    component: DeferralComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'accrual-data-capture',
    component: AccrualComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'accrual-data-capture/edit/:id',

    component: AccrualComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'accrual-data-capture/edit/:id/:actionType',
    component: AccrualComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'business-unit-adjustment-data-capture',
    component: BusinessUnitAdjustmentComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'business-unit-adjustment-data-capture/edit/:id',
    component: BusinessUnitAdjustmentComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'business-unit-adjustment-transfer',
    component: BusinessUnitAdjustmentTransferComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'business-unit-adjustment-transfer/edit/:id',
    component: BusinessUnitAdjustmentTransferComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'miscellaneous-income',
    component: MiscellaneousIncomeComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'miscellaneous-income/edit/:id',
    component: MiscellaneousIncomeComponent,
    canActivate: [MsalGuard]

  },
  {
    path: 'summary-action/:id/:type',
    component: SummaryActionComponent,
    canActivate: [MsalGuard]

  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: 'not-authorised',
    component: UserAuthorizationComponent
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
