import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of, BehaviorSubject, forkJoin } from 'rxjs';

import { UtilsService } from '../../shared/services/utils.service';
import { AdjustmentType, Adjustment } from '../../shared/models/adjustment.model';
import { PageNotFoundService } from '../page-not-found/page-not-found.service';
import { AppConfig } from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class DataCaptureFormsService {

  requestDetails: BehaviorSubject<object> = new BehaviorSubject(null);
  transactionNumberUrl = this.appConfig.apiUrl+'api/Deferral/Transactions';
  transactionNumberDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/TransactionDetails';
  saveDeferralRequestUrl = this.appConfig.apiUrl + 'api/Deferral/Save';
  updateDeferralRequestUrl = this.appConfig.apiUrl + 'api/Deferral/SaveRequestModel';
  deferralRequestDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/GetRequestDetails';
  deferralFileUploadUrl = this.appConfig.apiUrl + 'api/Deferral/upload';
  deferralFileDownloadUrl = this.appConfig.apiUrl + 'api/Deferral/download?AdjRequestID=';
  reasonsForAdjustmentUrl = this.appConfig.apiUrl + 'api/Common/adjustmentreasons';
  reversalTransactionNumberUrl = this.appConfig.apiUrl+'api/Deferral/GetReversalTransactionsByKey';
  reversalTransactionNumberDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/GetCorrectedTransactionDetails';
  correctedTransactionNumberUrl = this.appConfig.apiUrl+'api/Deferral/GetCorrectedTransactionByKey';
  correctedTransactionNumberDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/GetCorrectedTransactionDetails';
  saveBusinessUnitAdjustmentUrl = this.appConfig.apiUrl+'api/Deferral/SaveBusinessUnitAdjRequest';
  updateBusinessUnitAdjustmentUrl = this.appConfig.apiUrl+'api/Deferral/SaveBusinessUnitAdjRequestModel';
  businessUnitAdjustmentRequestDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/GetBusinessUnitAdjRequestById';
  businessUnitAdjustmentFileUploadUrl = this.appConfig.apiUrl + 'api/Deferral/upload';
  businessUnitAdjustmentFileDownloadUrl = this.appConfig.apiUrl + 'api/Deferral/download?AdjRequestID=';
  policyReferenceAccrualUrl = this.appConfig.apiUrl +'api/Accrual/GetPolicyReferences';
  policyReferenceDetailsAccrualUrl = this.appConfig.apiUrl +'api/Accrual/GetPolicyDetails';
  insuredAccrualUrl = this.appConfig.apiUrl +'api/Accrual/GetInsureds';
  brokerageAccrualUrl = this.appConfig.apiUrl +'api/Accrual/GetBrokerageAccounts';
  renewalTypesAccrualUrl = this.appConfig.apiUrl +'api/Accrual/GetRenewalTypes';
  incomeTypesAccrualUrl = this.appConfig.apiUrl +'api/Common/incometypes';
  currenciesAccrualUrl = this.appConfig.apiUrl +'api/Common/currencies';
  saveAccrualUrl = this.appConfig.apiUrl +'api/Accrual/Save';
  updateAccuralModalUrl = this.appConfig.apiUrl +'/api/Accrual/SaveAccrualModel';
  updateAccrualUrl = this.appConfig.apiUrl +'api/Accrual/Update';
  accrualRequestDetailsUrl = this.appConfig.apiUrl + 'api/Accrual/GetRequest';
  accrualFileUploadUrl = this.appConfig.apiUrl + 'api/Accrual/upload';
  accrualFileDownloadUrl = this.appConfig.apiUrl + 'api/Accrual/download?AdjRequestID=';
  workFlowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlow';
  updateDescriptionUrl = this.appConfig.apiUrl+ 'api/WorkFlow/UpdateFieldsForFinanceUsers';
  getUserDetailsUrl = this.appConfig.apiUrl + 'api/Deferral/GetUserDetails';
  summaryTransactionUrl = this.appConfig.apiUrl+ 'api/Summary/Transactions';
  businessUnitTransferRequestPolicyReference = this.appConfig.apiUrl+ 'api/BusinessUnitTransfer/GetPolicyReferences';
  businessUnitTransferIncomeType = this.appConfig.apiUrl+ 'api/Common/incometypes';
  businessUnitTransferCurrencies = this.appConfig.apiUrl+ 'api/Common/currencies';
  businessUnitTransferCostCenter = this.appConfig.apiUrl+ 'api/Common/costcentres';
  businessUnitTransferBrokarageAccount = this.appConfig.apiUrl+ 'api/BusinessUnitTransfer/GetBrokerageAccounts';
  saveBusinessUnitTransferRequest = this.appConfig.apiUrl+ 'api/BusinessUnitTransfer/Save';
  businessUnitTransferBase = this.appConfig.apiUrl+ 'api/BusinessUnitTransfer/GetBases';
  businessUnitTransferRequestDetails = this.appConfig.apiUrl+ 'api/BusinessUnitTransfer/GetRequest';
  // Workflow API URL'S
  deferralWorkFlowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlowForDeferral';
  accrualWorkFlowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlowForAccrual';
  businessUnitCorrectionWorkflowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlowForBusinessCorrection';
  businessUnitTransferWorkflowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlowForUnitTransfer';
  miscellaneousIncomeWorkFlowUrl = this.appConfig.apiUrl + 'api/WorkFlow/IntiateWorkFlowForMiscellaneous';

  //Miscellaneous income API Url
  miscellaneousIncomeTypeUrl = this.appConfig.apiUrl + "api/Common/incometypes";
  miscellaneousGetCurrencies = this.appConfig.apiUrl + 'api/Common/currencies';
  miscellaneousCostCentre = this.appConfig.apiUrl + 'api/Common/costcentres';
  saveMiscellaneousRequest = this.appConfig.apiUrl + 'api/Miscellaneous/Save';
  updateMiscellaneousRequest = this.appConfig.apiUrl + 'api/Miscellaneous/Update';
  miscellaneousIncomeRequestDetails = this.appConfig.apiUrl + 'api/Miscellaneous/GetRequestDetails';
  updateMiscellaneousRequestModal = this.appConfig.apiUrl + 'api/Miscellaneous/UpdateModel';
  miscellaneousFileDownloadUrl = this.appConfig.apiUrl + 'api/Miscellaneous/download';
  requestObjectData: object ;
  //Accrual Request Exists
  accrualRequestExists = this.appConfig.apiUrl + 'api/Accrual/IsAccrualRequestExists';
  exportSummaryAsPDF = this.appConfig.apiUrl + 'api/Common/ExportAsPdf';

  constructor(
    private router: Router, 
    private http: HttpClient,
    private utilsService: UtilsService,
    private pageNotfound: PageNotFoundService,
    private appConfig: AppConfig
  ) {}

  getTransactionNumbers(currentVal: string, transactionType: string, type?: number, year?:number, month?:number): Observable<object>{
    
    let url: string;
    
    switch(transactionType){
      case 'reversal':
        url = this.reversalTransactionNumberUrl;
        url += '?email=' + localStorage.getItem('userEmail');
      break;
      case 'corrected':
        url = this.correctedTransactionNumberUrl;
        url += '?email=' + localStorage.getItem('userEmail');
      break;
      case 'adjustment': 
        url = this.summaryTransactionUrl;
        url += '?userEmail=' + localStorage.getItem('userEmail');
        url += '&type=' + type;
        if(year)
          url += '&year=' + year;
        if(month)
          url += '&month=' + month;
      break;
      default:
        url = this.transactionNumberUrl;
        url += '?userEmail=' + localStorage.getItem('userEmail');
    }
    
    url += '&searchKey=' + currentVal;

    return this.http.get<any>(url);
  }

  getTransactionNumberDetails(transactionNumber: string, adjTypeId: string): Observable<object>{
    let url = this.transactionNumberDetailsUrl + '?TransactionNumber=' + transactionNumber + '&adjTypeId=' + adjTypeId;
    return this.http.get<any>(url);
  }

  getCorrectedTransactionNumberDetails(transactionNumber: string): Observable<object> {
    let url = this.correctedTransactionNumberDetailsUrl + '?TransactionNumber=' + transactionNumber;
    return this.http.get<any>(url);
  }

  getReversalTransactionNumberDetails(transactionNumber: string): Observable<object> {
    let url = this.reversalTransactionNumberDetailsUrl + '?TransactionNumber=' + transactionNumber;
    return this.http.get<any>(url);
  }

  getReasonsForAdjustment(adjustmentTypeId: number): Observable<object> {
    let url = this.reasonsForAdjustmentUrl+ '?adjType=' + adjustmentTypeId;
    return this.http.get(url);
  }

  //@ToDo: Change type to object when API returns an object later
  saveRequest(requestObj: object, requestType: string, fileExists: boolean): Observable<any>{
    let url: string;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let saveRequest;
    if(fileExists) {
      switch(requestType){
        case AdjustmentType.Deferral:
          url = this.saveDeferralRequestUrl;
        break;
        case AdjustmentType.BusinessUnitAdjustment:
          url = this.saveBusinessUnitAdjustmentUrl;
        break;
      }
      saveRequest = this.http.post(url, requestObj, httpOptions);
    } else {
      switch(requestType){
        case AdjustmentType.Deferral:
          url = this.updateDeferralRequestUrl;
        break;
        case AdjustmentType.BusinessUnitAdjustment:
          url = this.updateBusinessUnitAdjustmentUrl;
        break;
      }
      saveRequest = this.http.post(url, requestObj);
    }
    return saveRequest;
  }

  getRequestDetails(requestId: number, requestType: string): Observable<object>{
    this.requestDetails.next(null);
    let url: string;
    let email:string;
    email=localStorage.getItem('userEmail');
    switch (requestType) {
      case AdjustmentType.Deferral:
        url = this.deferralRequestDetailsUrl;
        break;
      case AdjustmentType.BusinessUnitAdjustment:
        url = this.businessUnitAdjustmentRequestDetailsUrl;
        break;
      case AdjustmentType.Accrual:
        url = this.accrualRequestDetailsUrl;
        break;
      case AdjustmentType.BusinessUnitAdjustmentTransfer:
        url = this.businessUnitTransferRequestDetails;
        break;
      case AdjustmentType.MiscellaneousIncome:
        url = this.miscellaneousIncomeRequestDetails;
        break;
    }
    url += '?requestId=' + requestId;
    url += '&email=' + email;
    this.http.get(url).subscribe(data => {
      this.utilsService.showLoadingIcon(false);
      if(!data){
        this.pageNotfound.headerTitle = "Record not found";
        this.router.navigate(['404']);
      }
      else{
        this.requestDetails.next(data);
      }
    }, 
    err => {
        this.utilsService.showLoadingIcon(false);
        if(err.status==401){
          this.router.navigate(['not-authorised']);
        }
        else{
        this.pageNotfound.headerTitle = "Record not found";
        this.router.navigate(['404']);
        }
    });
    return this.requestDetails.asObservable();
  }
  

  getPolicyReferenceAccrual(currentVal: string): Observable<object> {

    let url = this.policyReferenceAccrualUrl;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get<any>(url);
  }

  getPolicyReferenceDetailsAccrual(policyReference: string, financialYear?: number): Observable<object> {
    let url = this.policyReferenceDetailsAccrualUrl + '?policy=' + policyReference;
    if(financialYear) {
      url += "&financialYear=" + financialYear;
    }
    return this.http.get<any>(url);
  }

  getInsuredsAccrual(currentVal: string): Observable<object> {

    let url = this.insuredAccrualUrl;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get<any>(url);
  }

  getBrokerageAccountsAccrual(currentVal: string): Observable<object> {

    let url = this.brokerageAccrualUrl;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get<any>(url);
  }

  getRenewalTypesForAccrual(): Observable<object> {
    return this.http.get(this.renewalTypesAccrualUrl);
  }

  getIncomeTypesForAccrual(): Observable<object> {
    return this.http.get(this.incomeTypesAccrualUrl);
  }

  getCurrenciesForAccrual(): Observable<object> {
    return this.http.get(this.currenciesAccrualUrl);
  }

  saveUpdateAccrualRequest(requestObj: object): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    
    if(requestObj['requestId']){
      return this.http.put(this.updateAccrualUrl, requestObj, httpOptions);
    } else {
      if (requestObj['reAccrualAllowed'])
        return this.http.put(this.updateAccuralModalUrl, requestObj, httpOptions);
      else
        return this.http.post(this.saveAccrualUrl, requestObj, httpOptions);
    }    
  }
  getUserDetails(): Observable<object> {
    let userEmail = localStorage.getItem('userEmail');
    let url = this.getUserDetailsUrl +'?email='+userEmail;
    return this.http.get(url);
  }

  updateWorkFlow(request: object, userEmail: string, transition: number, comment: string, journalType?: number, description?: string): Observable<any>{
    let url:any;
    switch(request["type"]) {
      case AdjustmentType.Deferral: 
        url = this.deferralWorkFlowUrl;
        request["insuredName"] = request["insuredName"] ? request["insuredName"] : request["insureds"][0]["name"] ;
        request["brokerageAmount"] = request["amount"];
        request["brokerageRecognitionDate"] = request["incomeBrokerageRecognitionDate"];
        break;
      case AdjustmentType.Accrual: 
        url = this.accrualWorkFlowUrl;
        request["insuredName"] = request["insuredName"] ? request["insuredName"] : request["insureds"][0]["name"] ;
        request["incomeAmount"] = request["amount"];
        break;
      case AdjustmentType.BusinessUnitAdjustment:
        url = this.businessUnitCorrectionWorkflowUrl;
        request["insuredName"] = request["insuredName"] ? request["insuredName"] : request["insureds"][0]["name"];
        request["correctedBrokerageAccount"] = request["correctedBrokerageDetails"][0]["name"];
        request["correctedBrokerageIncome"] = request["correctedBrokerageDetails"][0]["brokerageAmount"];
        request["currentBrokerageAccountName"] = request["brokerageDetails"][0]["name"];
        request["currency"] = request["correctedBrokerageDetails"][0]["currencyCode"];
        break;
      case AdjustmentType.BusinessUnitAdjustmentTransfer:
        url = this.businessUnitTransferWorkflowUrl;
        request["incomeAmount"] = request["amount"];
        request["currentBUBase"] = request["brokerageDetails"][0]["base"];
        request["transferBUBase"] = request["transferBusinessUnit"][0]["base"];
        break;
      case AdjustmentType.MiscellaneousIncome:
        request["brokerageAmount"] = request["amount"];
        request["businessUnitBase"] = request["brokerageDetails"][0]["base"];
        url = this.miscellaneousIncomeWorkFlowUrl;
        break;
    };
    request["requestId"] = request["id"];
    request["userEmail"] = userEmail;
    request["requestComment"] = comment;
    request["brokerageAccountName"] = request["brokerageDetails"].length > 0 ?request["brokerageDetails"][0]["name"]: null;
    if(journalType) request["journalType"] = journalType;
    if(description) request["description"] = description;
    url += '?transition=' + transition;
    return this.http.post(url, request);
  }

  deferralFileUpload(formData, requestId){
    let url = this.deferralFileUploadUrl + '?AdjRequestID=' + requestId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    return this.http.post(url, formData, httpOptions);
  }

  businessUnitAdjustmentFileUpload(formData, requestId) {
    let url = this.businessUnitAdjustmentFileUploadUrl + '?AdjRequestID=' + requestId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    return this.http.post(url, formData, httpOptions);
  }

  accrualFileUpload(formData, requestId, isConfirmationOrder: boolean = false) {
    let url = this.accrualFileUploadUrl + '?AdjRequestID=' + requestId + '&IsConfirmationOrder=' + isConfirmationOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    return this.http.post(url, formData, httpOptions);
  }

  workflowSaveConfirmUpdate(actionType: string, sendTransitionOne: boolean, userEmail: string, request){
    
    switch(actionType){

      case 'save':
        if(sendTransitionOne){
          this.updateWorkFlow(request, userEmail, 1, '')
            .subscribe(dataWorkFlow1 => {

              this.utilsService.showMessageTip('Request saved successfully');
              this.utilsService.showLoadingIcon(false);
              this.router.navigate(['adjustments']);
            },
            errorDataWorkFlow1 => {
              this.utilsService.showMessageTip('Workflow could not be updated due to server error - ' + errorDataWorkFlow1['statusText']);
              this.utilsService.showLoadingIcon(false);
            }
          );
        } else {

          this.utilsService.showMessageTip('Request saved successfully');
          this.utilsService.showLoadingIcon(false);
          this.router.navigate(['adjustments']);
        }
      break;
      
      case 'confirm':
        
        if (sendTransitionOne) {
          this.updateWorkFlow(request, userEmail, 1, '')
            .subscribe(dataWorkFlow1 => {
              this.updateWorkFlow(request, userEmail, 2, '')
                .subscribe(dataWorkFlow2 => {
                  this.utilsService.showMessageTip('Request submitted successfully');
                  this.utilsService.showLoadingIcon(false);
                  this.router.navigate(['adjustments']);
                },
                  errorDataWorkFlow2 => {
                    this.utilsService.showMessageTip('Workflow could not be updated due to server error - ' + errorDataWorkFlow2['statusText']);
                    this.utilsService.showLoadingIcon(false);
                  }
                );
            },
              errorDataWorkFlow1 => {
                this.utilsService.showMessageTip('Workflow could not be updated due to server error - ' + errorDataWorkFlow1['statusText']);
                this.utilsService.showLoadingIcon(false);
              }
            );
        } else {
          let userTransition: number;
          if(request.userRoles.length > 0 && request.userRoles.indexOf('Tier 4') !== -1){
            userTransition = 2;
          } else if( request.userRoles.length > 0 && request.userRoles.indexOf('Tier 2') !== -1) {
            userTransition = 10;
          }
          this.updateWorkFlow(request, userEmail, userTransition, '')
            .subscribe(dataWorkFlow2 => {
              this.utilsService.showMessageTip('Request submitted successfully');
              this.utilsService.showLoadingIcon(false);
              this.router.navigate(['adjustments']);
            },
              errorDataWorkFlow2 => {
                this.utilsService.showMessageTip('Workflow could not be updated due to server error - ' + errorDataWorkFlow2['statusText']);
                this.utilsService.showLoadingIcon(false);
              }
            );
        }
      break;
      
      case 'edit': 
        this.updateWorkFlow(request, userEmail, 1, '')
          .subscribe(
            workflowResp => {
              this.utilsService.chooseAdjustmentRequestType(request.type, request.id);
            }
          )
    }
  }

  downloadAttachment(type: string, id: number, isConfirmationOrder?: boolean) {
    let url;
    if(type == "Accrual") {
      url = this.accrualFileDownloadUrl;
    } else if(type == "Deferral") {
      url = this.deferralFileDownloadUrl;
    } else if(type == "BusinessUnitAdjustment") {
      url = this.businessUnitAdjustmentFileDownloadUrl;
    } else if(type == "MiscellaneousIncomeAdjustment") {
      url = this.businessUnitAdjustmentFileDownloadUrl;
    }
    if(id) {
      url += id;
    }
    if(typeof isConfirmationOrder == 'boolean') {
      url += "&IsConfirmationOrder="+isConfirmationOrder;
    }

    this.http.get(url, {observe: 'response', responseType: 'arraybuffer' }).subscribe(
      response => {
        this.downloadFile(response)
      }
    );
  }

  getPolicyReferenceBusinessAdjustmentTransferReq(currentVal: string): Observable<object> {

    let url = this.businessUnitTransferRequestPolicyReference;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get <any> (url);
  }

  getBusinessAdjustmentTransferDropdownData(adjTypeId: number) {
    let reasons, incomeType, currencyList;
    reasons = this.getReasonsForAdjustment(adjTypeId);;
    incomeType = this.http.get(this.businessUnitTransferIncomeType);
    currencyList = this.http.get(this.businessUnitTransferCurrencies);
    return forkJoin([reasons, incomeType, currencyList]);
  }

  getBusinessAdjustmentTransferCostCenter(currentVal) {
    
    let url = this.businessUnitTransferCostCenter;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get <any> (url);
  }

  getBusinessAdjustmentTransferBrokerageAccounts(currentVal) {

    let url = this.businessUnitTransferBrokarageAccount;

    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get <any> (url);
  }

  saveUpdateBusinessUnitTransferRequest(requestObj: object): Observable<any> {
    return this.http.post(this.saveBusinessUnitTransferRequest, requestObj);
  }

  getBusinessAdjustmentTransferBases(currentVal) {
    
    let url = this.businessUnitTransferBase;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get <any> (url);
  }

  updateDescription(data) {
    let url = this.updateDescriptionUrl;
    return this.http.put(url, data);
  }

  getMiscellaneousIncomeAdjustmentDropdownData(adjTypeId: number) {
    let reasons,incomeType, currencyList;
    reasons = this.getReasonsForAdjustment(adjTypeId);
    incomeType = this.http.get(this.miscellaneousIncomeTypeUrl);
    currencyList = this.http.get(this.miscellaneousGetCurrencies);
    return forkJoin([reasons, incomeType, currencyList]);
  }

  getMiscellaneousIncomeCostCenter(currentVal) {
    let url = this.miscellaneousCostCentre;
    url += '?email=' + localStorage.getItem('userEmail');
    url += '&searchKey=' + currentVal;

    return this.http.get <any> (url);
  }

  saveUpdateMiscellaneousIncomeRequest(requestObj: object, fileExists: boolean, requestId): Observable<any> {
    if(requestId){
      if(fileExists) {
        return this.http.put(this.updateMiscellaneousRequest, requestObj);
      } else {
        return this.http.put(this.updateMiscellaneousRequestModal, requestObj);
      } 
    }else{
      return this.http.post(this.saveMiscellaneousRequest, requestObj);
    }  
  }

  isAccrualRequestExists(data) {
    return this.http.post(this.accrualRequestExists, data);
  }

  exportAsPDF(data) {
    //let url = this.exportSummaryAsPDF + '?userEmail=' + localStorage.getItem('userEmail');
    let url = this.exportSummaryAsPDF;
    return this.http.post(url, data ,{observe: 'response', responseType: 'arraybuffer' });
  }

  downloadFile(response) {
    const blob = new Blob([response.body]);
    const fileName = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace('"', '').replace('"', '');
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('visibility', 'hidden');
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
