import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AdjustmentStatus, AdjustmentType, Tiers, workflowType } from '../../shared/models/adjustment.model';

import DeferralModel from './deferral.model';
import AccrualModel from './accrual.model';
import BusinessUnitAdjustmentModel from './business-unit-adjustment.model';

import { DataCaptureFormsService } from './data-capture-forms.service';
import { UtilsService } from '../../shared/services/utils.service';
import BusinessUnitAdjustmentTransferModel from './business-unit-adjustment-transfer.model';
import MiscellaneousIncomeModel from './miscellaneous-income.model';

import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'summary-action',
  templateUrl: './summary-action.component.html',
  styleUrls: ['./data-capture-forms.scss']
})
export class SummaryActionComponent implements OnInit, OnDestroy {

  request: DeferralModel | AccrualModel | BusinessUnitAdjustmentModel | BusinessUnitAdjustmentTransferModel | MiscellaneousIncomeModel;

  currentComments: string;
  dataFetchComplete: boolean = false;
  subActivatedRoute: any;
  requestObj: object;
  tier: string[];
  adjustmentsViewType: string = 'Current';
  AdjustmentStatus: typeof AdjustmentStatus = AdjustmentStatus;
  AdjustmentType: typeof AdjustmentType = AdjustmentType;
  Tiers: typeof Tiers = Tiers;
  currentDescription: string;
  currentJournalType:any;
  workFlowType: typeof workflowType = workflowType;
  pdfStyles = `.container {padding: 1rem 2rem;}
               .width20P {width: 20% !important;}
               .template-heading {text-align: center;}
               .row-display {display: flex;flex-direction: row;}
               .summary-row {margin-top: 2em;margin-bottom: 2em;}
               .summary-label {color: rgba(0, 0, 0, 0.54);font-weight: bold;text-align: right;}
               .summary-value {color: rgba(0, 0, 0, 0.54);text-align: left;}
               .row-display > * {width: 20%;padding-right: 2em;}
               .summary-heading-line {border-width: 1px;border-style: solid;border-color: #ffffff #ffffff rgba(0, 0, 0, 0.54) #ffffff;}
               .summary-headings[_ngcontent-c18] {border: none;margin: 1em;}
               .width70P {width: 70% !important;}
               .width50P {width: 50% !important;}`;

  journalType: object[] = [
    {id: 1, name: "Reversing"},
    {id: 2, name: "Non-Reversing"}
  ];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService
  ) {
  }

  ngOnInit() {
    this.utilsService.showLoadingIcon(true);
    this.subActivatedRoute = this.activatedRoute.params.subscribe(params => {

      this.dataCaptureFormsService.getRequestDetails(+params['id'], params['type'])
        .subscribe((result) => {
          if(result) {
            this.setRetrievedRequestValues(result);
            this.currentDescription = result["description"];
            this.currentJournalType = result["journalType"];
            this.dataFetchComplete = true;
          }
        });
    });
    this.utilsService.tier.subscribe(data => this.tier = data);
    this.adjustmentsViewType = this.utilsService.adjustmentsViewType;
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }

  editRequest(e: Event) {
    this.utilsService.showLoadingIcon(true);
    let userEmail = localStorage.getItem('userEmail');
    if(this.request.status === 'Rejected') {
      this.dataCaptureFormsService.workflowSaveConfirmUpdate(
        'edit',
        true, 
        userEmail, 
        this.request
        );
    } else {
      this.utilsService.chooseAdjustmentRequestType(this.request.type, this.request.id);
    } 
  }

  reAccrueRequest(e: Event) {
    this.router.navigate(['accrual-data-capture/edit', this.request.id, 'reAccrual']);
  }
  reDeferralRequest(e: Event) {
    this.router.navigate(['deferral-data-capture/edit', this.request.id, 'reDeferral']);
  }
  backBtnClick(e: Event): void {
    this.router.navigate(['adjustments']);
  }

  submitRequest(e: Event, summaryActionForm: NgForm, actionType: string) {

    if (!summaryActionForm.valid) {
      return;
    }

    let userEmail = localStorage.getItem('userEmail');
    let transition = null;
    this.utilsService.showLoadingIcon(true);
    switch (this.request.status) {
      case AdjustmentStatus.Requested:
        if (actionType == 'reject') {
          transition = 3;
        } else {
          transition = 4;
        }
        break;
      case AdjustmentStatus.Authorized:
        if (actionType == 'reject') {
          transition = 5;
        } else {
          transition = 6;
        }
        break;
      case AdjustmentStatus.Submitted:
        if (actionType == 'reject') {
          transition = 7;
        } else {
          transition = 9;
        }
        break;
    }

    this.dataCaptureFormsService.updateWorkFlow(
      this.request,
      userEmail,
      transition,
      this.currentComments,
      this.request.journalType,
      this.request.description
    ).subscribe(
      data => {
        if(this.request.journalType !== this.currentJournalType || this.request.description !== this.currentDescription){
          this.dataCaptureFormsService.updateDescription({
            adjRequestID: this.request.id,
            description: this.request.description,
            JournalType: this.request.journalType
          }).subscribe(
            (data) => {
              this.router.navigate(['adjustments']);
              this.utilsService.showMessageTip("Record successfully updated");     
            },
            (error) => {
              this.utilsService.showLoadingIcon(false);
              this.utilsService.showMessageTip(error["error"]);
            }
          )
        } else {
          this.router.navigate(['adjustments']);
          this.utilsService.showMessageTip("Record successfully updated");
        }
      },
      error => {
        this.utilsService.showMessageTip(error['error']['error']);
        this.utilsService.showLoadingIcon(false);
      }
    );
  }

  setRetrievedRequestValues(requestObj: object): void {

    switch (requestObj['typeName']) {
      case AdjustmentType.Deferral:
        this.request = new DeferralModel();
        this.request.setValuesFromAPIObj(requestObj);
        break;
      case AdjustmentType.BusinessUnitAdjustment:
        this.request = new BusinessUnitAdjustmentModel();
        this.request.setValuesFromAPIObj(requestObj);
        break;
      case AdjustmentType.Accrual:
        this.request = new AccrualModel();
        this.request.setValuesFromAPIObj(requestObj);
        break;
      case AdjustmentType.BusinessUnitAdjustmentTransfer:
        this.request = new BusinessUnitAdjustmentTransferModel();
        this.request.setValuesFromAPIObj(requestObj);
        break;
      case AdjustmentType.MiscellaneousIncome:
        this.request = new MiscellaneousIncomeModel();
        this.request.setValuesFromAPIObj(requestObj);
        break
    }
  }

  onDeferralDownloadUrlClick() {
    this.dataCaptureFormsService.downloadAttachment('Deferral', this.request.id);
  }

  onAccrualDownloadUrlClick(isConfirmationOrder) {
    this.dataCaptureFormsService.downloadAttachment('Accrual', this.request.id, isConfirmationOrder);
  }

  onBusinessUnitAdjustmentDownloadUrlClick() {
    this.dataCaptureFormsService.downloadAttachment('BusinessUnitAdjustment', this.request.id);
  }

  onMiscIncomeDownloadUrlClick() {
    this.dataCaptureFormsService.downloadAttachment('MiscellaneousIncomeAdjustment', this.request.id);
  }

   exportAdjSummaryAsPDF(e: Event) {
     this.utilsService.showLoadingIcon(true);
    let outerHtml = document.querySelector('#adj-detailed-summary').outerHTML;
    outerHtml = outerHtml.replace(/"/g, '\'');
    outerHtml = outerHtml.replace(/href=\'javascript:void\(0\);\'/g, "");
    let pdfTemplate = `
    <style>${this.pdfStyles}</style>
    <div class='container'>
    <div class='template-heading'>
        <h1>${this.request.type} Request ${this.request.id} Summary</h1>
    </div>
      ${outerHtml}
    </div>
    `
    this.dataCaptureFormsService.exportAsPDF({htmlInputString: pdfTemplate}).subscribe((response) => {
      this.dataCaptureFormsService.downloadFile(response);
      this.utilsService.showLoadingIcon(false);
    })
  }

}
