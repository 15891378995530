import { AdjustmentType, AdjustmentStatus } from '../../shared/models/adjustment.model';

export default class MiscellaneousIncomeModel {
    type: string = AdjustmentType.MiscellaneousIncome;
    id: number;
    requestDate: Date = new Date();

    reasonForMiscellaneousId: number;
    reasonForMiscellaneous: string;

    incomeTypeId: number;
    incomeType: string;

    currencyTypeId: number;
    currencyTypeCode: string;

    amount: any;

    brokerageEarningDate: Date;

    adjMiscellaneousID: number;

    costCentre: string;

    allowDuplicates : boolean = false;

    otherReason: string;

    journalType: number = 2;
    description: string ="";
    workFlowType: number;

    userRoles: string[];

    adjTypeID: number = 5;

    slipFile: File;
    slipFileName: string;
    slipExistingFileName: string;


    status: string = AdjustmentStatus.Incomplete;
    statusId: number;

    //@ToDo: move comments out and use updated variables like submittedComments etc.. as per status
    comments: string;
    //@ToDo: move processedBy out and use updated variables like authorizedBy etc..
    processedBy: string;
    requestedBy: string;
    requestedById: number;
    submittedBy: string;
    submittedById: number;
    submittedComments: string;
    authorizedBy: string;
    authorizedById: number;
    authorizedComments: string;
    approvedBy: string;
    approvedById: number;
    approvedComments: string;
    rejectedBy: string;
    rejectedById: number;
    rejectedComments: string;

    brokerageDetails: object[] = [{
        id: <number>null,
        accountCode: <string>null,
        name: <string>null,
        brokerageAmount: <any>null,
        brokerageRecogDate: <Date>null,
        currencyID: <number>null,
        currencyCode: <string>null,
        businessUnitGroup2: <string>null,
        businessUnitGroup3: <string>null,
        businessUnitGroup4: <string>null,
        fyBusinessUnitID: <number>null,
        costCentre: <string>null,
        companyCode: <string>null,
        base: <string>null
    }];

    setBrokerageDetailsValuesFromCostCentre(respObj) {
        if(respObj.length) {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            this.brokerageDetails[0]['name'] = respObj[0].name;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['businessUnitGroup2'] = respObj[0].businessUnitGroup2;
            this.brokerageDetails[0]['businessUnitGroup3'] = respObj[0].businessUnitGroup3;
            this.brokerageDetails[0]['businessUnitGroup4'] = respObj[0].businessUnitGroup4;
            this.brokerageDetails[0]['id'] = respObj[0].id;
            this.brokerageDetails[0]['brokerageAmount'] = respObj[0].brokerageAmount;
            this.brokerageDetails[0]['brokerageRecogDate'] = respObj[0].brokerageRecogDate;
            this.brokerageDetails[0]['currencyID'] = respObj[0].currencyID;
            this.brokerageDetails[0]['currencyCode'] = respObj[0].currencyCode;
            this.brokerageDetails[0]['fyBusinessUnitID'] = respObj[0].fyBusinessUnitID;
            this.brokerageDetails[0]['companyCode'] = respObj[0].companyCode;
        } else {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            this.brokerageDetails[0]['name'] = null;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = null;
            this.brokerageDetails[0]['businessUnitGroup2'] = null;
            this.brokerageDetails[0]['businessUnitGroup3'] = null;
            this.brokerageDetails[0]['businessUnitGroup4'] = null;
            this.brokerageDetails[0]['id'] = null;
            this.brokerageDetails[0]['brokerageAmount'] = null;
            this.brokerageDetails[0]['brokerageRecogDate'] = null;
            this.brokerageDetails[0]['currencyID'] = null;
            this.brokerageDetails[0]['currencyCode'] = null;
            this.brokerageDetails[0]['fyBusinessUnitID'] = null;
            //this.brokerageDetails[0]["costCentre"] = null;
            this.brokerageDetails[0]["companyCode"] = null;
        }
    }

    getSaveObject() {
        let brokerageEarningDateObj;
        let brokerageEarningDateateTimeMs;
        let brokerageEarningDateTimeZoneDate;
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let userRoleStatus: string;

        brokerageEarningDateObj = new Date(this.brokerageEarningDate);
        brokerageEarningDateateTimeMs = brokerageEarningDateObj.getTime() - (brokerageEarningDateObj.getTimezoneOffset() * 60 * 1000);
        brokerageEarningDateTimeZoneDate = new Date(brokerageEarningDateateTimeMs);
        this.description = `${monthNames[brokerageEarningDateObj.getMonth()]} miscellaneous income - ${this.brokerageDetails[0]['businessUnitGroup3']}`;

        if(this.userRoles && this.userRoles.indexOf('Tier 4') !== -1){
            userRoleStatus = "RequestedToAuthorised";
          } else if( this.userRoles && this.userRoles.indexOf('Tier 2') !== -1) {
            userRoleStatus = "IncompleteToSumitted";
          }

        return {
            "adjMiscellaneousID": this.adjMiscellaneousID,
            "brokerageAmount": this.amount,
            "brokerageEarningDate": brokerageEarningDateTimeZoneDate,
            "reasonForMiscellaneous": this.reasonForMiscellaneous,
            "fyBusinessUnitID": this.brokerageDetails[0]["fyBusinessUnitID"],
            "description": this.description,
            "businessUnitBase": this.brokerageDetails[0]["base"],
            "brokerageAccountName": this.brokerageDetails[0]["name"],
            "requestId": this.id ? this.id : null,
            "adjTypeID": this.adjTypeID,
            "reasonID": this.reasonForMiscellaneousId,
            "incomeTypeID": this.incomeTypeId,
            "currencyID": this.currencyTypeId,
            "userEmail": localStorage.getItem('userEmail'),
            "otherReason": this.otherReason,
            "requestComment": "",
            "journalType": this.journalType,
            "transition": userRoleStatus,
            "currencyTypeCode": this.currencyTypeCode,
            "workFlowType": this.workFlowType
          }
    }

    setValuesFromAPIObj(requestObj: object): void {
        if(requestObj) {
            this.id = requestObj['requestId'];
            this.adjMiscellaneousID = requestObj["adjMiscellaneousID"];
            this.adjTypeID = requestObj["adjTypeID"];
            this.reasonForMiscellaneous = requestObj["reason"];
            this.reasonForMiscellaneousId = requestObj["reasonId"];
            this.incomeType = requestObj["incomeName"];
            this.incomeTypeId = requestObj["incomeTypeId"];
            this.brokerageDetails =  [requestObj["fyBusinessUnitDetails"]];
            if(requestObj["transRequest"] && requestObj["transRequest"]["currency"]) {
                this.currencyTypeCode = requestObj["transRequest"]["currency"]["code"];
                this.currencyTypeId = requestObj["transRequest"]["currency"]["currencyID"];
            }
            if(requestObj["transRequest"]){
                this.amount = requestObj["transRequest"]["brokerageAmount"];
                this.brokerageEarningDate = requestObj["transRequest"]["brokerageDate"];
            }
            if(requestObj["description"]) this.description = requestObj["description"];
            if(requestObj["journalType"]) this.journalType = requestObj["journalType"];
            
            if(requestObj["workFlowType"]) this.workFlowType = requestObj["workFlowType"];

            this.slipExistingFileName = requestObj['slipFileName'];

            this.otherReason = requestObj["otherReason"];
            this.requestedById = (requestObj['requestedBy'] && requestObj['requestedBy']['id']) ? requestObj['requestedBy']['id'] : null;
            this.requestedBy = (requestObj['requestedBy'] && requestObj['requestedBy']['name']) ? requestObj['requestedBy']['name'] : null;
            this.submittedById = (requestObj['submittedBy'] && requestObj['submittedBy']['id']) ? requestObj['submittedBy']['id'] : null;
            this.submittedBy = (requestObj['submittedBy'] && requestObj['submittedBy']['name']) ? requestObj['submittedBy']['name'] : null;
            this.submittedComments = (requestObj['submittedBy'] && requestObj['submittedBy']['comments']) ? requestObj['submittedBy']['comments'] : null;

            this.authorizedById = (requestObj['authorizedBy'] && requestObj['authorizedBy']['id']) ? requestObj['authorizedBy']['id'] : null;
            this.authorizedBy = (requestObj['authorizedBy'] && requestObj['authorizedBy']['name']) ? requestObj['authorizedBy']['name'] : null;
            this.authorizedComments = (requestObj['authorizedBy'] && requestObj['authorizedBy']['comments']) ? requestObj['authorizedBy']['comments'] : null;

            this.approvedById = (requestObj['approvedBy'] && requestObj['approvedBy']['id']) ? requestObj['approvedBy']['id'] : null;
            this.approvedBy = (requestObj['approvedBy'] && requestObj['approvedBy']['name']) ? requestObj['approvedBy']['name'] : null;
            this.approvedComments = (requestObj['approvedBy'] && requestObj['approvedBy']['comments']) ? requestObj['approvedBy']['comments'] : null;

            this.rejectedById = (requestObj['rejectedBy'] && requestObj['rejectedBy']['id']) ? requestObj['rejectedBy']['id'] : null;
            this.rejectedBy = (requestObj['rejectedBy'] && requestObj['rejectedBy']['name']) ? requestObj['rejectedBy']['name'] : null;
            this.rejectedComments = (requestObj['rejectedBy'] && requestObj['rejectedBy']['comments']) ? requestObj['rejectedBy']['comments'] : null;

            this.status = requestObj["status"];
        }
    }
}