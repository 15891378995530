<div class="docs-primary-header component-page-header">
    <button mat-button class="sidenav-toggle">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>{{userAuth.headerTitle}}</h1>
  </div>
  <div class="col-display content-main-container">
    <div class="nopage-container">
      <div class="col-display">
        <mat-card>
          <mat-card-content>
              <ng-container>
                <div class="row-display nopage-row">
                    <div class="centeralign">
                      <div class="notfound">{{userAuth.errorCode}}!</div>
                      <div class="err-msg">{{userAuth.errorMessage}}</div><br/>
                      <button mat-raised-button (click)="performNavigation()">Home</button>
                    </div>
                </div>
              </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  
  
  
  