
export class AADConfig {
    clientId: string;
    authority: string;
    redirectUri: string;
    apiScope: string;
}


export class AppConfig {
    production: boolean;
    apiUrl: string;
    AADConfig: AADConfig;
    appInsights: any;
}
