import { Injectable } from '@angular/core';
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { AppConfig } from '../app/app.config';

@Injectable()
export class AuthConfig {

    isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    msalConfig: Configuration = {
        auth: {
            clientId: this.appconfig.AADConfig.clientId,
            authority: this.appconfig.AADConfig.authority,
            redirectUri: this.appconfig.AADConfig.redirectUri
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: this.isIE, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback(logLevel: LogLevel, message: string) {
                    // put logger to check logs if required
                },
                logLevel: LogLevel.Verbose,
                piiLoggingEnabled: false
            }
        }
    };
    protectedResources = {
        api: {
            endpoint: this.appconfig.apiUrl,
            scopes: [this.appconfig.AADConfig.apiScope],
        },
    };
    loginRequest = {
        scopes: []
    };

    constructor(private appconfig: AppConfig) { }
}
