// This service to handle authentication services for the application
import { Injectable } from '@angular/core';
// import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { AuthConfig } from 'src/assets/auth-config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoginSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private router: Router, private dialog: MatDialog, private authService: MsalService, private authConfig: AuthConfig) { }

  sessionTimer;
  sessionTime = 3000000;

  // Reset the settings, logout from okta if not logged out, redirect to login
  async doLogout() {
    localStorage.setItem('userProfile', JSON.stringify({}));
    localStorage.setItem('userEmail', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('renewalNoteVisible', null);
    localStorage.setItem('browserNoteVisible', null);
    localStorage.setItem('resolutionNoteVisible', null);
    await this.authService.instance.logoutRedirect();
    this.router.navigate(['/login']);
  }

  getLoginUserDetails() {
    return this.authService.instance.getAllAccounts();
  }

  async checkSessionAuth() {
    // return (await this.oktaAuth['oktaAuth'].session.exists()) && (await this.oktaAuth.isAuthenticated());
    // this.authService.instance.getAllAccounts();
    return await this.authService.instance.getAllAccounts().length > 0;
  }

  async refreshSession() {
    // await this.oktaAuth['oktaAuth'].session.refresh();
  }

  //   setSessionTimer() {
  //     if (this.sessionTimer) {
  //       clearTimeout(this.sessionTimer);
  //     }
  //     this.sessionTimer = setTimeout(() => {
  //       const dialogRef = this.dialog.open(SessionTimeOutDialogComponent, {
  //         width: '340px',
  //         disableClose: true,
  //         data: {}
  //       });
  //     }, this.sessionTime);
  //   }
}
