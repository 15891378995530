import { NgModule } from "@angular/core";
import { AmountPipe } from "./amount.pipe";

@NgModule({
    imports: [

    ],
    declarations: [
        AmountPipe
    ],
    exports: [
        AmountPipe
    ]
})

export class PipesModule {}