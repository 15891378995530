export class Adjustment {
    id: number;
    type: AdjustmentType;
    transactionNumber: string;
    brokerage: Date;
    status: AdjustmentStatus;
    createdBy: string;

    /**
     *
     */
    constructor(id: number,
        type: AdjustmentType,
        transactionNumber: string,
        brokerage: Date,
        status: AdjustmentStatus,
        createdBy: string,
    ) {
        this.id = id;
        this.type = type,
        this.transactionNumber = transactionNumber,
        this.brokerage = brokerage;
        this.status = status;
        this.createdBy = createdBy;
    }
}

export enum AdjustmentType {
    Accrual = "Accrual",
    BusinessUnitAdjustment = "Business Unit Correction",
    Deferral = "Deferral",
    BusinessUnitAdjustmentTransfer = "Business Unit Transfer",
    MiscellaneousIncome = "Miscellaneous Income"
}

export enum AdjustmentStatus {
    Approved = "Approved",
    Authorized = "Authorised",
    Incomplete = "Incomplete",
    Rejected = "Rejected",
    Requested = "Requested",
    Submitted = "Submitted",
    Closed = "Closed"
}

export enum Tiers{
    Tier1 = "Tier 1",
    Tier2 = "Tier 2",
    Tier3 = "Tier 3",
    Tier4 = "Tier 4"
}

export enum workflowType{
    BusinessToHeadFinance  = 1,
    FinanceToHeadFinace = 2
}
