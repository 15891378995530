<div class="docs-primary-header component-page-header">
  <button mat-button class="sidenav-toggle">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="row-display">
    <h1 class="width40P">{{ request.type }} Data Capture</h1>
    <div class="width40P request-date request-date-placement">Request Date: {{ request.requestDate | date:'d/MM/yyyy' }} &nbsp;|&nbsp; Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy'}}</div>
  </div>
</div>
<div class="col-display content-main-container">
  <div class="form-container" [hidden]="!hideSummary">
    <form #deferralForm="ngForm">

      <div class="col-display">

        <mat-form-field class="width40P field-with-hint">
          <input required matInput placeholder="Transaction Number" [matAutocomplete]="transactionNumberAutoComplete"
            #transactionNumber="ngModel" [(ngModel)]="request.transactionNumber" name="transactionNumber"
            (ngModelChange)="autoCompleteTransactionNumbers()" (blur)="transactionNumberBlur($event)" userSessionExists>
          <mat-hint *ngIf="isTransactionNumbersExists">Please type any 3 letters to search transaction</mat-hint>
          <mat-hint *ngIf="!isTransactionNumbersExists">No transactions found</mat-hint>
          <mat-error>Please provide a Transaction Number</mat-error>
        </mat-form-field>

        <mat-autocomplete #transactionNumberAutoComplete="matAutocomplete">
          <mat-option *ngFor="let trNo of transactionNumbers | async" [value]="trNo.transactionNumber">
            {{trNo.transactionNumber}}
          </mat-option>
        </mat-autocomplete>

        <mat-form-field class="width20P">
          <input matInput disabled [matDatepicker]="pickerEffectiveDate" placeholder="Effective Date" [(ngModel)]="request.effectiveDate"
            name="effectiveDate" userSessionExists>
          <mat-datepicker #pickerEffectiveDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width40P">
          <mat-select required placeholder="Reason for Deferral" [(ngModel)]="request.reasonForDeferralId" name="reasonForDeferralId"
            (selectionChange)="reasonForDeferralChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of reasonsForDeferralOptions; let in=index" [value]="currentVal.reasonID">
              {{ currentVal.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a reason</mat-error>
        </mat-form-field>

        <mat-form-field class="width50P" *ngIf="request.reasonForDeferral=='Other'">
          <textarea required matInput placeholder="Enter details in case of other" [(ngModel)]="request.otherReason"
            name="otherReason" userSessionExists></textarea>
          <mat-error>
            Please provide details</mat-error>
        </mat-form-field>

        <h2>Insured</h2>

        <div class="row-display" *ngFor="let currentVal of request.insureds">
          <mat-form-field class="width70P">
            <input matInput disabled placeholder="Insured" [(ngModel)]="currentVal.name" [ngModelOptions]="{standalone: true}">
          </mat-form-field>

          <mat-form-field>
            <input matInput disabled placeholder="Insured Code" [(ngModel)]="currentVal.code" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>

        <h2>Brokerage Account</h2>
        <div class="row-display">

          <mat-form-field class="width40P">
            <mat-select required placeholder="Brokerage Account" [(ngModel)]="request.deferredBrokerageAccountID" name="deferredBrokerageAccountID"
              (selectionChange)="brokerageAccountChange($event)" userSessionExists>
              <mat-option *ngFor="let currentVal of request.brokerageDetails; let in=index" [value]="currentVal.id">
                {{ currentVal.name }}
              </mat-option>
            </mat-select>
            <mat-error>Please select a Brokerage Account</mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput disabled [(ngModel)]="request.accountCode" name="accountCode">
          </mat-form-field>
        </div>

        <div class="row-display">
          <mat-form-field>
            <input matInput disabled placeholder="Business Unit" [(ngModel)]="request.businessUnitGroup2" name="businessUnitGroup2">
          </mat-form-field>

          <mat-form-field>
            <input matInput disabled [(ngModel)]="request.businessUnitGroup3" name="businessUnitGroup3">
          </mat-form-field>

          <mat-form-field>
            <input matInput disabled [(ngModel)]="request.businessUnitGroup4" name="businessUnitGroup4">
          </mat-form-field>
        </div>

        <h2>Current</h2>
        <ng-container *ngFor="let currentVal of request.selectedBrokerageDetails">
          <div class="row-display deferral-income-type-row">
            <mat-form-field class="hide">
              <input matInput disabled placeholder="Income Type" [(ngModel)]="request.incomeType"  [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="deferral-currency">
              <input matInput disabled placeholder="Currency" [(ngModel)]="currentVal.currencyCode"  [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="deferral-amount">
              <input matInput disabled placeholder="Brokerage Amount" [ngModel]="currentVal.brokerageAmount | amountPipe"
                (ngModelChange)="currentVal.brokerageAmount=$event"  [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="deferral-brokerage-date">
              <input matInput disabled [matDatepicker]="pickerIncomeBrokerageRecognitionDate" placeholder="Brokerage Earning Date"
                [(ngModel)]="currentVal.brokerageRecogDate"  [ngModelOptions]="{standalone: true}">
              <mat-datepicker #pickerIncomeBrokerageRecognitionDate></mat-datepicker>
            </mat-form-field>
          </div>
      </ng-container>

        <h2>Deferral Request</h2>

        <div class="row-display">
          <div class="col-display width100P">
            <div class="row-display deferral-requests-row" *ngFor="let deferralRequestVar of request.deferralRequests; let in=index; let i=index; first as isFirst">
              
              <mat-form-field class="deferral-currency" *ngIf="request.selectedBrokerageDetails.length == 1">
                <input disabled matInput placeholder="Currency" [(ngModel)]="deferralRequestVar.currency" [ngModelOptions]="{standalone: true}" name="deferralRequestVar.currency" userSessionExists>
              </mat-form-field>

              <mat-form-field class="deferral-currency" *ngIf="request.selectedBrokerageDetails.length > 1">
                <mat-select required placeholder="Currency" [(ngModel)]="deferralRequestVar.currency" name="deferralRequestCurrency" [ngModelOptions]="{standalone: true}"
                [disabled]="request.reDeferralAllowed && !deferralRequestVar.isCurrentPeriodId" userSessionExists (selectionChange)="deferralRequestCurrencyChange($event)"  >
                <mat-option *ngFor="let currentVal of request.brokerageDetails; let in=index" [value]="currentVal.currencyCode">
                {{ currentVal.currencyCode }}
                </mat-option>
            </mat-select>
            <mat-error>Please select a Currency </mat-error>
              </mat-form-field>

              <mat-form-field class="deferral-amount">
                <input required matInput placeholder="Brokerage Amount" [ngModel]="deferralRequestVar.brokerage | amountPipe"
                  (ngModelChange)="deferralRequestVar.brokerage=$event" [ngModelOptions]="{standalone: true,updateOn:'blur'}" (keypress)="utilsService.validateNumberOnly($event)" (keyup)="validateAmountField($event.target.value)" userSessionExists
                  [disabled]="request.reDeferralAllowed && !deferralRequestVar.isCurrentPeriodId">
                 
                </mat-form-field>
             
              <mat-form-field class="deferral-brokerage-date"  >
                <input required [min]="request.incomeBrokerageRecognitionDate" matInput [matDatepicker]="earningDatePicker"
                  placeholder="Brokerage Earning Date" [(ngModel)]="deferralRequestVar.brokerageRecognitionDate"
                  [ngModelOptions]="{standalone: true}" userSessionExists [disabled]="request.reDeferralAllowed && !deferralRequestVar.isCurrentPeriodId">
               
                <mat-datepicker-toggle matSuffix [for]="earningDatePicker" [hidden]="request.reDeferralAllowed && !deferralRequestVar.isCurrentPeriodId"></mat-datepicker-toggle>
                <mat-datepicker #earningDatePicker ></mat-datepicker>
              
                <mat-error>
                  Please provide unique dates by currency with at least one date in a future period
                </mat-error>
              </mat-form-field>

              <mat-icon *ngIf="deferralRequestVar.isDisabled && request.multiCurrencies.length > 0" class="icon-cls icon-add-req"  (click)="addDeferralRequests($event, deferralRequestVar.currency)" userSessionExists>add_circle_outline</mat-icon>              
              <mat-icon *ngIf="(request.reDeferralAllowed && deferralRequestVar.isCurrentPeriodId);else normalDeferral"  class="icon-cls icon-add-req" (click)="removeDeferralRequests($event, i)" userSessionExists>delete</mat-icon>
              <ng-template #normalDeferral>
                <mat-icon *ngIf="(!request.reDeferralAllowed && !deferralRequestVar.isDisabled && request.multiCurrencies.length > 0)" class="icon-cls icon-add-req" (click)="removeDeferralRequests($event, i)" userSessionExists>delete</mat-icon>

                
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row-display error-msg" [hidden]='!brokerageAmountErrMsg && !brokerageDateErrMsg'>
          <div class="brokerage-amount-error-msg" [hidden]='!brokerageAmountErrMsg' [innerHTML]="brokerageAmountErrMsg"></div>
          <div class="brokerage-date-error-msg width30P" [hidden]='!brokerageDateErrMsg' [innerHTML]="brokerageDateErrMsg"></div>
        </div>

        <h2>Supporting Documentation</h2>

        <div class="row-display">
          <div class="width70P">
            Upload Slip: &nbsp;&nbsp;
            <button mat-raised-button (click)="chooseFile($event)" class="fileuploadButton" >Choose</button>
            <input type="text" (click)="chooseFile($event)" [value]="request.slipFileName ? request.slipFileName : ''"/>
            <input type='file' id="slipfileUpload" class="displaynone" (change)="slipFileUploadChange($event.target.files)" #slipFileUploadField userSessionExists >
            <mat-icon class="adc-remove-icon" (click)="slipFileRemove($event, slipFileUploadField)" userSessionExists>delete</mat-icon>
          </div>
        </div>

        <div class="row-display existing-file-row" *ngIf="request.slipExistingFileName">
          <div class="width70P">
            Current File*: &nbsp;&nbsp;
            <a href="javascript:void(0);" userSessionExists (click)="onDownloadCurrentFile()" userSessionExists>{{
              request.slipExistingFileName }}</a>
          </div>
        </div>
        <div class="data-capture-info-text" *ngIf="slipUploaded">*Uploading new file will replace existing file. If file exists</div>

        <!-- <p class="file-override" *ngIf="request.slipExistingFileName">
          Note*: Uploading a new file will override the existing one
        </p> -->

        <div *ngIf="errorMsg" class="row-display error-msg deferral-error-msg" [hidden]='!errorMsg' [innerHTML]="errorMsg">
        </div>

        <div class="row-display btn-row">
          <!-- <button mat-raised-button (click)="saveConfirmRequest($event, deferralForm, 'save')">Save</button>
          &nbsp;&nbsp;&nbsp;&nbsp; -->
          <button mat-raised-button (click)="toggleFormSummaryDisplay($event, deferralForm)" >Next</button>
        </div>

      </div>
    </form>
  </div>
  <div class="summary-container" [hidden]="hideSummary">
    <div class="col-display">

      <mat-card>
        <mat-card-title>
          Summary
        </mat-card-title>
        <mat-card-content>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Transaction Number: </div>
            <div class="width20P summary-value">{{ request.transactionNumber }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Effective Date </div>
            <div class="width20P summary-value">{{ request.effectiveDate | date:'d/MM/yyyy' }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Reason for Deferral: </div>
            <div class="width20P summary-value">{{ request.reasonForDeferral }}</div>
            <div class="width20P summary-value" *ngIf="request.reasonForDeferral=='Other'">{{ request.otherReason }}</div>
          </div>

          <h2>Insured</h2>

          <ng-container *ngFor="let currentVal of request.insureds">
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Insured: </div>
              <div class="width70P summary-value ">{{ currentVal.name }}</div>
            </div>
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Insured Code: </div>
              <div class="width70P summary-value ">{{ currentVal.code }}</div>
            </div>
          </ng-container>

          <h2>Brokerage Account</h2>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Brokerage Account: </div>
            <div class="width20P summary-value">{{ request.brokerageAccount }}</div>

            <div class="width20P summary-label">Account Code: </div>
            <div class="width20P summary-value">{{ request.accountCode }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Business Unit: </div>
            <div class="width70P summary-value">{{ request.businessUnitGroup2
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.businessUnitGroup3
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.businessUnitGroup4 }}
            </div>
          </div>

          <h2>Current</h2>
          <ng-container *ngFor="let currentVal of request.selectedBrokerageDetails">
            <div class="row-display summary-row">
              <div class="width20P summary-label">Brokerage: </div>
              <div class="width20P summary-value">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}</div>
              <div class="width20P summary-label">Brokerage Earning Date: </div>
              <div class="width20P summary-value">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
            </div>
          </ng-container>

          <h2>Deferral Requests</h2>

          <div class="row-display summary-row" *ngFor="let deferralRequestVar of request.deferralRequests; let in=index;">
            <div class="width20P summary-label">Brokerage: </div>
            <div class="width20P summary-value">{{ deferralRequestVar.currency }}&nbsp;{{ deferralRequestVar.brokerage
              | amountPipe }}</div>
            <div class="width20P summary-label">Brokerage Earning Date: </div>
            <div class="width20P summary-value">{{ deferralRequestVar.brokerageRecognitionDate | date:'d/MM/yyyy' }}</div>
          </div>
       
          <h2>Supporting Documentation</h2>
          <div class="row-display summary-row" *ngIf="request.slipFileName">
            <div class="width20P summary-label">Slip: </div>
            <div class="width20P summary-value">{{ request.slipFileName }}</div>
          </div>

          <div class="row-display summary-row" *ngIf="request.slipExistingFileName">
            <div class="width20P summary-label">Current Slip: </div>
            <div class="width60P summary-value">
              <a href="javascript:void(0);" (click)="onDownloadCurrentFile()">{{
                request.slipExistingFileName }}</a>
            </div>
          </div>

          <div class="row-display btn-row summary-btn-row">
            <button mat-raised-button (click)="toggleFormSummaryDisplay($event, deferralForm)" >Back</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button mat-raised-button (click)="saveConfirmRequest($event, deferralForm, 'confirm')" >Confirm</button>
          </div>

        </mat-card-content>
      </mat-card>

    </div>
  </div>
</div>
