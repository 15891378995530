import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { WindowRef } from '../../shared/services/window-ref.service';
import { DataCaptureFormsService } from './data-capture-forms.service';
import { UtilsService } from '../../shared/services/utils.service';

import BusinessUnitAdjustmentModel from './business-unit-adjustment.model';
import { AdjustmentStatus, AdjustmentType } from '../../shared/models/adjustment.model';


@Component({
  selector: 'business-unit-adjustment',
  templateUrl: './business-unit-adjustment.component.html',
  styleUrls: ['./data-capture-forms.scss']
})
export class BusinessUnitAdjustmentComponent implements OnInit, OnDestroy {

  request: BusinessUnitAdjustmentModel = new BusinessUnitAdjustmentModel();;
  transactionNumbers: any;
  isTransactionNumbersExists: boolean = true;
  
  isReversalTransactionNumberExists: boolean = true;
  isCorrectedTransactionNumberExists: boolean = true;

  reversalTransactionNumbers: any;
  correctedTransactionNumbers: any;

  reasonsForAdjustmentOptions: any;
  hideSummary: boolean = true;
  errorMsg: string = '';

  subActivatedRoute: any;
  currentWindow: any;
  
  isSlipUploaded = false;
  slipUploaded = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private winRef: WindowRef,
    private dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService
  ) {
    this.currentWindow = winRef.nativeWindow;
  }

  ngOnInit() {
    this.dataCaptureFormsService.getReasonsForAdjustment(this.request.adjTypeID)
      .subscribe(
        (data) => {
          this.reasonsForAdjustmentOptions = data;
        }
      );
    //Getting the details for edit in case required
    this.subActivatedRoute = this.activatedRoute.params.subscribe(params => {
      if (+params['id']) {
        this.utilsService.showLoadingIcon(true);
        this.request.id = +params['id'];
        this.dataCaptureFormsService.getRequestDetails(+params['id'], AdjustmentType.BusinessUnitAdjustment)
          .subscribe((result) => {
            this.request.setValuesFromAPIObj(result);
            //this.utilsService.showLoadingIcon(false);
          },
          (error) => {
            this.utilsService.showMessageTip(error["error"]);
            this.utilsService.showLoadingIcon(false);
          });
      }
    }); 
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }

  toggleFormSummaryDisplay(event: Event, form: NgForm): void {

    if(this.formValidations(form)) {

      this.errorMsg = null;
      this.hideSummary = !this.hideSummary;
      this.currentWindow.scrollTo(0, 0);
    }
  }

  slipFileUploadChange(files: FileList) {

    if(this.isSlipUploaded) {
      this.slipUploaded = true;
    }
    if(files.length) {
    this.request.slipFile = files[0];
    this.request.slipFileName = files[0].name;
    this.request.slipExistingFileName = null;
    this.isSlipUploaded = true;
    }
  };
  chooseFile(event) {
    // this.utilsService.isSessionExists().then((isSessionExists)=>{
        if(!this.utilsService.isSessionExists()) {
            event.preventDefault();
            this.utilsService.showTimedOutMessage();
            event.stopPropagation();
        }else {
    let ele: HTMLElement = document.getElementById('slipfileUpload') as HTMLElement;
    ele.click();
    event.preventDefault();
    event.stopPropagation();
    return false;
    }
  // });
  return false;
}

  slipFileRemove(e: Event, slipFileUploadField) {

    slipFileUploadField.value = '';
    this.request.slipFile = null;
    this.request.slipFileName = null;
  }

  saveConfirmRequest(e: Event, form: NgForm, actionType: string): void {    
    
    let isRequestIDExists = this.request.id ? true : false;
    let isFileExists = false;
    if(!this.formValidations(form)){
      
      this.hideSummary = true;
      return;
    }

    let sendTransitionOne = false;//Used to determine if workflow with transition 1 should be called or not
    let userEmail = localStorage.getItem('userEmail');

    if (!this.request.id) {
      sendTransitionOne = true;
    } else if (this.request.status == AdjustmentStatus.Rejected) {
      sendTransitionOne = true;
    }

    this.request.userRoles = this.utilsService.setTierItems.getValue();
    let expectedSaveObj = this.request.getSaveObject();
    let formData;
    if (this.request.slipFileName) {
      isFileExists = true;
      formData = new FormData();
      formData.append('file', this.request.slipFile, this.request.slipFileName);
      formData.append('data', JSON.stringify(expectedSaveObj));
    } else {
      isFileExists = false;
      formData = expectedSaveObj;
    }

    this.utilsService.showLoadingIcon(true);
    this.dataCaptureFormsService.saveRequest(formData, AdjustmentType.BusinessUnitAdjustment, isFileExists)
      .subscribe(data => {
        if(isRequestIDExists) {
          this.dataCaptureFormsService.workflowSaveConfirmUpdate(actionType, sendTransitionOne, userEmail, this.request);
        } else {
          this.request.id = data;
        }

        this.utilsService.showMessageTip("Request saved successfully");
        this.router.navigate(['adjustments']);

      },
        error => {
          this.utilsService.showMessageTip(error['error']['error']);
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  autoCompleteTransactionNumbers(): void {
    this.isTransactionNumbersExists = true;
    if (this.request.transactionNumber.length < 3) {
      return;
    }
    this.transactionNumbers = this.dataCaptureFormsService
      .getTransactionNumbers(this.request.transactionNumber, '')
      .pipe(
        map(transactionNumbers => this.filterTransactionNumbers(transactionNumbers, 'default'))
      );
  }

  //@ToDo: Once API for this is in place, check if existing function autoCompleteTransactionNumbers can be used with some arguments in place of this
  autoCompleteReversalTransactionNumbers(): void {
    if (this.request.reversalTransactionNumber.length < 3) {
      return;
    }
    this.reversalTransactionNumbers = this.dataCaptureFormsService
      .getTransactionNumbers(this.request.reversalTransactionNumber, 'reversal')
      .pipe(
        map(transactionNumbers => this.filterTransactionNumbers(transactionNumbers, 'reversal'))
      );
  }

  //@ToDo: Once API for this is in place, check if existing function autoCompleteTransactionNumbers can be used with some arguments in place of this
  autoCompleteCorrectedTransactionNumbers(): void {
    if (this.request.correctedTransactionNumber.length < 3) {
      return;
    }
    this.correctedTransactionNumbers = this.dataCaptureFormsService
      .getTransactionNumbers(this.request.correctedTransactionNumber, 'corrected')
      .pipe(
        map(transactionNumbers => this.filterTransactionNumbers(transactionNumbers, 'corrected'))
      );
  }

  filterTransactionNumbers(values, transactionType) {
    if(values.length) {
      let transactionNumer = this.request.transactionNumber;
      switch(transactionType){
        case 'reversal':
          transactionNumer = this.request.reversalTransactionNumber;
          this.isReversalTransactionNumberExists = true;
        break;
        case 'corrected':
          transactionNumer = this.request.correctedTransactionNumber;
          this.isCorrectedTransactionNumberExists = true;
        break;
        default:
          transactionNumer = this.request.transactionNumber;
          this.isTransactionNumbersExists = true;
      }
      return values.filter(trNo =>
        trNo.transactionNumber.toLowerCase().includes(transactionNumer.toLowerCase())
      )
    } else {
      switch(transactionType) {
        case 'reversal':
          this.isReversalTransactionNumberExists = false;
        break;
        case 'corrected':
          this.isCorrectedTransactionNumberExists = false;
        break;
        default:
          this.isTransactionNumbersExists = false;
      }
    }  
  }

  transactionNumberSelected(e: Event): void {

    this.dataCaptureFormsService
      .getTransactionNumberDetails(this.request.transactionNumber, 'BusinessUnitAdjustment')
      .subscribe(
        data => {
          this.request.updateFieldsFromTransactionNumberDetails(data, 'default');
        }
      );
    //this.request.businessUnitAdjLedgereID = this.getSelectedLedgerId(this.transactionNumbers, this.request.transactionNumber);
  }

  correctedTransactionNumberSelected(e: Event): void {

    this.dataCaptureFormsService
      .getCorrectedTransactionNumberDetails(this.request.correctedTransactionNumber)
      .subscribe(
        data => {
          this.request.updateFieldsFromTransactionNumberDetails(data, 'corrected');
        }
      );
  }

  reversalTransactionNumberSelected(e: Event): void {

    this.dataCaptureFormsService
      .getReversalTransactionNumberDetails(this.request.reversalTransactionNumber)
      .subscribe(
        data => {
          this.request.updateFieldsFromTransactionNumberDetails(data, 'reversal');
        }
      );
  }

  getSelectedLedgerId(trNosObjArr: string[], selectedTrNo): number{
    console.log(trNosObjArr);
    let selectedTransactionNoObj = trNosObjArr.filter((trNoObj) => {
      return trNoObj['transactionNumber'] == selectedTrNo;
    });
    console.log(selectedTransactionNoObj);
    return selectedTransactionNoObj[0]['ledgerID'];
  }

  reasonForAdjustmentChange(e: Event): void {

    let selectedOptionArr = this.reasonsForAdjustmentOptions.filter(
      currentVal => {
        return currentVal['reasonID'] == this.request.reasonForAdjustmentId;
      }
    )
    this.request.reasonForAdjustment = selectedOptionArr[0]['name'];
  }

  formValidations(form: NgForm): boolean{

    this.errorMsg = '';
    let errorMsgArr = [];

    if (!form.valid) {

      errorMsgArr.push('<br />Provide correct values for all mandatory fields.');
    } 
    
    if ((this.request.transactionNumber == this.request.reversalTransactionNumber) || (this.request.transactionNumber == this.request.correctedTransactionNumber) || (this.request.reversalTransactionNumber == this.request.correctedTransactionNumber)) {

      errorMsgArr.push('<br />Provide different values for all 3 transaction numbers.');
    } 
    
    if (!this.request.slipFileName && !this.request.slipExistingFileName) {

      errorMsgArr.push('<br />Upload a slip.');
    }

    if(errorMsgArr.length>0){

      this.errorMsg = 'Please resolve following errors:<br />'+ errorMsgArr.join('');
      return false;
    }
    return true;
  }

  onDownloadBusinessAdjustmentCurrentFile() {
    this.dataCaptureFormsService.downloadAttachment('BusinessUnitAdjustment', this.request.id);
  }
}
