<div class="docs-primary-header component-page-header">
  <button mat-button class="sidenav-toggle">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="row-display">
    <h1 class="width40P">{{ request.type }} Data Capture</h1>
    <div class="width40P request-date request-date-placement">Request Date: {{ request.requestDate | date:'d/MM/yyyy' }} &nbsp;|&nbsp; Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy'}}</div>
  </div>
</div>
<div class="col-display content-main-container">
  <div class="form-container" [hidden]="!hideSummary">
    <form #accrualForm="ngForm">

      <div class="col-display">

        <mat-form-field class="width50P field-with-hint">
          <input matInput placeholder="Policy Reference" [matAutocomplete]="policyReferenceAutoComplete" #policyReference="ngModel"
            [(ngModel)]="request.policyReference" name="policyReference" (ngModelChange)="autoCompletePolicyReference()" (blur)="policyReferenceBlur()" userSessionExists>
          <mat-hint *ngIf="isPolicyReferenceOptionsExists">Please type any 3 letters to search policy reference</mat-hint>
          <mat-hint *ngIf="!isPolicyReferenceOptionsExists">No active policies found</mat-hint>
        </mat-form-field>

        <mat-autocomplete #policyReferenceAutoComplete="matAutocomplete">
          <mat-option *ngFor="let policyRefNo of policyReferenceOptions | async" [value]="policyRefNo.riskReference">
            {{policyRefNo.riskReference}}
          </mat-option>
        </mat-autocomplete>

        <ng-container *ngIf="displayInsuredNameAsAutoComplete">

          <mat-form-field class="width80P field-with-hint">
            <input required matInput placeholder="Insured" [matAutocomplete]="insuredAutoComplete" #insured="ngModel" [(ngModel)]="request.insuredName"
              name="insuredName" (ngModelChange)="autoCompleteInsureds()" (blur) = "insuredAutoCompleteBlur()" userSessionExists>
            <mat-hint *ngIf="isInsuredOptionsExists">Please type any 3 letters to search insured</mat-hint>
            <mat-hint *ngIf="!isInsuredOptionsExists">No Data found</mat-hint>
            <mat-error>Please provide Insured</mat-error>
          </mat-form-field>

          <mat-autocomplete #insuredAutoComplete="matAutocomplete" (optionSelected)="insuredSelected($event)">
            <mat-option *ngFor="let insured of insuredOptions | async" [value]="insured.name">
              {{insured.name}}
            </mat-option>
          </mat-autocomplete>

        </ng-container>

        <ng-container *ngIf="!displayInsuredNameAsAutoComplete">

          <mat-form-field class="width80P">
            <mat-select required placeholder="Insured" [(ngModel)]="request.insuredName" name="insuredName" (selectionChange)="insuredDropDownSelected($event)" userSessionExists>
              <mat-option *ngFor="let insured of insuredOptions; let in=index" [value]="insured.name">
                {{ insured.name }}
              </mat-option>
            </mat-select>
            <mat-error>Please provide insured</mat-error>
          </mat-form-field>

        </ng-container>

        <mat-form-field class="width25P">
          <input matInput disabled placeholder="Insured Code" [(ngModel)]="request.insuredCode" name="insuredCode" userSessionExists>
        </mat-form-field>


        <mat-form-field class="width25P">
          <input matInput required [matDatepicker]="pickerEffectiveDate" placeholder="Effective Date" [(ngModel)]="request.effectiveDate"
            name="effectiveDate" userSessionExists>
          <mat-datepicker-toggle matSuffix [for]="pickerEffectiveDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEffectiveDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width50P">
          <mat-select required placeholder="New or Renewal" [(ngModel)]="request.renewalTypeID" name="renewalTypeID" (selectionChange)="renewalTypeChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of renewalTypeOptions; let in=index" [value]="currentVal.renewalTypeID">
              {{ currentVal.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a type</mat-error>
        </mat-form-field>

        <div class="row-display">

          <ng-container *ngIf="displayBrokerageAccountAsAutoComplete">

            <mat-form-field class="width80P field-with-hint">
              <input required matInput placeholder="Brokerage Account" [matAutocomplete]="brokerageAccountAutoComplete" #brokerageAccount="ngModel"
                [(ngModel)]="request.brokerageDetails[0]['name']" (blur) = "brokerageAccountBlur($event)" name="brokerageAccountName" (ngModelChange)="autoCompleteBrokerageAccount()" userSessionExists>
              <mat-hint *ngIf="isBrokerageAccountOptionsExist">Please type any 3 letters to search brokerage Account</mat-hint>
              <mat-hint *ngIf="!isBrokerageAccountOptionsExist">No Data found</mat-hint>
              <mat-error>Please provide brokerage account</mat-error>
            </mat-form-field>

            <mat-autocomplete #brokerageAccountAutoComplete="matAutocomplete" (optionSelected)="brokerageAccountSelected($event.option)">
              <mat-option *ngFor="let brokerageAccount of brokerageAccountOptions | async" [value]="brokerageAccount.name" [id]="brokerageAccount.dataSource">
                {{brokerageAccount.name + ' (' + brokerageAccount.dataSource + ')'}}
              </mat-option>
            </mat-autocomplete>
          </ng-container>

          <ng-container *ngIf="!displayBrokerageAccountAsAutoComplete">
            <mat-form-field class="width80P">
              <input matInput disabled placeholder="Brokerage Account" [(ngModel)]="request.brokerageDetails[0]['name']" name="brokerageAccount" userSessionExists>
            </mat-form-field>
          </ng-container>


        </div>

        <mat-form-field class="width25P">
          <input matInput disabled placeholder="Account Code" [(ngModel)]="request.brokerageDetails[0]['accountCode']" name="accountCode">
        </mat-form-field>

        <div class="row-display">
          <mat-form-field class="width25P">
            <input matInput disabled placeholder="Business Unit" [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup2']" name="businessUnitGroup2">
          </mat-form-field>

          <mat-form-field class="width25P">
            <input matInput disabled [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup3']" name="businessUnitGroup3">
          </mat-form-field>

          <mat-form-field class="width25P">
            <input matInput disabled [(ngModel)]="request.brokerageDetails[0]['businessUnitGroup4']" name="businessUnitGroup4">
          </mat-form-field>
        </div>

        <mat-form-field class="width50P">
          <mat-select required placeholder="Income Type" [(ngModel)]="request.incomeTypeId" name="incomeTypeId" (selectionChange)="incomeTypeChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of incomeTypeOptions; let in=index" [value]="currentVal.incomeTypeId">
              {{ currentVal.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a Income type</mat-error>
        </mat-form-field>

        <div class="row-display income-type-row">

          <mat-form-field class="width25P">
            <mat-select required placeholder="Currency" [(ngModel)]="request.currencyTypeId" name="currencyTypeId" (selectionChange)="currencyTypeChange($event)" userSessionExists>
              <mat-option *ngFor="let currentVal of currencyTypeOptions; let in=index" [value]="currentVal.currencyID">
                {{ currentVal.code }}
              </mat-option>
            </mat-select>
            <mat-error>Please select Currency</mat-error>
          </mat-form-field>

          <mat-form-field class="width25P">
            <input matInput required placeholder="Amount" [ngModel]="request.amount | amountPipe" (ngModelChange)="request.amount=$event" [ngModelOptions]="{updateOn:'blur'}"
              name="amount" (keypress)="utilsService.validateNumberOnly($event)" (keyup)="validateAmountField($event.target.value)" userSessionExists>
          </mat-form-field>

          <mat-form-field class="width25P">
            <input matInput [min]="activePeriodID" required [matDatepicker]="incomeBrokerageEarningDate" placeholder="Brokerage Earning Date" [(ngModel)]="request.brokerageEarningDate"
              name="brokerageEarningDate" userSessionExists>
            <mat-datepicker-toggle matSuffix [for]="incomeBrokerageEarningDate"></mat-datepicker-toggle>
            <mat-datepicker #incomeBrokerageEarningDate></mat-datepicker>
            <mat-error>Please select a date in active period.</mat-error>
          </mat-form-field>

        </div>
        <div class="row-display error-msg" [hidden]='!brokerageAmountErrMsg'>
          <div class="brokerage-amount-error-msg-businessTransfer" [hidden]='!brokerageAmountErrMsg' [innerHTML]="brokerageAmountErrMsg"></div>
        </div>

        <mat-form-field class="width50P">
          <mat-select required placeholder="Reason for Accrual" [(ngModel)]="request.reasonForAccrualId" name="reasonForAccrual" (selectionChange)="reasonsForAccrualChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of reasonForAccrualOptions; let in=index" [value]="currentVal.reasonID">
              {{ currentVal.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a reason</mat-error>
        </mat-form-field>


        <mat-form-field class="width50P" *ngIf="request.reasonForAccrual=='Other'">
          <textarea required matInput placeholder="Enter details in case of other" [(ngModel)]="request.otherReason" name="otherReason" userSessionExists></textarea>
          <mat-error>
            Please provide details</mat-error>
        </mat-form-field>

        <h2>Supporting Documentation</h2>

        <div class="row-display accrual-files-rows">
          <div class="width70P">
            Upload Slip&nbsp;*: &nbsp;&nbsp;
            <button mat-raised-button (click)="chooseSlipFile($event)" class="fileuploadButton" >Choose</button>
            <input type="text" (click)="chooseSlipFile($event)" [value]="request.slipFileName ? request.slipFileName : ''"/>
            <input type='file' id="slipfileUpload" class="displaynone" (change)="slipFileUploadChange($event.target.files)" #slipFileUploadField userSessionExists>
            <mat-icon class="adc-remove-icon" (click)="clearFilePath(slipFileUploadField, false)">delete</mat-icon>
          </div>
        </div>
        <div class="data-capture-info-text" *ngIf="slipUploaded">*Uploading new file will replace existing file. If file exists</div>

        <div class="row-display existing-file-row" *ngIf="request.slipExistingFileName">
          <div class="width70P">
            Current File: &nbsp;&nbsp;
            <a href="javascript:void(0);" (click)="onDownloadAccrualCurrentFile(false)">{{ request.slipExistingFileName }}</a>
          </div>
        </div>

        <div class="row-display accrual-files-rows">
          <div class="width70P">
            Upload Confirmation of Order&nbsp;*: &nbsp;&nbsp;
            <button mat-raised-button (click)="chooseOrderFile($event)" class="fileuploadButton" >Choose</button>
            <input type="text" (click)="chooseOrderFile($event)" [value]="request.confirmationOfOrderFileName ? request.confirmationOfOrderFileName : ''"/>
            <input type='file' id="orderfileUpload" class="displaynone" (change)="confirmationOfOrderFileUploadChange($event.target.files)" #confirmationFile >
            <mat-icon class="adc-remove-icon" (click)="clearFilePath(confirmationFile, true)">delete</mat-icon>
          </div>
        </div>

        <div class="data-capture-info-text" *ngIf="OrderConfirmationUploaded">*Uploading new file will replace existing file. If file exists</div>

        <div class="row-display existing-file-row" *ngIf="request.confirmationOfOrderExistingFileName">
          <div class="width70P">
            Current File: &nbsp;&nbsp;
            <a href="javascript:void(0);" (click)="onDownloadAccrualCurrentFile(true)">{{ request.confirmationOfOrderExistingFileName }}</a>
          </div>
        </div>

        <mat-checkbox labelPosition="before" required class="work-done-checkbox" [(ngModel)]="request.riskBoundCheck" #workDoneCheck name="workDoneCheck" userSessionExists>Has the work been substantially completed and the risk confirmed as bound by the end of the month requested?&nbsp;*</mat-checkbox>

        <div *ngIf="errorMsg" class="row-display error-msg error-msg-accrual" [hidden]='!errorMsg' [innerHTML]="errorMsg">
        </div>

        <div class="row-display btn-row">
          <!-- <button mat-raised-button (click)="saveConfirmRequest($event, accrualForm, 'save', workDoneCheck)">Save</button>
          &nbsp;&nbsp;&nbsp;&nbsp; -->
          <button mat-raised-button (click)="toggleFormSummaryDisplay($event, accrualForm, workDoneCheck)">Next</button>

        </div>

      </div>
    </form>
  </div>
  <div class="summary-container" [hidden]="hideSummary">
    <div class="col-display">

      <mat-card>
        <mat-card-title>
          Summary
        </mat-card-title>
        <mat-card-content>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Policy Reference: </div>
            <div class="width20P summary-value">{{ request.policyReference }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Reason for Accrual: </div>
            <div class="width20P summary-value">{{ request.reasonForAccrual }}</div>
            <div class="width20P summary-value" *ngIf="request.reasonForAccrual=='Other'">{{ request.otherReason }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Renewal Type: </div>
            <div class="width20P summary-value">{{ request.renewalType }}
            </div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Insured: </div>
            <div class="width50P summary-value">{{ request.insuredName }}
            </div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Insured Code: </div>
            <div class="width20P summary-value">{{ request.insuredCode }}
            </div>
          </div>


          <div class="row-display summary-row">
            <div class="width20P summary-label">Effective Date: </div>
            <div class="width20P summary-value">{{ request.effectiveDate | date:'d/MM/yyyy' }}</div>
          </div>

          <h2>Brokerage Account</h2>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Brokerage Account: </div>
            <div class="width50P summary-value">{{ request.brokerageDetails[0]['name'] }}
            </div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Account Code: </div>
            <div class="width20P summary-value">{{ request.brokerageDetails[0]['accountCode'] }}
            </div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Business Unit: </div>
            <div class="width70P summary-value">{{ request.brokerageDetails[0]['businessUnitGroup2'] }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup3']
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup4'] }}
            </div>
          </div>

          <h2>Income Details</h2>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Income Type: </div>
            <div class="width20P summary-value">{{ request.incomeType }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Income Amount: </div>
            <div class="width20P summary-value">{{ request.currencyTypeCode }}&nbsp;&nbsp;{{ request.amount | amountPipe }}</div>
          </div>

          <div class="row-display summary-row">
            <div class="width20P summary-label">Earning Date: </div>
            <div class="width20P summary-value">{{ request.brokerageEarningDate | date:'d/MM/yyyy' }}</div>
          </div>

          <h2>Supporting Documentation</h2>

          <div class="row-display summary-row" *ngIf="request.slipFileName">
            <div class="width20P summary-label">Slip: </div>
            <div class="width20P summary-value">{{ request.slipFileName }}</div>
          </div>

          <div class="row-display summary-row" *ngIf="request.slipExistingFileName">
            <div class="width20P summary-label">Current Slip: </div>
            <div class="width60P summary-value">
              <a href="javascript:void(0);" (click)="onDownloadAccrualCurrentFile(false)">{{ request.slipExistingFileName }}</a>
            </div>
          </div>

          <div class="row-display summary-row" *ngIf="request.confirmationOfOrderFileName">
            <div class="width20P summary-label">Confirmation of Order: </div>
            <div class="width20P summary-value">{{ request.confirmationOfOrderFileName }}</div>
          </div>

          <div class="row-display summary-row" *ngIf="request.confirmationOfOrderExistingFileName">
            <div class="width20P summary-label">Current Confirmation of Order: </div>
            <div class="width60P summary-value">
              <a href="javascript:void(0);" (click)="onDownloadAccrualCurrentFile(true)">{{ request.confirmationOfOrderExistingFileName }}</a>
            </div>
          </div>


          <div class="row-display btn-row summary-btn-row">
            <button mat-raised-button (click)="toggleFormSummaryDisplay($event, accrualForm, workDoneCheck, true)" >Back</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button mat-raised-button (click)="saveConfirmRequest($event, accrualForm, 'confirm', workDoneCheck)" >Confirm</button>
          </div>

        </mat-card-content>
      </mat-card>

    </div>
  </div>
</div>
