import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import {MatButtonModule} from '@angular/material/button';
 import {MatCardModule} from '@angular/material/card';
 import {MatIconModule} from '@angular/material/icon';

import { PageNotFoundComponent } from './page-not-found.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule
  ],
  declarations: [PageNotFoundComponent]
})
export class PageNotFoundModule { }
