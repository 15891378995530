import { AdjustmentType, AdjustmentStatus } from '../../shared/models/adjustment.model';

export default class AccrualModel{

  type: string = AdjustmentType.Accrual;

  id: number;

  journalType: number = 1;
  description: string ="";
  userRoles: string[];
  workFlowType: number;

  requestDate: Date = new Date();
  
  policyReferenceId: number;
  policyReference: string;

  adjAccrualDetailID: number = 0;
  policyFYBusinessUnitID: number = 0;
  adjTypeID: number = 1; //Represents Accrual
  businessUnitAdjLedgerID: number = 0;
  
  insuredId: number;
  insuredName: string;
  insuredCode: string;
  
  effectiveDate: Date;
  
  renewalTypeID: number;
  renewalType: string;

  actionType: string;//Used to check whether current request is for Re-Accrual
  reAccrualAllowed: boolean;
  reAccrualRequestId:number;
  brokerageDetails: object[] = [{
    id: <number>null,
    accountCode: <string>null,
    name: <string>null,
    brokerageAmount: <any>null,
    brokerageRecogDate: <Date>null,
    currencyID: <number>null,
    currencyCode: <string>null,
    businessUnitGroup2: <string>null,
    businessUnitGroup3: <string>null,
    businessUnitGroup4: <string>null
  }];

  incomeTypeId: number;
  incomeType: string;

  currencyTypeId: number;
  currencyTypeCode: string;

  amount: any;

  brokerageEarningDate: Date;

  reasonForAccrualId: number;
  reasonForAccrual: string;
  otherReason: string;

  riskBoundCheck: boolean = false;

  slipFile: File;
  slipFileName: string;
  slipExistingFileName: string;//This is to hold already uploaded file

  confirmationOfOrderFile: File;
  confirmationOfOrderFileName: string;
  confirmationOfOrderExistingFileName: string;//This is to hold already uploaded file

  statusId: number = 1;
  status: string = AdjustmentStatus.Incomplete;
  
  //@ToDo: move comments out and use updated variables like submittedComments etc.. as per status
  comments: string;
  //@ToDo: move processedBy out and use updated variables like authorizedBy etc..
  processedBy: string;
  requestedBy: string;
  requestedById: number;
  submittedBy: string;
  submittedById: number;
  submittedComments: string;
  authorizedBy: string;
  authorizedById: number;
  authorizedComments: string;
  approvedBy: string;
  approvedById: number;
  approvedComments: string;
  rejectedBy: string;
  rejectedById: number;
  rejectedComments: string;

  constructor(){  }

  setValuesFromAPIObj(requestObj: object): void {

    if(requestObj) {

      this.id = (this.actionType && this.actionType == 'reAccrual') ? null : requestObj['requestId'];
      this.reAccrualRequestId= (this.actionType && this.actionType == 'reAccrual') ?  requestObj['requestId']:null;
      this.policyReferenceId = (requestObj['policyID']) ? requestObj['policyID'] : null;
      this.policyReference = (requestObj['riskReference']) ? requestObj['riskReference'] : null;

      this.adjTypeID = requestObj['adjTypeID'];
      this.adjAccrualDetailID = requestObj['adjAccrualDetailID'];
      this.businessUnitAdjLedgerID = requestObj['businessUnitAdjLedgerID'];
      this.policyFYBusinessUnitID = requestObj['policyFYBusinessUnitID'];

      this.insuredId = requestObj['insured']['insuredId'];
      this.insuredName = requestObj['insured']['name'];
      this.insuredCode = requestObj['insured']['code'];

      this.effectiveDate = requestObj['effectiveDate'];

      this.renewalTypeID = requestObj['renewalType']['renewalTypeID'];
      this.renewalType = requestObj['renewalType']['name'];

      this.reAccrualAllowed = (requestObj.hasOwnProperty('reAccrualAllowed')) ? requestObj['reAccrualAllowed'] : false;

      this.brokerageDetails = requestObj['brokerageDetails'];

      this.incomeTypeId = requestObj['incomeTypeId'];
      this.incomeType = requestObj['incomeName'];

      this.currencyTypeId = requestObj['accrualRequest']['currency']['currencyID'];
      this.currencyTypeCode = requestObj['accrualRequest']['currency']['code'];
      this.amount = requestObj['accrualRequest']['brokerageAmount'];
      // this.brokerageEarningDate = requestObj['accrualRequest']['brokerageDate'];
      this.brokerageEarningDate = this.getBrokerageEarningDateReAccrual(requestObj);
      
      this.reasonForAccrualId = requestObj['reasonId'];
      this.reasonForAccrual = requestObj['reason'];
      this.otherReason = requestObj['otherReason'];

      this.statusId = requestObj['statusId'];
      this.status = requestObj['status'];

      this.slipExistingFileName = requestObj['slipFileName'];
      this.confirmationOfOrderExistingFileName = requestObj['confirmationOfOrderFileName'];

      if(requestObj["description"]) this.description = requestObj["description"];
      if(requestObj["journalType"]) this.journalType = requestObj["journalType"];
      if(requestObj["workFlowType"]) this.workFlowType = requestObj["workFlowType"];

      this.riskBoundCheck = (requestObj.hasOwnProperty('riskBoundCheck')) ? requestObj['riskBoundCheck'] : false;

      this.requestedById = (requestObj['requestedBy'] && requestObj['requestedBy']['id']) ? requestObj['requestedBy']['id'] : null;
      this.requestedBy = (requestObj['requestedBy'] && requestObj['requestedBy']['name']) ? requestObj['requestedBy']['name'] : null;

      this.submittedById = (requestObj['submittedBy'] && requestObj['submittedBy']['id']) ? requestObj['submittedBy']['id'] : null;
      this.submittedBy = (requestObj['submittedBy'] && requestObj['submittedBy']['name']) ? requestObj['submittedBy']['name'] : null;
      this.submittedComments = (requestObj['submittedBy'] && requestObj['submittedBy']['comments']) ? requestObj['submittedBy']['comments'] : null;

      this.authorizedById = (requestObj['authorizedBy'] && requestObj['authorizedBy']['id']) ? requestObj['authorizedBy']['id'] : null;
      this.authorizedBy = (requestObj['authorizedBy'] && requestObj['authorizedBy']['name']) ? requestObj['authorizedBy']['name'] : null;
      this.authorizedComments = (requestObj['authorizedBy'] && requestObj['authorizedBy']['comments']) ? requestObj['authorizedBy']['comments'] : null;

      this.approvedById = (requestObj['approvedBy'] && requestObj['approvedBy']['id']) ? requestObj['approvedBy']['id'] : null;
      this.approvedBy = (requestObj['approvedBy'] && requestObj['approvedBy']['name']) ? requestObj['approvedBy']['name'] : null;
      this.approvedComments = (requestObj['approvedBy'] && requestObj['approvedBy']['comments']) ? requestObj['approvedBy']['comments'] : null;

      this.rejectedById = (requestObj['rejectedBy'] && requestObj['rejectedBy']['id']) ? requestObj['rejectedBy']['id'] : null;
      this.rejectedBy = (requestObj['rejectedBy'] && requestObj['rejectedBy']['name']) ? requestObj['rejectedBy']['name'] : null;
      this.rejectedComments = (requestObj['rejectedBy'] && requestObj['rejectedBy']['comments']) ? requestObj['rejectedBy']['comments'] : null;
    }  
  }

  updateFieldsFromPolicyReferenceDetails(unsetValues: boolean, data: object, compObj: object): void{

    if(unsetValues){

      this.policyReferenceId = null;
      this.policyFYBusinessUnitID = 0;
      this.reAccrualAllowed=false;
      compObj['insuredOptions'] = null;
      this.insuredId = null;
      this.insuredName = null;
      this.insuredCode = null;
      
      compObj['displayInsuredNameAsAutoComplete'] = true;
      
      this.brokerageDetails = [{}];
      compObj['displayBrokerageAccountAsAutoComplete'] = true;
      
    }else{
      
      this.policyReferenceId = (data['policyID']) ? data['policyID'] : null;
      this.policyReference = (data['riskReference']) ? data['riskReference'] : null;
      this.policyFYBusinessUnitID = (data['policyFYBusinessUnitID']) ? data['policyFYBusinessUnitID'] : null;

      if (data['insureds'] && Array.isArray(data['insureds']) && data['insureds'].length > 0) {
        compObj['insuredOptions'] = data['insureds'];
        compObj['displayInsuredNameAsAutoComplete'] = false;
        if (compObj['insuredOptions'].length==1){
          this.insuredId = compObj['insuredOptions'][0]['insuredId'];
          this.insuredName = compObj['insuredOptions'][0]['name'];
          this.insuredCode = compObj['insuredOptions'][0]['code'];
        }
      } else {
        compObj['insuredOptions'] = null;
        compObj['displayInsuredNameAsAutoComplete'] = true;
      }

      if (data['brokerageAccount']) {
        this.brokerageDetails = [data['brokerageAccount']];
        compObj['displayBrokerageAccountAsAutoComplete'] = false;
      } else {
        this.brokerageDetails = [{}];
        compObj['displayBrokerageAccountAsAutoComplete'] = true;
      }
    }    
  }

  getSaveObj():object{

    let effectiveDateObj;
    let effectiveDateateTimeMs;
    let effectiveDateTimeZoneDate;

    let brokerageEarningDateObj;
    let brokerageEarningDateateTimeMs;
    let brokerageEarningDateTimeZoneDate;
    let userRoleStatus: string;

    effectiveDateObj = new Date(this.effectiveDate);
    effectiveDateateTimeMs = effectiveDateObj.getTime() - (effectiveDateObj.getTimezoneOffset() * 60 * 1000);
    effectiveDateTimeZoneDate = new Date(effectiveDateateTimeMs);

    brokerageEarningDateObj = new Date(this.brokerageEarningDate);
    brokerageEarningDateateTimeMs = brokerageEarningDateObj.getTime() - (brokerageEarningDateObj.getTimezoneOffset() * 60 * 1000);
    brokerageEarningDateTimeZoneDate = new Date(brokerageEarningDateateTimeMs);

    if(this.userRoles.length > 0 && this.userRoles.indexOf('Tier 4') !== -1){
      userRoleStatus = "RequestedToAuthorised";
    } else if( this.userRoles.length > 0 && this.userRoles.indexOf('Tier 2') !== -1) {
      userRoleStatus = "IncompleteToSumitted";
    }

    return {
      policyID: (this.policyReferenceId) ? this.policyReferenceId : null,
      adjAccrualDetailID: (this.actionType && this.actionType == 'reAccrual') ? null : this.adjAccrualDetailID,
      insuredID: (this.insuredId) ? this.insuredId: null,
      insuredName: this.insuredName,
      effectiveDate: effectiveDateTimeZoneDate,
      renewalTypeID: this.renewalTypeID,
      brokerageAccountID: this.brokerageDetails[0]['id'],
      incomeAmount: this.amount,
      brokerageEarningDate: brokerageEarningDateTimeZoneDate,
      policyFYBusinessUnitID: this.policyFYBusinessUnitID,
      requestId: this.id,
      adjTypeID: this.adjTypeID,
      reasonID: this.reasonForAccrualId,
      incomeTypeID: this.incomeTypeId,
      currencyID: this.currencyTypeId,
      riskBoundCheck: this.riskBoundCheck,
      userEmail: localStorage.getItem('userEmail'),
      otherReason: this.otherReason,
      journalType: this.journalType,
      businessUnitGroup3: this.brokerageDetails[0]["businessUnitGroup3"],
      transition: userRoleStatus,
      reasonForAccrual: this.reasonForAccrual,
      brokerageAccountName: this.brokerageDetails[0]["name"],
      policyReference: this.policyReference,
      currencyTypeCode: this.currencyTypeCode,
      reAccrualAllowed:(this.actionType && this.actionType == 'reAccrual') ? true:false,
      reAccrualRequestId:this.reAccrualRequestId,
      workFlowType: this.workFlowType
    };
  }

  getBrokerageEarningDateReAccrual(requestObj: object){

    
    let currentBrokerageEarningDate = requestObj['accrualRequest']['brokerageDate'];

    if(this.actionType!='reAccrual'){
      return currentBrokerageEarningDate;
    }
    
    let currentDateObj = new Date(currentBrokerageEarningDate);
    
    let 
      fullYear = currentDateObj.getFullYear(), 
      month = currentDateObj.getMonth(), 
      date = currentDateObj.getDate(),
      hours = currentDateObj.getHours(),
      minutes = currentDateObj.getMinutes(),
      seconds = currentDateObj.getSeconds();

    let 
      newDate,
      newMonth,
      newFullYear;
    
    newMonth = (month==11) ? 0 : month+1;
    newFullYear = (newMonth==0) ? fullYear+1 : fullYear;
    newDate = date;

    if (newMonth == 3 || newMonth == 5 || newMonth == 8 || newMonth == 10){//Case when maximum day can be 30 for a month

      if (date==31){
        newDate = 30;
      }
    }else if(newMonth == 1){//Feb case

      if(newFullYear%4==0){//Leap year case with Feb
        if (date == 30 || date==31){
          newDate = 29;
        }
      }else{
        if (date == 29 || date == 30 || date==31){
          newDate = 28;
        }
      }
    }
    
    return new Date(newFullYear, newMonth, newDate, hours, minutes, seconds);
  }
}
