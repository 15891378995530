import { Component, OnInit } from "@angular/core";
import BusinessUnitAdjustmentTransferModel from "./business-unit-adjustment-transfer.model";
import { DataCaptureFormsService } from "./data-capture-forms.service";
import { map } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { UtilsService } from "src/app/shared/services/utils.service";
import { WindowRef } from "src/app/shared/services/window-ref.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AdjustmentType, AdjustmentStatus } from "src/app/shared/models/adjustment.model";

@Component({
    selector: 'business-unit-adjustment-transfer',
    templateUrl: './business-unit-adjustment-transfer.component.html',
    styleUrls: ['./data-capture-forms.scss']
})

export class BusinessUnitAdjustmentTransferComponent implements OnInit {
    request: BusinessUnitAdjustmentTransferModel = new BusinessUnitAdjustmentTransferModel();
    
    hideSummary: boolean = true;

    policyReference: any;
    isPolicyReferenceExists:boolean = true;

    reasonForTransferOptions: any;
    incomeTypeOptions: any;
    currencyTypeOptions: any;

    isCostCenterOptionsExists: boolean = true;
    costCenterOptions: any;

    isBrokerageAccountOptionsExist: boolean = true;
    brokerageAccountOptions: any;

    activePeriodID: Date;
    userDetails: Object;

    errorMsg: string;
    currentWindow: any;

    baseOptions: any;
    isBaseOptionsExists: boolean = true;
    subActivatedRoute: any;

    costCentreCompanyCodes: object[] = [];

    brokerageAmountErrMsg: string;

    errMsg: string = '';
    accountCode: string;

    constructor(private dataCaptureFormsService: DataCaptureFormsService, 
                public utilsService: UtilsService,
                private winRef: WindowRef,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
                  this.currentWindow = winRef.nativeWindow;
                }

    ngOnInit(){
        this.populateDropdown();
        this.dataCaptureFormsService.getUserDetails().subscribe(
          data => {
            this.userDetails = data;
            this.activePeriodID = this.utilsService.getFormatedDate(this.userDetails["activePeriodID"]);
          }
        );

        this.subActivatedRoute = this.activatedRoute.params.subscribe(
          (params) => {
            if(+params["id"]) {
              this.utilsService.showLoadingIcon(true);
              this.request.id = +params.id;
              this.dataCaptureFormsService.getRequestDetails(+params["id"], AdjustmentType.BusinessUnitAdjustmentTransfer)
                  .subscribe(
                    (data) => {
                      this.request.setValuesFromAPIObj(data);
                      //this.utilsService.showLoadingIcon(false);
                    },
                    (error) => {
                      this.utilsService.showMessageTip(error["error"]);
                      this.utilsService.showLoadingIcon(false);
                    }
                  )
            }
          }
        )
    }

    ngOnDestroy() {
      this.subActivatedRoute.unsubscribe();
    }
    
  /**
   * 
   */

  autoCompletePolicyReference() {
    this.isPolicyReferenceExists = true;
    if (this.request.policyReference.length < 3) {
      return;
    }
    this.policyReference = this.dataCaptureFormsService
    .getPolicyReferenceBusinessAdjustmentTransferReq(this.request.policyReference)
    .pipe(
      map(transactionNumbers => this.filterPolicyReference(transactionNumbers))
    );
  }

  filterPolicyReference(values) {
    let policyReference = this.request.policyReference;
    if(values.length > 0) {
      this.isPolicyReferenceExists = true;
      return values.filter(policyRef =>
        policyRef['riskReference'].toLowerCase().includes(policyReference.toLowerCase())
      );
    } else {
      this.isPolicyReferenceExists = false;
    }
  }

  autoCompletePolicyReferenceBlur() {
    if(!this.request.policyReference || !this.isPolicyReferenceExists) {
      this.request.policyReferenceId = null;
      return;
    }

    this.policyReference.subscribe(data => {
      if(data.length == 1) {
        this.request.policyReference = data[0].riskReference;
        this.request.policyReferenceId = data[0].policyId;
      }
    })
  }
  /**
   * 
   */
  reasonsForTransferChange(e: Event) {
    let selectedOptionArr = this.reasonForTransferOptions.filter(
      currentVal => {
        return currentVal['reasonID'] == this.request.reasonForTransferId;
      }
    )
    this.request.reasonForTransfer = selectedOptionArr[0]['name'];
  }

  /**
   * 
   */
  incomeTypeChange(e: Event){
    let selectedIncomeType = this.incomeTypeOptions.filter(
      currentVal => {
        return currentVal['incomeTypeId'] == this.request.incomeTypeId;
      }
    );
    this.request.incomeType = selectedIncomeType[0]["name"];
  }

  /**
   * 
   */
  toggleTransferFormSummaryDisplay(e: Event, form: NgForm) {
    if(this.validateForm(form)) {
      this.hideSummary = !this.hideSummary;
      this.currentWindow.scrollTo(0,0);
      this.errorMsg = "";
    }
  }

  validateForm(form) {
    let amountValidator = this.utilsService.validateIncomeAmount(this.request.amount);
    let errorMsgArr = [];
    this.brokerageAmountErrMsg = "";
    if(!this.request.policyReferenceId){
      this.request.policyReference = "";
    }
    if(!form.valid) {
      errorMsgArr.push('<br />Provide correct values for all mandatory fields');
    }
    if(!amountValidator["valid"]) {
      this.brokerageAmountErrMsg = amountValidator.message;
    }
    if(!(this.request.brokerageDetails[0]["costCentre"] || this.request.brokerageDetails[0]["name"])) {
      errorMsgArr.push('<br /> Please provide either Costcentre or Brokerage Account details');
    }
    if(!this.request.brokerageDetails[0]["fyBusinessUnitID"]) {
      errorMsgArr.push('<br /> Please Select Companycode');
    }
    if( errorMsgArr.length > 0 ) {
      this.errorMsg = 'Please resolve following errors:<br />' + errorMsgArr.join('');
      return false;
    }
    return true;
  }

  /**
   * 
   */
  saveConfirmTransferRequest ( e: Event, form: NgForm, actionType): void{
    this.request.userRoles = this.utilsService.setTierItems.getValue();
    let userEmail = localStorage.getItem("userEmail"),
    expectedSaveObj = this.request.getSaveObject();
    let sendTransitionOne = false;

    if (!this.request.id) {
      sendTransitionOne = true;
    } else if (this.request.status == AdjustmentStatus.Rejected) {
      sendTransitionOne = true;
    }

    this.utilsService.showLoadingIcon(true);
    this.dataCaptureFormsService.saveUpdateBusinessUnitTransferRequest(expectedSaveObj)
      .subscribe(
        (response) => {
          if(this.request.id) {
            this.dataCaptureFormsService.workflowSaveConfirmUpdate(actionType, sendTransitionOne, userEmail, this.request);
          } else {
            this.request.id = response;
          }
          this.utilsService.showMessageTip("Request saved successfully");
          this.router.navigate(['adjustments']);
        },
        (error) => {
          this.utilsService.showMessageTip(error['error']['error']);
          this.utilsService.showLoadingIcon(false);
        }
      )
  }
  populateDropdown() {
    this.dataCaptureFormsService.getBusinessAdjustmentTransferDropdownData(this.request.adjTypeID)
         .subscribe(
           (respList) => {
             this.reasonForTransferOptions = respList[0];
             this.incomeTypeOptions = respList[1];
             this.currencyTypeOptions = respList[2];
           }
         );
  }

  /**
   * 
   */
  autoCompleteCostCenter() {
    this.isCostCenterOptionsExists = true;
    if (this.request.brokerageDetails[0]['costCentre'].length < 3) {
      return;
    }

    this.costCenterOptions = this.dataCaptureFormsService.getBusinessAdjustmentTransferCostCenter(this.request.brokerageDetails[0]["costCentre"])
        .pipe(
          map(costCenter => this.filterCostCenterOptions(costCenter))
        );
  }

  filterCostCenterOptions(values) {
    let costCenter = this.request.brokerageDetails[0]["costCentre"];
    if(values.length > 0) {
      this.isCostCenterOptionsExists = true;
      return values.filter(costCenterRef =>
        costCenterRef['costCentre'].toLowerCase().includes(costCenter.toLowerCase())
      );
    } else {
      this.isCostCenterOptionsExists = false;
    }
  }

  /**
   * 
   */
  autoCompleteBrokerageAccount() {
    this.isBrokerageAccountOptionsExist = true;
    if(this.request.brokerageDetails[0]["name"].length < 3) {
      return;
    }

    this.brokerageAccountOptions = this.dataCaptureFormsService.getBusinessAdjustmentTransferBrokerageAccounts(this.request.brokerageDetails[0]["name"])
      .pipe(
        map(brokerageAccounts => this.filterBrokerageAccounts(brokerageAccounts))
      )
  }

  filterBrokerageAccounts(values) {
    let brokerageAccount = this.request.brokerageDetails[0]["name"];
    if(values.length > 0) {
      this.isBrokerageAccountOptionsExist = true;
      return values.filter( brokerageAccountRef => 
        brokerageAccountRef['name'].toLowerCase().includes(brokerageAccount.toLowerCase())
      );
    } else {
      this.isBrokerageAccountOptionsExist = false;
    }
  }

  costCenterSelected(e: Event) {
    this.costCenterOptions.subscribe(
      (data) => {
        this.costCentreCompanyCodes = data[0]["businessUnitWithCompanyList"];
        this.request.brokerageDetails[0]["base"] = data[0]["base"];
        this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
        if(this.costCentreCompanyCodes.length < 2){
          this.request.setBrokerageDetailsValuesFromCostCentre(this.costCentreCompanyCodes);
        } else {
          this.request.setBrokerageDetailsValuesFromCostCentre([]);
        }
      }
    )
  }

  companyCodeSelected(e: Event) {
    let selectedCompanyCode: object[] = [];
    if(e["value"]) {
      selectedCompanyCode =  this.costCentreCompanyCodes.filter(
        (value) => {
          return value["fyBusinessUnitID"] === e["value"];
        }
      );
      if(selectedCompanyCode.length > 0) {
        this.request.setBrokerageDetailsValuesFromCostCentre(selectedCompanyCode);
        //this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
      }
    }
  }

  onCostCentreBlur(e: Event) {
    if(!this.request.brokerageDetails[0]["costCentre"] || !this.isCostCenterOptionsExists) {
      this.request.setBrokerageDetailsValuesFromCostCentre([]);
      this.isBrokerageAccountOptionsExist = true;
      return;
    }
    this.costCenterOptions.subscribe(
      (data) => {
        if(data) {
          let enteredCostCentre = data.filter(
             (obj) => {
               return this.request.brokerageDetails[0]["costCentre"] === obj.costCentre;
             }
          );
          if(enteredCostCentre.length > 0){
            this.costCentreCompanyCodes = enteredCostCentre[0]["businessUnitWithCompanyList"];
            this.request.brokerageDetails[0]["base"] = enteredCostCentre[0]["base"];
            this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
            // if company codes are greaterthan 2 clearing previously selected values.
            if(data[0]["businessUnitWithCompanyList"].length < 2) {
              this.request.setBrokerageDetailsValuesFromCostCentre(this.costCentreCompanyCodes);
            } else {
              this.request.setBrokerageDetailsValuesFromCostCentre([]);
            }
          } else {
            this.costCentreCompanyCodes = []; 
          }
        } else {
          this.request.setBrokerageDetailsValuesFromCostCentre([]);
        }
      })
  }

  policyReferenceSelected(e: Event) {
    this.policyReference.subscribe(
      (data) => {
        this.request.policyReferenceId = data[0].policyId;
      }
    )
  }

  autoCompleteBase(e:Event) {
    this.isBaseOptionsExists = true;
    if(this.request.transferBusinessUnit[0]["base"].length < 3) {
      return;
    }
    this.baseOptions = this.dataCaptureFormsService
        .getBusinessAdjustmentTransferBases(this.request.transferBusinessUnit[0]["base"])
        .pipe(
          map(bases => this.filterBaseDetails(bases))
        )
  }

  filterBaseDetails (values) {
    let baseSearchKey = this.request.transferBusinessUnit[0]['base'];
    if(values.length > 0) {
      this.isBaseOptionsExists = true;
      return values.filter( base => 
        base['base'].toLowerCase().includes(baseSearchKey.toLowerCase())
      );
    } else {
      this.isBaseOptionsExists = false;
    }
  }

  onBaseSelected(e: Event) {
    this.baseOptions.subscribe(
      (data) => {
        this.request.transferBusinessUnit[0]['fyBusinessUnitID'] = data[0].fyBusinessUnitID;
        this.request.transferBusinessUnit[0]['businessUnitBKey'] = data[0].businessUnitBKey;
        this.request.transferBusinessUnit[0]['costCentre'] = data[0].costCenter;
        this.request.transferBusinessUnit[0]['financialYear'] = data[0].financialYear;
        this.request.transferBusinessUnit[0]['businessUnitGroup2'] = data[0].businessUnitGroup2;
        this.request.transferBusinessUnit[0]['businessUnitGroup3'] = data[0].businessUnitGroup3;
        this.request.transferBusinessUnit[0]['businessUnitGroup4'] = data[0].businessUnitGroup4;
      }
    )
  }

  brokerageOptionSelected(e: any) {
    this.brokerageAccountOptions.subscribe(
      (data) => {
        this.request.setBrokerageDetailsValues(data, e.id);
        this.accountCode = data.find(el => el.dataSource === e.id).accountCode;
      }
    )
  }

  onBrokerageAccountBlur() {
    if(!this.request.brokerageDetails[0]["name"] || !this.isBrokerageAccountOptionsExist) {
      this.request.setBrokerageDetailsValues([]);
      this.isCostCenterOptionsExists = true;
      return;
    }
    this.brokerageAccountOptions.subscribe(
      (data) => {
        if(data) {
          let enteredDetails = data.filter(
            (obj) =>  {
              if (this.accountCode){
                return this.request.brokerageDetails[0]['name'] === obj.name && obj.accountCode === this.accountCode;
              } else {
                return this.request.brokerageDetails[0]['name'] === obj.name;
              }
            });
            this.request.setBrokerageDetailsValues(enteredDetails);
        } else {
          this.request.setBrokerageDetailsValues([]);
        }
      }
    )
  }

  currencyTypeChange(e: Event): void {

    let selectedOptionArr = this.currencyTypeOptions.filter(
      currentVal => {
        return currentVal['currencyID'] == this.request.currencyTypeId;
      }
    )
    this.request.currencyTypeCode = selectedOptionArr[0]['code'];
  }

  validateAmountField(value) {
    this.brokerageAmountErrMsg = "";
    let amountValidator = this.utilsService.validateIncomeAmount(value);
    if(!amountValidator["valid"]) {
      this.brokerageAmountErrMsg = amountValidator.message;
    }
  }
}