import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { UtilsService } from './shared/services/utils.service';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { AppInsightService } from 'src/app/shared/services/app-insight.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-adc',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'app';
  private readonly _destroying$ = new Subject<void>(); s
  displayLoadingIconApp: boolean = false;
  constructor(
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    private baseService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private appInsights: AppInsightService
  ) { }

  ngOnInit() {
    this.utilsService.displayLoadingIcon.subscribe(
      (data) => {
        this.displayLoadingIconApp = data;
      }
    );
    this.performAADCode()
  }

  performAADCode() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START),
        takeUntil(this._destroying$))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (payload.account) {
          var localStorageMail = "";
          if (localStorage.getItem('userEmail') !== null && localStorage.getItem('userEmail') !== undefined) {
            localStorageMail = localStorage.getItem('userEmail');
          }

          var emailFromPayload = payload.account.username;
          localStorage.setItem('userEmail', payload.account.username);
          localStorage.setItem('userProfile', JSON.stringify({
            "firstName": payload.account.name,
            "lastName": "",
            "email": payload.account.username,
            "login": payload.account.username
          }))
      
          var dateNowMilliseconds = new Date().getTime();
          if (localStorage.getItem("lastEventDate") !== null && localStorage.getItem("lastEventDate") !== undefined) {
            if (emailFromPayload === localStorageMail) {
              var lastEventDateMilliseconds = parseInt(localStorage.getItem("lastEventDate"));
              if ((dateNowMilliseconds - lastEventDateMilliseconds) > 1000) {
                this.handleTracking();
              }
            } else {
              this.handleTracking();
            }
            localStorage.setItem("lastEventDate", dateNowMilliseconds.toString());
          } else {
            this.handleTracking();
            localStorage.setItem("lastEventDate", dateNowMilliseconds.toString());
          }
          this.baseService.isLoginSuccess.next(true);
        }
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  handleTracking() {
    let email = localStorage.getItem("userEmail");
    this.appInsights.setAuthenticatedUserContext(email);
    this.appInsights.logPageView("Login", "/login");
    return true;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }


  ngOnDestroy() {
    this.utilsService.displayLoadingIcon.unsubscribe();
  }
}
