import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { WindowRef } from '../../shared/services/window-ref.service';
import { DataCaptureFormsService } from './data-capture-forms.service';

import DeferralModel from './deferral.model';
import { AdjustmentStatus, AdjustmentType } from '../../shared/models/adjustment.model';
import { UtilsService } from '../../shared/services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'deferral',
  templateUrl: './deferral.component.html',
  styleUrls: ['./data-capture-forms.scss']
})
export class DeferralComponent implements OnInit, OnDestroy {

  request: DeferralModel = new DeferralModel();;

  transactionNumbers: any;
  isTransactionNumbersExists: boolean = true;
  reasonsForDeferralOptions: any;

  hideSummary: boolean = true;
  errorMsg: string = '';
  brokerageAmountErrMsg: string;
  brokerageDateErrMsg: string;

  currentDate: Date = new Date();
  nextMonthStartingDate : Date;

  subActivatedRoute: any;
  currentWindow: any;

  userDetails: object = {};

  isslipUploaded = false;
  slipUploaded = false;
  activePeriodDate: Date;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private winRef: WindowRef,
    private dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService
  ) {

    this.currentWindow = winRef.nativeWindow;
  }

  ngOnInit() {
    this.dataCaptureFormsService.getReasonsForAdjustment(this.request.adjTypeID)
      .subscribe(
        (data) => {
          this.reasonsForDeferralOptions = data;
        }
      );
    //Getting the details for edit in case required
    this.subActivatedRoute = this.activatedRoute.params.subscribe(params => {
      if (params['actionType'] == 'reDeferral') {
        this.request.actionType = 'reDeferral';
      }
      if (+params['id']) {
        this.utilsService.showLoadingIcon(true);
        this.request.id = +params['id'];
        this.dataCaptureFormsService.getRequestDetails(+params['id'], AdjustmentType.Deferral)
          .subscribe((result) => {
            this.request.setValuesFromAPIObj(result);
            //this.utilsService.showLoadingIcon(false);
          },
          (error) => {
            this.utilsService.showMessageTip(error["error"]);
            this.utilsService.showLoadingIcon(false);
          });
      }
    });
    this.dataCaptureFormsService.getUserDetails()
      .subscribe(
        (data) => {
          this.userDetails = data;
          this.activePeriodDate = this.utilsService.getFormatedDate(this.userDetails["activePeriodID"]);
        }
      );    
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }

  toggleFormSummaryDisplay(event: Event, form: NgForm): void {

    if(this.formValidations(form)){
      this.hideSummary = !this.hideSummary;
      this.currentWindow.scrollTo(0, 0);
    }
  }

  addDeferralRequests(event: Event, currency): void {
    if(this.request.multiCurrencies.length > 1) {
      this.request.deferralRequests.push({
        curencyId: null,
        currency: null,
        brokerage: 0,
        brokerageRecognitionDate: null,
        isDisabled: false,
        isCurrentPeriodId:true
      });
    } else {
      this.request.deferralRequests.push({
        curencyId: this.request.currencyID,
        currency: this.request.currency,
        brokerage: 0,
        brokerageRecognitionDate: null,
        isDisabled: false,
        isCurrentPeriodId:true
      });
    }
  }

  removeDeferralRequests(event: Event, index: number): void {
    let newDeferralRequests = this.request.deferralRequests.filter((currentVal, i) => {
      return i != index;
    });
    this.request.deferralRequests = newDeferralRequests;
    this.deferralRequestsDatesValidation();
    this.checkTotalBrokerageAmounts();
  }

  checkTotalBrokerageAmounts(): boolean {

    let deferralRequestTotal: number = 0;
    let numberWithoutComma;
    let hasValueAsZero: boolean = false;
    
    this.request.deferralRequests.forEach((deferralRequest: object) => {
      if(deferralRequest['brokerage']==0){
        hasValueAsZero = true;
      }
      numberWithoutComma = (deferralRequest['brokerage'] && deferralRequest['brokerage'].replace) ? deferralRequest['brokerage'].replace(/,/g, '') : deferralRequest['brokerage'];
      deferralRequestTotal += Number(numberWithoutComma);
    });
    if(hasValueAsZero){
      this.brokerageAmountErrMsg = 'Please update the brokerage amount to a value other than 0';
      return false;
    }
    if (deferralRequestTotal.toFixed(2) != this.request.amount) {
      this.brokerageAmountErrMsg = 'Total brokerage amount entered should be equal to the current brokerage amount by currency';
      return false;
    }
    if(this.request.selectedBrokerageDetails.length > 1) {
      let brokerageAmountErrorFlag = [];
      this.request.selectedBrokerageDetails.forEach((val, index) => {
        let filteredCurrencyData = this.request.deferralRequests.filter((value, ind) => {
          return val['currencyCode'] === value['currency'];
        });
        let filteredCurrencySum;
        if(filteredCurrencyData.length > 1) {
          filteredCurrencySum = this.sumFilteredCurrencyData(filteredCurrencyData);
        } else {
          filteredCurrencySum = filteredCurrencyData[0]['brokerage'];
        }
        if(this.convertedCurrencyString(filteredCurrencySum) !== val['brokerageAmount']) {
          brokerageAmountErrorFlag.push(true);
        }
      });
      if(brokerageAmountErrorFlag.length) {
        this.brokerageAmountErrMsg = 'Total brokerage amount entered should be equal to the current brokerage amount by currency.';
        return false;
      }
    }
    this.brokerageAmountErrMsg = '';
    return true;
  }

  sumFilteredCurrencyData(data) {
    let sum: number = 0;
    for(let i =0; i < data.length; i++) {
      let brokerageAmountWithoutComma = (data[i]['brokerage'] && data[i]['brokerage'].replace) ? data[i]['brokerage'].replace(/,/g, '') : data[i]['brokerage'];
      sum = Number(Number(sum + parseFloat(brokerageAmountWithoutComma)).toFixed(2));
    }
    return sum;
  }

  autoCompleteTransactionNumbers(): void {
    this.isTransactionNumbersExists = true;
    if (this.request.transactionNumber.length < 3) {
      return;
    }
    this.transactionNumbers = this.dataCaptureFormsService
      .getTransactionNumbers(this.request.transactionNumber, '')
      .pipe(
        map(transactionNumbers => this.filterTransactionNumbers(transactionNumbers))
      );
  }

  filterTransactionNumbers(values) {
    if(values.length) {
      this.isTransactionNumbersExists = true;
      return values.filter(trNo =>
        trNo.transactionNumber.toLowerCase().includes(this.request.transactionNumber.toLowerCase())
      )
    } else {
      this.isTransactionNumbersExists = false;
    }
  }

  transactionNumberSelected(e: Event): void {

    //Commenting this below as blur being used for fetching the data for now
    /*this.dataCaptureFormsService
          .getTransactionNumberDetails(this.request.transactionNumber, 'Deferral')
          .subscribe(
            data => {
              this.request.updateFieldsFromTransactionNumberDetails(data);
            }
          );*/
  }

  transactionNumberBlur(e: Event): void {

    if (this.request.transactionNumber) {
      this.dataCaptureFormsService
        .getTransactionNumberDetails(this.request.transactionNumber, 'Deferral')
        .subscribe(
          data => {
            if (data) {
              this.request.updateFieldsFromTransactionNumberDetails(false, data);
            } else {
              this.request.updateFieldsFromTransactionNumberDetails(true, null);
            }
          }
        );
    } else {
      this.request.updateFieldsFromTransactionNumberDetails(true, null);
    }
  }

  reasonForDeferralChange(e: Event): void {

    let selectedOptionArr = this.reasonsForDeferralOptions.filter(
      currentVal => {
        return currentVal['reasonID'] == this.request.reasonForDeferralId;
      }
    )
    this.request.reasonForDeferral = selectedOptionArr[0]['name'];
  }

  brokerageAccountChange(e: Event): void {
    let groupByData;
    let selectedOptionArr = this.request.brokerageDetails.filter(
      currentVal => {
        return currentVal['id'] == this.request.deferredBrokerageAccountID;
      }
    )

    this.request.selectedBrokerageDetails = [...selectedOptionArr];
    this.request.brokerageAccount = selectedOptionArr[0]['name'];
    this.request.accountCode = selectedOptionArr[0]['accountCode'];
    this.request.businessUnitGroup2 = selectedOptionArr[0]['businessUnitGroup2'];
    this.request.businessUnitGroup3 = selectedOptionArr[0]['businessUnitGroup3'];
    this.request.businessUnitGroup4 = selectedOptionArr[0]['businessUnitGroup4'];
    this.request.currency = selectedOptionArr[0]['currencyCode'];
    this.request.currencyID = selectedOptionArr[0]['currencyID'];
    this.request.amount = selectedOptionArr[0]['brokerageAmount'];
    this.request.incomeBrokerageRecognitionDate = selectedOptionArr[0]['brokerageRecogDate'];

    //Giving default values to deferral requests first row
    this.request.deferralRequests[0]['curencyId'] = this.request.currencyID;
    this.request.deferralRequests[0]['currency'] = this.request.currency;
    this.request.deferralRequests[0]['brokerage'] = this.request.amount;
    this.request.deferralRequests[0]['brokerageRecognitionDate'] = this.request.incomeBrokerageRecognitionDate;
  }

  slipFileUploadChange(files: FileList) {
    if( this.isslipUploaded ) {
      this.slipUploaded = true;
    }
    if(files.length) {
    this.request.slipFile = files[0];
    this.request.slipFileName = files[0].name;
    this.request.slipExistingFileName = null;
    this.isslipUploaded = true;
    }
  }
  
  chooseFile(event) {
    // this.utilsService.isSessionExists().then((isSessionExists)=>{
        if(!this.utilsService.isSessionExists()) {
            event.preventDefault();
            this.utilsService.showTimedOutMessage();
            event.stopPropagation();
        }else {
    let ele: HTMLElement = document.getElementById('slipfileUpload') as HTMLElement;
    ele.click();
    event.preventDefault();
    event.stopPropagation();
    return false;
    }
  // });
  return false;
  }

  slipFileRemove(e: Event, slipFileUploadField) {

    slipFileUploadField.value = '';
    this.request.slipFile = null;
    this.request.slipFileName = null;
  }

  saveConfirmRequest ( e: Event, form: NgForm, actionType): void{
    if(!this.formValidations(form)){

      this.hideSummary = true;
      return;
    }
    let isRequestIDExists = this.request.id ? true : false;
    let isFileExists: boolean = false;

    let sendTransitionOne = false;//Used to determine if workflow with transition 1 should be called or not
    let userEmail = localStorage.getItem('userEmail');

    if (!this.request.id) {
      sendTransitionOne = true;
    } else if (this.request.status == AdjustmentStatus.Rejected) {
      sendTransitionOne = true;
    }
    this.request.userRoles = this.utilsService.setTierItems.getValue();
    let expectedSaveObj = this.request.getSaveObject();
    let formData;
    if(this.request.slipFileName) {
      formData = new FormData();
      isFileExists = true;
      formData.append('file', this.request.slipFile, this.request.slipFileName);
      formData.append('data', JSON.stringify(expectedSaveObj));
    } else {
      isFileExists = false;
      formData = expectedSaveObj;
    }

    this.utilsService.showLoadingIcon(true);
    this.dataCaptureFormsService.saveRequest(formData, AdjustmentType.Deferral, isFileExists)
      .subscribe(data => {
        if(isRequestIDExists) {
          this.dataCaptureFormsService.workflowSaveConfirmUpdate(actionType, sendTransitionOne, userEmail, this.request);
        } else {
          this.request.id = data;
        }

        this.utilsService.showMessageTip("Request saved successfully");
        this.utilsService.adjustmentsViewType="Current";
        this.router.navigate(['adjustments']);
      },
        error => {
          this.utilsService.showMessageTip(error['error']['error']);
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  getNextMonthStartingDate(): Date{
    
    let currentDateObj = new Date();

    let
      fullYear = currentDateObj.getFullYear(),
      month = currentDateObj.getMonth(),
      date = currentDateObj.getDate(),
      hours = currentDateObj.getHours(),
      minutes = currentDateObj.getMinutes(),
      seconds = currentDateObj.getSeconds();

    let
      newDate,
      newMonth,
      newFullYear;

    newMonth = (month == 11) ? 0 : month + 1;
    newFullYear = (newMonth == 0) ? fullYear + 1 : fullYear;
    newDate = 1;

    return new Date(newFullYear, newMonth, newDate, 0, 0, 0);
  }

  deferralRequestsDatesValidation(): boolean{
    
    let datesArr = [];
    let duplicateValues = [];
    let pastValues = [];
    //let nextMonthStartingDate = this.getNextMonthStartingDate();
    let nextMonthStartingDate = this.utilsService.getFormatedDate(this.userDetails["futureActivePeriodID"]);
    let nextMonthStartingDateTimeMs = nextMonthStartingDate.getTime();
    let currentEarningDate = this.utilsService.getFormatedDate(this.userDetails["activePeriodID"]);
    let currentEarningDateMs = currentEarningDate.getTime();
    
    let currentBrokerageEarningDate = new Date(this.request.incomeBrokerageRecognitionDate);

    let formattedNextMonthBrokerageEarningDate;
    if (currentBrokerageEarningDate.getMonth() == 11) {
      formattedNextMonthBrokerageEarningDate = 1 + "/" + 1 + "/" + (currentBrokerageEarningDate.getFullYear() + 1);
    } else {
      formattedNextMonthBrokerageEarningDate = (currentBrokerageEarningDate.getMonth() + 2) + "/" + 1 + "/" + currentBrokerageEarningDate.getFullYear();
    }
    // let formattedNextMonthBrokerageEarningDate = (currentBrokerageEarningDate.getMonth() + 2) + "/" + 1 + "/" + currentBrokerageEarningDate.getFullYear();
    let nextMonthToCurrentBrokerageEarningDate = new Date(formattedNextMonthBrokerageEarningDate);
    let currentBrokerageEarningDateMS = nextMonthToCurrentBrokerageEarningDate.getTime();

    // let hasAtLeastOneFutureDate = false;
    // let isNotValidBrokerageEarningDate = false;
    // let isValidBrokerageEarningDate = true;
    
    let dateErrMsgArr = [];

    this.brokerageDateErrMsg = '';
    let filteredCurrencies = [];
    

    this.request.deferralRequests.forEach(function(element){
      datesArr.push(new Date(element['brokerageRecognitionDate']).getTime());
    });
    
    if(datesArr.indexOf(0)>-1){
      dateErrMsgArr.push('');//Adding a blank value as mandatory validation provied by form validation as well
    }

    this.request.multiCurrencies.forEach((value, index) => {
      let filteredDatesArray = [];
      let hasAtLeastOneFutureDate = false;
      let isNotValidBrokerageEarningDate = false;
      let isValidBrokerageEarningDate = true;

      filteredCurrencies = this.request.deferralRequests.filter((val) => {
        return val['currency'] === value;
      });
      filteredCurrencies.forEach((data) => {
        filteredDatesArray.push(new Date(data['brokerageRecognitionDate']).getTime());
      });

      duplicateValues = filteredDatesArray.reduce(function (acc, el, i, arr) {
      
        if (arr.indexOf(el, i + 1) != -1 && acc.indexOf(el) < 0) {
          acc.push(el);
        }
        return acc;
      }, []);

      if (duplicateValues && duplicateValues.length>0){
        if(dateErrMsgArr.indexOf('<br />Please provide unique values for all brokerage dates by currency') === -1) {
          dateErrMsgArr.push('<br />Please provide unique values for all brokerage dates by currency');
        }
        return false;
      }

      filteredDatesArray.forEach( (value, index) => {
      let isPastDateRedeferral =false; 
      isPastDateRedeferral=(this.request.actionType =='reDeferral')||(this.request.id!=null && this.request.reDeferralAllowed)
        if((value<currentEarningDateMs && value!=currentEarningDateMs && !isPastDateRedeferral)){
          pastValues.push(
            this.getFormattedDate(this.request.deferralRequests[index]['brokerageRecognitionDate'])
          );
          return false;
        }
        if(value>=nextMonthStartingDateTimeMs){
           hasAtLeastOneFutureDate = true;
        }
        if(value >= currentBrokerageEarningDateMS) {
          isNotValidBrokerageEarningDate = true;
        }
        if(value < currentBrokerageEarningDateMS) {
          isValidBrokerageEarningDate = false;
        }
      });
 
      if(pastValues.length>0 || !hasAtLeastOneFutureDate || !isNotValidBrokerageEarningDate){
        if(dateErrMsgArr.indexOf('<br />Please provide at least one date in a future period to the current brokerage earning period') === -1){
          dateErrMsgArr.push('<br />Please provide at least one date in a future period to the current brokerage earning period');
          return false;
        }
        //dateErrMsgArr.push('<br />Please provide future date(s) for ' + pastValues.join(', ');      
      }
    })


    // datesArr.forEach( (value, index) => {
    //   if((value<currentEarningDateMs && value!=currentEarningDateMs)){
    //     pastValues.push(
    //       this.getFormattedDate(this.request.deferralRequests[index]['brokerageRecognitionDate'])
    //     );
    //   }
    //   if(value>=nextMonthStartingDateTimeMs){
    //      hasAtLeastOneFutureDate = true;
    //   }
    //   if(value > currentBrokerageEarningDateMS) {
    //     isNotValidBrokerageEarningDate = true;
    //   }
    //   if(value < currentBrokerageEarningDateMS) {
    //     isValidBrokerageEarningDate = false;
    //   }
    // });

    // if(pastValues.length>0 || !hasAtLeastOneFutureDate || !isNotValidBrokerageEarningDate){
    //   dateErrMsgArr.push('<br />Please provide future period date(s) and it should be next month dates to current brokerage earning period date(s).');
    //   //dateErrMsgArr.push('<br />Please provide future date(s) for ' + pastValues.join(', ');      
    // }

    if(dateErrMsgArr.length>0){
      this.brokerageDateErrMsg = dateErrMsgArr.join('');
      return false;
    }

    return true;
  }

  getFormattedDate(dateVal) {

    let dateObj = new Date(dateVal);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
  }

  formValidations(form: NgForm): boolean{

    let errorMsgArr = [];
    this.errorMsg = '';

    if (!form.valid) {

      errorMsgArr.push('<br />Provide correct values for all mandatory fields');
    } 
    
    if (!this.checkTotalBrokerageAmounts()) {

      errorMsgArr.push('<br />Resolve errors related to brokerage amount(s) provided');
    } 
    
    if (!this.deferralRequestsDatesValidation()) {

      errorMsgArr.push('<br />Resolve errors related to brokerage date(s) provided');
    } 
    
    if (!this.request.slipFileName && !this.request.slipExistingFileName) {

      errorMsgArr.push('<br />Upload a slip');
    } 

    if (errorMsgArr.length > 0) {

      this.errorMsg = 'Please resolve following errors:<br />' + errorMsgArr.join('');
      return false;
    }
    return true;
  }

  onDownloadCurrentFile() {
    this.dataCaptureFormsService.downloadAttachment('Deferral', this.request.id);
  }
  validateAmountField(value) {
    this.brokerageAmountErrMsg = "";
    let amountValidator = this.utilsService.validateIncomeAmount(value);
    if(!amountValidator["valid"]) {
      this.brokerageAmountErrMsg = amountValidator.message;
    }
  }

  deferralRequestCurrencyChange(event) {
    let changedRequest = this.request.deferralRequests.filter((val) => {
      return val['currency'] == event.value;
    });
    let changedRequestCurrencyId = this.request.brokerageDetails.filter((val) => {
      return val['currencyCode'] == event.value;
    });
    changedRequest.forEach((value, index) => {
      value['curencyId'] = changedRequestCurrencyId[0]['currencyID'];
    })
  }

  convertedCurrencyString(val) {
    let numberWithoutComma = (val && val.replace) ? val.replace(/,/g, '') : val;
    return Number(numberWithoutComma);
  }
}
