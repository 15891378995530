<app-nav-bar class="mat-elevation-z6"></app-nav-bar>
<mat-sidenav-container>
  <mat-sidenav-content>
    <!-- <div class="spinner-parent" *ngIf="displayLoadingIconApp"> -->
    <!-- <div class="spinner-parent" [hidden]="!displayLoadingIconApp">
      <mat-spinner class="spinner"></mat-spinner>
    </div> -->
    <router-outlet>
        <div class="loader-wrapper" [hidden] = "!displayLoadingIconApp">
        <div class="loader">
          <div class="loader-text">Accelerator</div>
        </div>
      </div>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<!-- <div class="loader-wrapper" [hidden] = "!displayLoadingIconApp">
  <div class="loader">
    <div class="loader-text">Accelerator</div>
  </div>
</div> -->