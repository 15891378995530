import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataCaptureFormsService } from './pages/data-capture/data-capture-forms.service';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  onChange(data: any){
    this.messageSource.next(data);
  }
}