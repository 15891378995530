import { Component, OnInit, Inject } from '@angular/core';
import { NavBarItem } from './nav-bar.model';
import { Router } from '@angular/router';
import { DataCaptureFormsService } from '../../pages/data-capture/data-capture-forms.service';
import { UtilsService } from '../services/utils.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppInsightService } from '../services/app-insight.service';
import { AuthService } from '../services/auth.service';

export interface DialogData {
  message: string
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  navbarItems: Array<NavBarItem>;
  username: string;
  tier: string;
  isAuthenticated: boolean;
  isSessionExists: boolean;
  constructor(
    public router: Router,
    private dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    private appInsights: AppInsightService,
    private baseService: AuthService
  ) {
    this.utilsService.isAuthenticated.subscribe(res => {
      this.isAuthenticated = res;
    });
  }
  async ngOnInit() {

    this.navbarItems = [
      new NavBarItem('Adjustments', 'adjustments')
    ];

    this.baseService.isLoginSuccess.subscribe(status => {
      setTimeout(() => {
        this.GetUserDetails();
        localStorage.setItem('loggedIn', 'UNDONE');
        return true;
      }, 1000);
    })
  }

  login() {
    this.router.navigate(['/adjustments']);
  }

  logout() {
    this.utilsService.logout();
  }

  private async GetUserDetails() {
    this.dataCaptureFormsService.getUserDetails()
      .subscribe(data => {
        if (data) {
          this.utilsService.setTier(false, data['role']);
          this.tier = this.utilsService.getUserRolesDisplay();
          localStorage.setItem('username', data['name']);
          localStorage.setItem('userId', data['id']);
          this.username = data['name'];
          this.utilsService.userDetails = data;
        }
      },
        (error => {
          if (error["error"] && error["error"]["error"] == "User Not Found") {
            const dialogRef = this.dialog.open(DialogContent, {
              height: '250px',
              width: '580px',
              data: { message: error["error"] },
              disableClose: true
            },
            );
            dialogRef.afterClosed().subscribe(result => {
              this.utilsService.logout();
            })
          }
        }));
  }

  onAdjustments() {
    this.utilsService.adjustmentsViewType = "Current";
  }
}

@Component({
  selector: 'dialog-content',
  templateUrl: '../dialog-content.html'
})
export class DialogContent {
  constructor(public dialogRef: MatDialogRef<DialogContent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public utilsService: UtilsService) { }


}
