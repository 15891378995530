<div class="docs-primary-header component-page-header">
  <button mat-button class="sidenav-toggle">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="row-display">
    <h1 class="width40P">{{ request.type }} Data Capture</h1>
    <div class="width40P request-date request-date-placement">Request Date: {{ request.requestDate | date:'d/MM/yyyy' }} &nbsp;|&nbsp; Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy'}}</div>
  </div>
</div>
<div class="col-display content-main-container">
  <div class="form-container" [hidden]="!hideSummary">
    <form #businessUnitAdjustmentForm="ngForm">

      <div class="col-display">

        <mat-form-field class="width40P field-with-hint">
          <input required matInput placeholder="Transaction Number" [matAutocomplete]="transactionNumberAutoComplete" #transactionNumber="ngModel"
            [(ngModel)]="request.transactionNumber" name="transactionNumber" (ngModelChange)="autoCompleteTransactionNumbers()" userSessionExists>
          <mat-hint *ngIf="isTransactionNumbersExists">Please type any 3 letters to search transaction</mat-hint>
          <mat-hint *ngIf="!isTransactionNumbersExists">No transactions found</mat-hint>
          <mat-error>Please provide a Transaction Number</mat-error>
        </mat-form-field>

        <mat-autocomplete #transactionNumberAutoComplete="matAutocomplete" (optionSelected)="transactionNumberSelected($event)">
          <mat-option *ngFor="let trNo of transactionNumbers | async" [value]="trNo.transactionNumber">
            {{trNo.transactionNumber}}
          </mat-option>
        </mat-autocomplete>

        <div class="row-display">

          <mat-form-field>
            <input matInput disabled [matDatepicker]="pickerEffectiveDate" placeholder="Effective Date" [(ngModel)]="request.effectiveDate"
              name="effectiveDate" userSessionExists>
            <mat-datepicker #pickerEffectiveDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="row-display income-type-row hide">
          <mat-form-field>
            <input matInput disabled placeholder="Income Type" [(ngModel)]="request.incomeType" name="incomeType">
          </mat-form-field>
        </div>

        <mat-form-field class="width40P">
          <mat-select required placeholder="Reason for Adjustment" [(ngModel)]="request.reasonForAdjustmentId" name="reasonForAdjustmentId"
            (selectionChange)="reasonForAdjustmentChange($event)" userSessionExists>
            <mat-option *ngFor="let currentVal of reasonsForAdjustmentOptions; let in=index" [value]="currentVal.reasonID">
              {{ currentVal.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select a reason</mat-error>
        </mat-form-field>

        <mat-form-field class="width50P" *ngIf="request.reasonForAdjustment=='Other'">
          <textarea required matInput placeholder="Enter details in case of other" [(ngModel)]="request.otherReason" name="otherReason" userSessionExists></textarea>
          <mat-error>
            Please provide details</mat-error>
        </mat-form-field>

        <h2>Insured</h2>

        <div class="row-display" *ngFor="let currentVal of request.insureds">
          <mat-form-field class="width70P">
            <input matInput disabled placeholder="Insured" [(ngModel)]="currentVal.name" [ngModelOptions]="{standalone: true}" userSessionExists>
          </mat-form-field>

          <mat-form-field>
            <input matInput disabled placeholder="Insured Code" [(ngModel)]="currentVal.code" [ngModelOptions]="{standalone: true}" userSessionExists>
          </mat-form-field>
        </div>

        <h2>Current</h2>
        <ng-container *ngFor="let currentVal of request.brokerageDetails">
          <div class="row-display">
            <mat-form-field class="width70P">
              <input matInput disabled placeholder="Brokerage Account" [(ngModel)]="currentVal.name" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled placeholder="Account Code" [(ngModel)]="currentVal.accountCode" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>
          </div>

          <div class="row-display">
            <mat-form-field>
              <input matInput disabled placeholder="Currency" [(ngModel)]="currentVal.currencyCode" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled placeholder="Amount" [ngModel]="currentVal.brokerageAmount | amountPipe" (ngModelChange)="currentVal.brokerageAmount=$event"
                [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [matDatepicker]="pickerIncomeBrokerageDate" placeholder="Brokerage Date" [(ngModel)]="currentVal.brokerageRecogDate"
                [ngModelOptions]="{standalone: true}" userSessionExists>
              <mat-datepicker #pickerIncomeBrokerageDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row-display">
            <mat-form-field>
              <input matInput disabled placeholder="Business Unit" [(ngModel)]="currentVal.businessUnitGroup2" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [(ngModel)]="currentVal.businessUnitGroup3" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [(ngModel)]="currentVal.businessUnitGroup4" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>
          </div>
        </ng-container>

        <h2>Adjustment Request</h2>

        <mat-form-field class="width40P field-with-hint">
          <input required matInput placeholder="Reversal Transaction Number" [matAutocomplete]="reversalTransactionNumberAutoComplete"
            #reversalTransactionNumber="ngModel" [(ngModel)]="request.reversalTransactionNumber" name="reversalTransactionNumber"
            (ngModelChange)="autoCompleteReversalTransactionNumbers()" userSessionExists>
          <mat-hint *ngIf="isReversalTransactionNumberExists">Please type any 3 letters to search transaction</mat-hint>
          <mat-hint *ngIf="!isReversalTransactionNumberExists">No transactions found</mat-hint>
          <mat-error>Please provide Reversal Transaction Number</mat-error>
        </mat-form-field>

        <mat-autocomplete #reversalTransactionNumberAutoComplete="matAutocomplete" (optionSelected)="reversalTransactionNumberSelected($event)">
          <mat-option *ngFor="let trNo of reversalTransactionNumbers | async" [value]="trNo.transactionNumber">
            {{trNo.transactionNumber}}
          </mat-option>
        </mat-autocomplete>

        <mat-form-field class="width40P field-with-hint">
          <input required matInput placeholder="Corrected Transaction Number" [matAutocomplete]="correctedTransactionNumberAutoComplete"
            #correctedTransactionNumber="ngModel" [(ngModel)]="request.correctedTransactionNumber" name="correctedTransactionNumber"
            (ngModelChange)="autoCompleteCorrectedTransactionNumbers()" userSessionExists>
          <mat-hint *ngIf="isCorrectedTransactionNumberExists">Please type any 3 letters to search transaction</mat-hint>
          <mat-hint *ngIf="!isCorrectedTransactionNumberExists">No transactions found</mat-hint>
          <mat-error>Please provide Corrected Transaction Number</mat-error>
        </mat-form-field>

        <mat-autocomplete #correctedTransactionNumberAutoComplete="matAutocomplete" (optionSelected)="correctedTransactionNumberSelected($event)">
          <mat-option *ngFor="let trNo of correctedTransactionNumbers | async" [value]="trNo.transactionNumber">
            {{trNo.transactionNumber}}
          </mat-option>
        </mat-autocomplete>

        <h2>Corrected Details</h2>

        <ng-container *ngFor="let currentVal of request.correctedBrokerageDetails">

          <div class="row-display">
            <mat-form-field class="width70P">
              <input matInput disabled placeholder="Brokerage Account" [(ngModel)]="currentVal.name" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled placeholder=" Account Code " [(ngModel)]="currentVal.accountCode
                " [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>
          </div>

          <div class="row-display">
            <mat-form-field>
              <input matInput disabled placeholder="Currency" [(ngModel)]="currentVal.currencyCode" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled placeholder="Amount" [ngModel]="currentVal.brokerageAmount | amountPipe" (ngModelChange)="currentVal.brokerageAmount=$event"
                [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [matDatepicker]="pickerIncomeBrokerageDate" placeholder="Brokerage Date" [(ngModel)]="currentVal.brokerageRecogDate"
                [ngModelOptions]="{standalone: true}" userSessionExists>
              <mat-datepicker #pickerIncomeBrokerageDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row-display">
            <mat-form-field>
              <input matInput disabled placeholder="Business Unit" [(ngModel)]="currentVal.businessUnitGroup2" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [(ngModel)]=" currentVal.businessUnitGroup3 " [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>

            <mat-form-field>
              <input matInput disabled [(ngModel)]="currentVal.businessUnitGroup4" [ngModelOptions]="{standalone: true}" userSessionExists>
            </mat-form-field>
          </div>

        </ng-container>

        <h2>Supporting Documentation</h2>

        <div class="row-display">
          <div class="width70P">
            Upload Slip: &nbsp;&nbsp;
            <button mat-raised-button (click)="chooseFile($event)" class="fileuploadButton" >Choose</button>
            <input type="text" (click)="chooseFile($event)" [value]="request.slipFileName ? request.slipFileName : ''"/>
            <input type='file' id="slipfileUpload" class="displaynone" (change)="slipFileUploadChange($event.target.files)" #slipFileUploadField userSessionExists>
            <mat-icon class="adc-remove-icon" (click)="slipFileRemove($event, slipFileUploadField)" userSessionExists>delete</mat-icon>
          </div>
        </div>
        <div class="data-capture-info-text" *ngIf="slipUploaded">*Uploading new file will replace existing file. If file exists</div>

        <div class="row-display existing-file-row" *ngIf="request.slipExistingFileName">
          <div class="width70P">
            Current File: &nbsp;&nbsp;
            <a href="javascript:void(0);" (click)="onDownloadBusinessAdjustmentCurrentFile()">{{ request.slipExistingFileName }}</a>
          </div>
        </div>

        <div *ngIf="errorMsg" class="row-display error-msg bua-error-msg" [hidden]='!errorMsg' [innerHTML]="errorMsg">
        </div>

        <div class="row-display btn-row">
          <!-- <button mat-raised-button (click)="saveConfirmRequest($event, businessUnitAdjustmentForm, 'save') ">Save</button>
          &nbsp;&nbsp;&nbsp;&nbsp; -->
          <button mat-raised-button (click)="toggleFormSummaryDisplay($event, businessUnitAdjustmentForm) " >Next</button>
        </div>

      </div>
    </form>
  </div>
  <div class="summary-container " [hidden]="hideSummary ">
    <div class="col-display ">

      <mat-card>
        <mat-card-title>
          Summary
        </mat-card-title>
        <mat-card-content>

          <div class="row-display summary-row ">
            <div class="width20P summary-label ">Transaction Number: </div>
            <div class="width20P summary-value ">{{ request.transactionNumber }}</div>
          </div>

          <div class="row-display summary-row ">
            <div class="width20P summary-label ">Effective Date: </div>
            <div class="width20P summary-value ">{{ request.effectiveDate | date:'d/MM/yyyy' }}</div>
          </div>

          <div class="row-display summary-row ">
            <div class="width20P summary-label ">Reason for Adjustment: </div>
            <div class="width20P summary-value ">{{ request.reasonForAdjustment }}</div>
            <div class="width20P summary-value " *ngIf="request.reasonForAdjustment=='Other' ">{{ request.otherReason }}</div>
          </div>

          <h2>Insured</h2>

          <ng-container *ngFor="let currentVal of request.insureds">

            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Insured: </div>
              <div class="width70P summary-value ">{{ currentVal.name }}</div>
            </div>
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Insured Code: </div>
              <div class="width70P summary-value ">{{ currentVal.code }}</div>
            </div>
          </ng-container>

          <h2>Current</h2>
          <ng-container *ngFor="let currentVal of request.brokerageDetails">
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Brokerage Account: </div>
              <div class="width20P summary-value ">{{ currentVal.name }}</div>
              <div class="width20P summary-label ">Account Code: </div>
              <div class="width20P summary-value ">{{ currentVal.accountCode }}</div>
            </div>

            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Amount: </div>
              <div class="width70P summary-value ">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}
              </div>
            </div>
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Brokerage Recognition Date: </div>
              <div class="width70P summary-value ">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
            </div>

            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Business Unit: </div>
              <div class="width70P summary-value ">{{ currentVal.businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup3
                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup4 }}
              </div>
            </div>
          </ng-container>

          <h2>Adjustment Request</h2>

          <div class="row-display summary-row ">
            <div class="width20P summary-label ">Reversal Transaction: </div>
            <div class="width20P summary-value ">{{ request.reversalTransactionNumber }}</div>
          </div>

          <div class="row-display summary-row ">
            <div class="width20P summary-label ">Corrected Transaction: </div>
            <div class="width20P summary-value ">{{ request.correctedTransactionNumber }}</div>
          </div>

          <h2>Corrected Details</h2>

          <ng-container *ngFor="let currentVal of request.correctedBrokerageDetails">
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Brokerage Account: </div>
              <div class="width20P summary-value ">{{ currentVal.name }}</div>
              <div class="width20P summary-label ">Account Code: </div>
              <div class="width20P summary-value ">{{ currentVal.accountCode }}</div>
            </div>

            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Amount: </div>
              <div class="width70P summary-value ">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}
              </div>
            </div>
            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Brokerage Recognition Date: </div>
              <div class="width70P summary-value ">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
            </div>

            <div class="row-display summary-row ">
              <div class="width20P summary-label ">Business Unit: </div>
              <div class="width70P summary-value ">{{ currentVal.businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup3
                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup4 }}
              </div>
            </div>
          </ng-container>

          <h2>Supporting Documentation</h2>

          <div class="row-display summary-row" *ngIf="request.slipFileName">
            <div class="width20P summary-label">Slip: </div>
            <div class="width20P summary-value">{{ request.slipFileName }}</div>
          </div>

          <div class="row-display summary-row" *ngIf="request.slipExistingFileName">
            <div class="width20P summary-label">Current Slip: </div>
            <div class="width60P summary-value">
              <a href="javascript:void(0);" (click)="onDownloadBusinessAdjustmentCurrentFile()" userSessionExists>{{ request.slipExistingFileName }}</a>
            </div>
          </div>

          <div class="row-display btn-row summary-btn-row ">
            <button mat-raised-button (click)="toggleFormSummaryDisplay($event, businessUnitAdjustmentForm) " >Back</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button mat-raised-button (click)="saveConfirmRequest($event, businessUnitAdjustmentForm, 'confirm') " >Confirm</button>
          </div>

        </mat-card-content>
      </mat-card>

    </div>
  </div>
</div>
