<div *ngIf="request">
    <div class="docs-primary-header component-page-header" >
      <button mat-button class="sidenav-toggle">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="row-display">
        <h1 class="width40P"><span *ngIf="dataFetchComplete">{{ request.type }}</span> Data Summary</h1>
        <div class="period-date period-date-placement width60P"><button mat-raised-button *ngIf="true" (click)="exportAdjSummaryAsPDF($event)">Export</button>  &nbsp; &nbsp;  Current Period: {{ utilsService.getPeriodDetails() | date:'MMMM yyyy' }}</div>
      </div>
    </div>
    <div class="col-display content-main-container">
      <div class="summary-container">
        <div class="col-display">
    
          <mat-card>
            <mat-card-content *ngIf="dataFetchComplete">
              <div id="adj-detailed-summary">
              <ng-container *ngIf="request.type==AdjustmentType.Deferral">
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Transaction Number: </div>
                  <div class="width20P summary-value">{{ request.transactionNumber }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Current Status: </div>
                  <div class="width20P summary-value">{{ request.status }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reason for Deferral: </div>
                  <div class="width20P summary-value">{{ request.reasonForDeferral }}</div>
                  <div class="width20P summary-value" *ngIf="request.reasonForDeferral=='Other'">{{ request.otherReason }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Effective Date: </div>
                  <div class="width20P summary-value">{{ request.effectiveDate | date:'d/MM/yyyy' }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="((request.status == AdjustmentStatus.Approved && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 )) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Submitted))">
                  <div class="width20P summary-label">Description: </div>
                  <div class="width50P summary-value">{{ request.description }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="(request.status == AdjustmentStatus.Approved  && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 ))">
                  <div class="width20P summary-label">Journal Type: </div>
                  <div class="width20P summary-value">{{ request.journalType == 1 ? "Reversing" : "Non-reversing" }}</div>
                </div>
    
                <ng-container *ngIf="(request.requestedBy && request.status!=AdjustmentStatus.Incomplete) || request.rejectedBy || request.authorizedBy || request.submittedBy || request.approvedBy">
    
                  <div class="summary-heading-line">
                    <h2 class="summary-headings">Processed By</h2>
                  </div>
                  <div class="row-display summary-row" *ngIf="request.requestedBy && request.status!=AdjustmentStatus.Incomplete">
                    <div class="width20P summary-label">Requested By: </div>
                    <div class="width20P summary-value">{{ request.requestedBy }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.rejectedBy">
                    <div class="width20P summary-label">Rejected By: </div>
                    <div class="width20P summary-value">{{ request.rejectedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.rejectedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.authorizedBy">
                    <div class="width20P summary-label">Authorised By: </div>
                    <div class="width20P summary-value">{{ request.authorizedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.authorizedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.submittedBy">
                    <div class="width20P summary-label">Submitted By: </div>
                    <div class="width20P summary-value">{{ request.submittedBy }}</div>
                    <div class="width40P summary-value" *ngIf="request.workFlowType == workFlowType.BusinessToHeadFinance">
                      <span class="bold-text">Comments: </span>{{ request.submittedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.approvedBy">
                    <div class="width20P summary-label">Approved By: </div>
                    <div class="width20P summary-value">{{ request.approvedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.approvedComments }}</div>
                  </div>
    
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Insured</h2>
                </div>
                <ng-container *ngFor="let currentVal of request.insureds">
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Insured: </div>
                    <div class="width70P summary-value ">{{ currentVal.name }}</div>
                  </div>
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Insured Code: </div>
                    <div class="width70P summary-value ">{{ currentVal.code }}</div>
                  </div>
    
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Details</h2>
                </div>
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Brokerage Account: </div>
                  <div class="width20P summary-value">{{ request.brokerageAccount }}</div>
                  <div class="width20P summary-label">Account Code: </div>
                  <div class="width20P summary-value">{{ request.accountCode }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Business Unit: </div>
                  <div class="width70P summary-value">{{ request.businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.businessUnitGroup3
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.businessUnitGroup4 }}
                  </div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Current</h2>
                </div>
                
                <ng-container *ngFor="let currentVal of request.selectedBrokerageDetails">
                  <div class="row-display summary-row">
                    <div class="width20P summary-label">Brokerage: </div>
                    <div class="width20P summary-value">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}</div>
                    <div class="width20P summary-label">Brokerage Earning Date: </div>
                    <div class="width20P summary-value">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
                  </div>
                  
                  <div class="row-display summary-row hide">
                    <div class="width20P summary-label">Income Type: </div>
                    <div class="width20P summary-value">{{ request.incomeType }}</div>
                  </div>
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Deferral Requests</h2>
                </div>
  
                <div class="row-display summary-row" *ngFor="let deferralRequestVar of request.deferralRequests; let in=index;">
    
                  <div class="width20P summary-label">Brokerage: </div>
                  <div class="width20P summary-value">{{ deferralRequestVar.currency }}&nbsp;{{ deferralRequestVar.brokerage | amountPipe }}</div>
    
                  <div class="width20P summary-label">Brokerage Earning Date: </div>
                  <div class="width20P summary-value">{{ deferralRequestVar.brokerageRecognitionDate | date:'d/MM/yyyy' }}</div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Supporting Documentation</h2>
                </div>
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Slip: </div>
                  <div class="width20P summary-value">
                    <a href="javascript:void(0);" (click)="onDeferralDownloadUrlClick()" userSessionExists>{{ request.slipExistingFileName }}</a>
                  </div>
                </div>
    
              </ng-container>
    
              <ng-container *ngIf="request.type==AdjustmentType.Accrual">
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Policy Reference: </div>
                  <div class="width20P summary-value">{{ request.policyReference }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Current Status: </div>
                  <div class="width20P summary-value">{{ request.status }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reason for Accrual: </div>
                  <div class="width20P summary-value">{{ request.reasonForAccrual }}</div>
                  <div class="width20P summary-value" *ngIf="request.reasonForAccrual=='Other'">{{ request.otherReason }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="((request.status == AdjustmentStatus.Approved && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 )) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Submitted))">
                  <div class="width20P summary-label">Description: </div>
                  <div class="width50P summary-value">{{ request.description }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="(request.status == AdjustmentStatus.Approved  && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 ))">
                  <div class="width20P summary-label">Journal Type: </div>
                  <div class="width20P summary-value">{{ request.journalType == 1 ? "Reversing" : "Non-reversing" }}</div>
                </div>
    
                <ng-container *ngIf="(request.requestedBy && request.status!=AdjustmentStatus.Incomplete) || request.rejectedBy || request.authorizedBy || request.submittedBy || request.approvedBy">
    
                  <div class="summary-heading-line">
                      <h2 class="summary-headings">Processed By</h2>
                  </div>
  
                  <div class="row-display summary-row" *ngIf="request.requestedBy && request.status!=AdjustmentStatus.Incomplete">
                    <div class="width20P summary-label">Requested By: </div>
                    <div class="width20P summary-value">{{ request.requestedBy }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.rejectedBy">
                    <div class="width20P summary-label">Rejected By: </div>
                    <div class="width20P summary-value">{{ request.rejectedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.rejectedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.authorizedBy">
                    <div class="width20P summary-label">Authorised By: </div>
                    <div class="width20P summary-value">{{ request.authorizedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.authorizedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.submittedBy">
                    <div class="width20P summary-label">Submitted By: </div>
                    <div class="width20P summary-value">{{ request.submittedBy }}</div>
                    <div class="width40P summary-value" *ngIf="request.workFlowType == workFlowType.BusinessToHeadFinance">
                      <span class="bold-text">Comments: </span>{{ request.submittedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.approvedBy">
                    <div class="width20P summary-label">Approved By: </div>
                    <div class="width20P summary-value">{{ request.approvedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.approvedComments }}</div>
                  </div>
    
                </ng-container>
    
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Details</h2>
                </div>
  
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Renewal Type: </div>
                  <div class="width20P summary-value">{{ request.renewalType }}
                  </div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Insured: </div>
                  <div class="width50P summary-value">{{ request.insuredName }}
                  </div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Insured Code: </div>
                  <div class="width20P summary-value">{{ request.insuredCode }}
                  </div>
                </div>
    
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Effective Date: </div>
                  <div class="width20P summary-value">{{ request.effectiveDate | date:'d/MM/yyyy' }}</div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Brokerage Account</h2>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Brokerage Account: </div>
                  <div class="width50P summary-value">{{ request.brokerageDetails[0].name }}
                  </div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Account Code: </div>
                  <div class="width20P summary-value">{{ request.brokerageDetails[0].accountCode }}
                  </div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Business Unit: </div>
                  <div class="width70P summary-value">{{ request.brokerageDetails[0].businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0].businessUnitGroup3
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0].businessUnitGroup4 }}
                  </div>
                </div>
    
                <div class="summary-heading-line">
                    <h2 class="summary-headings">Income Details</h2>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Income Type: </div>
                  <div class="width20P summary-value">{{ request.incomeType }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Income Amount: </div>
                  <div class="width20P summary-value">{{ request.currencyTypeCode }}&nbsp;&nbsp;{{ request.amount | amountPipe }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Earning Date: </div>
                  <div class="width20P summary-value">{{ request.brokerageEarningDate | date:'d/MM/yyyy' }}</div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Supporting Documentation</h2>
                </div>
    
                <div class="row-display summary-row" *ngIf="request.slipExistingFileName">
                  <div class="width20P summary-label">Slip: </div>
                  <div class="width20P summary-value">
                    <a href="javascript:void(0);" (click)="onAccrualDownloadUrlClick(false)" userSessionExists>{{ request.slipExistingFileName }}</a>
                  </div>
                </div>
                <div class="row-display summary-row" *ngIf="request.confirmationOfOrderExistingFileName">
                  <div class="width20P summary-label">Confirmation of Order: </div>
                  <div class="width20P summary-value">
                    <a href="javascript:void(0);" (click)="onAccrualDownloadUrlClick(true)" userSessionExists>{{ request.confirmationOfOrderExistingFileName }}</a>
                  </div>
                </div>
    
    
    
              </ng-container>
    
              <ng-container *ngIf="request.type==AdjustmentType.BusinessUnitAdjustment">
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Transaction Number: </div>
                  <div class="width20P summary-value">{{ request.transactionNumber }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Current Status: </div>
                  <div class="width20P summary-value">{{ request.status }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reason for Adjustment: </div>
                  <div class="width20P summary-value">{{ request.reasonForAdjustment }}</div>
                  <div class="width20P summary-value" *ngIf="request.reasonForAdjustment=='Other'">{{ request.otherReason }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="((request.status == AdjustmentStatus.Approved && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 )) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Submitted))">
                  <div class="width20P summary-label">Description: </div>
                  <div class="width50P summary-value">{{ request.description }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="(request.status == AdjustmentStatus.Approved  && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 ))">
                  <div class="width20P summary-label">Journal Type: </div>
                  <div class="width20P summary-value">{{ request.journalType == 1 ? "Reversing" : "Non-reversing" }}</div>
                </div>
    
                <ng-container *ngIf="(request.requestedBy && request.status!=AdjustmentStatus.Incomplete) || request.rejectedBy || request.authorizedBy || request.submittedBy || request.approvedBy">
  
                  <div class="summary-heading-line">
                    <h2 class="summary-headings">Processed By</h2>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.requestedBy && request.status!=AdjustmentStatus.Incomplete">
                    <div class="width20P summary-label">Requested By: </div>
                    <div class="width20P summary-value">{{ request.requestedBy }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.rejectedBy">
                    <div class="width20P summary-label">Rejected By: </div>
                    <div class="width20P summary-value">{{ request.rejectedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.rejectedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.authorizedBy">
                    <div class="width20P summary-label">Authorised By: </div>
                    <div class="width20P summary-value">{{ request.authorizedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.authorizedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.submittedBy">
                    <div class="width20P summary-label">Submitted By: </div>
                    <div class="width20P summary-value">{{ request.submittedBy }}</div>
                    <div class="width40P summary-value" *ngIf="request.workFlowType == workFlowType.BusinessToHeadFinance">
                      <span class="bold-text">Comments: </span>{{ request.submittedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.approvedBy">
                    <div class="width20P summary-label">Approved By: </div>
                    <div class="width20P summary-value">{{ request.approvedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.approvedComments }}</div>
                  </div>
    
                </ng-container>
    
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Insured</h2>
                </div>
    
                <ng-container *ngFor="let currentVal of request.insureds">
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Insured: </div>
                    <div class="width70P summary-value ">{{ currentVal.name }}</div>
                  </div>
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Insured Code: </div>
                    <div class="width70P summary-value ">{{ currentVal.code }}</div>
                  </div>
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Current</h2>
                </div>
                <ng-container *ngFor="let currentVal of request.brokerageDetails">
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Brokerage Account: </div>
                    <div class="width20P summary-value ">{{ currentVal.name }}</div>
                    <div class="width20P summary-label ">Account Code: </div>
                    <div class="width20P summary-value ">{{ currentVal.accountCode }}</div>
                  </div>
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Amount: </div>
                    <div class="width70P summary-value ">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}
                    </div>
                  </div>
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Brokerage Recognition Date: </div>
                    <div class="width70P summary-value ">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
                  </div>
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Business Unit: </div>
                    <div class="width70P summary-value ">{{ currentVal.businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup3
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup4 }}
                    </div>
                  </div>
                </ng-container>
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Adjustment Request</h2>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reversal Transaction Number: </div>
                  <div class="width20P summary-value">{{ request.reversalTransactionNumber }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Corrected Transaction Number: </div>
                  <div class="width20P summary-value">{{ request.correctedTransactionNumber }}</div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Corrected Details</h2>
                </div>
    
                <ng-container *ngFor="let currentVal of request.correctedBrokerageDetails">
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Brokerage Account: </div>
                    <div class="width20P summary-value ">{{ currentVal.name }}</div>
                    <div class="width20P summary-label ">Account Code: </div>
                    <div class="width20P summary-value ">{{ currentVal.accountCode }}</div>
                  </div>
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Amount: </div>
                    <div class="width70P summary-value ">{{ currentVal.currencyCode }}&nbsp;&nbsp;{{ currentVal.brokerageAmount | amountPipe }}
                    </div>
                  </div>
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Brokerage Recognition Date: </div>
                    <div class="width70P summary-value ">{{ currentVal.brokerageRecogDate | date:'d/MM/yyyy' }}</div>
                  </div>
    
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Business Unit: </div>
                    <div class="width70P summary-value ">{{ currentVal.businessUnitGroup2 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup3
                      }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ currentVal.businessUnitGroup4 }}
                    </div>
                  </div>
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Supporting Documentation</h2>
                </div>
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Slip: </div>
                  <div class="width20P summary-value">
                    <a href="javascript:void(0);" (click)="onBusinessUnitAdjustmentDownloadUrlClick()" userSessionExists>{{ request.slipExistingFileName }}</a>
                  </div>
                </div>
    
    
              </ng-container>
    
              <ng-container *ngIf="request.type==AdjustmentType.BusinessUnitAdjustmentTransfer">
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Policy Reference: </div>
                  <div class="width20P summary-value">{{ request.policyReference }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Current Status: </div>
                  <div class="width20P summary-value">{{ request.status }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reason for Transfer: </div>
                  <div class="width20P summary-value">{{ request.reasonForTransfer }}</div>
                  <div class="width20P summary-value" *ngIf="request.reasonForTransfer=='Other'">{{ request.otherReason }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="((request.status == AdjustmentStatus.Approved && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 )) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Submitted))">
                  <div class="width20P summary-label">Description: </div>
                  <div class="width50P summary-value">{{ request.description }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="(request.status == AdjustmentStatus.Approved  && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 ))">
                  <div class="width20P summary-label">Journal Type: </div>
                  <div class="width20P summary-value">{{ request.journalType == 1 ? "Reversing" : "Non-reversing" }}</div>
                </div>
    
                <ng-container *ngIf="(request.requestedBy && request.status!=AdjustmentStatus.Incomplete) || request.rejectedBy || request.authorizedBy || request.submittedBy || request.approvedBy">
    
                    <div class="summary-heading-line">
                      <h2 class="summary-headings">Processed By</h2>
                    </div>
    
                  <div class="row-display summary-row" *ngIf="request.requestedBy && request.status!=AdjustmentStatus.Incomplete">
                    <div class="width20P summary-label">Requested By: </div>
                    <div class="width20P summary-value">{{ request.requestedBy }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.rejectedBy">
                    <div class="width20P summary-label">Rejected By: </div>
                    <div class="width20P summary-value">{{ request.rejectedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.rejectedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.authorizedBy">
                    <div class="width20P summary-label">Authorised By: </div>
                    <div class="width20P summary-value">{{ request.authorizedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.authorizedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.submittedBy">
                    <div class="width20P summary-label">Submitted By: </div>
                    <div class="width20P summary-value">{{ request.submittedBy }}</div>
                    <div class="width40P summary-value" *ngIf="request.workFlowType == workFlowType.BusinessToHeadFinance">
                      <span class="bold-text">Comments: </span>{{ request.submittedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.approvedBy">
                    <div class="width20P summary-label">Approved By: </div>
                    <div class="width20P summary-value">{{ request.approvedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.approvedComments }}</div>
                  </div>
    
                </ng-container>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Details</h2>
                </div>
      
                  <div class="row-display summary-row">
                    <div class="width20P summary-label">Income Type: </div>
                    <div class="width20P summary-value">{{ request.incomeType }}</div>
                  </div>
      
                  <div class="row-display summary-row">
                    <div class="width20P summary-label">Income Amount: </div>
                    <div class="width20P summary-value">{{ request.currencyTypeCode }}&nbsp;&nbsp;{{ request.amount | amountPipe }}</div>
                  </div>
      
                  <div class="row-display summary-row">
                    <div class="width20P summary-label">Earning Date: </div>
                    <div class="width20P summary-value">{{ request.brokerageEarningDate | date:'d/MM/yyyy' }}</div>
                  </div>
    
                  <div class="row-display summary-row">
                    <div class="width20P summary-label">Cost Centre: </div>
                    <div class="width20P summary-value">{{ request.brokerageDetails[0]['costCentre'] }}</div>
                    <div class="width20P summary-label" *ngIf="request.brokerageDetails[0]['companyCode']">Company Code: </div>
                    <div class="width20P summary-value" *ngIf="request.brokerageDetails[0]['companyCode']">{{ request.brokerageDetails[0]["companyCode"] }}</div>  
                  </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Brokerage Account: </div>
                  <div class="width20P summary-value">{{ request.brokerageDetails[0]["name"] }}</div>
                  <div class="width20P summary-label">Account Code: </div>
                  <div class="width20P summary-value">{{ request.brokerageDetails[0]["accountCode"] }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Business Unit: </div>
                  <div class="width70P summary-value">{{ request.brokerageDetails[0]['businessUnitGroup2'] }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup3']
                  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup4'] }}
                  </div>
                </div>
    
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Transfer Request</h2>
                </div>
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Base: </div>
                    <div class="width70P summary-value ">{{ request.transferBusinessUnit[0]['base'] }}</div>
                  </div>
    
      
                  <div class="row-display summary-row ">
                    <div class="width20P summary-label ">Transfer Business Unit: </div>
                    <div class="width70P summary-value ">{{request.transferBusinessUnit[0]['businessUnitGroup2']}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{request.transferBusinessUnit[0]['businessUnitGroup3']}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{request.transferBusinessUnit[0]['businessUnitGroup4']}}
                    </div>
                  </div>
    
              </ng-container>
  
              <!-- Miscellaneous Income Summary starts -->
              <ng-container *ngIf="request.type == AdjustmentType.MiscellaneousIncome">
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Current Status: </div>
                  <div class="width20P summary-value">{{ request.status }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Reason for Adjustment: </div>
                  <div class="width20P summary-value">{{ request.reasonForMiscellaneous }}</div>
                  <div class="width20P summary-value" *ngIf="request.reasonForMiscellaneous=='Other'">{{ request.otherReason }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="((request.status == AdjustmentStatus.Approved && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 )) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Submitted))">
                  <div class="width20P summary-label">Description: </div>
                  <div class="width50P summary-value">{{ request.description }}</div>
                </div>
    
                <div class="row-display summary-row" *ngIf="(request.status == AdjustmentStatus.Approved  && (tier.indexOf(Tiers.Tier1) !== -1 || tier.indexOf(Tiers.Tier1) !== -1 ))">
                  <div class="width20P summary-label">Journal Type: </div>
                  <div class="width20P summary-value">{{ request.journalType == 1 ? "Reversing" : "Non-reversing" }}</div>
                </div>
  
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Income Type: </div>
                  <div class="width20P summary-value">{{ request.incomeType }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Income Amount: </div>
                  <div class="width20P summary-value">{{ request.currencyTypeCode }}&nbsp;&nbsp;{{ request.amount | amountPipe }}</div>
                </div>
    
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Earning Date: </div>
                  <div class="width20P summary-value">{{ request.brokerageEarningDate | date:'d/MM/yyyy' }}</div>
                </div>
  
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Cost Centre: </div>
                  <div class="width20P summary-value">{{ request.brokerageDetails[0]['costCentre'] }}</div>
                  <div class="width20P summary-label" *ngIf="request.brokerageDetails[0]['companyCode']">Company Code: </div>
                  <div class="width20P summary-value" *ngIf="request.brokerageDetails[0]['companyCode']">{{ request.brokerageDetails[0]["companyCode"] }}</div>  
                </div>
  
              <div class="row-display summary-row">
                <div class="width20P summary-label">Business Unit: </div>
                <div class="width70P summary-value">{{ request.brokerageDetails[0]['businessUnitGroup2'] }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup3']
                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ request.brokerageDetails[0]['businessUnitGroup4'] }}
                </div>
              </div>
  
                <ng-container *ngIf="(request.requestedBy && request.status!=AdjustmentStatus.Incomplete) || request.rejectedBy || request.authorizedBy || request.submittedBy || request.approvedBy">
    
                    <div class="summary-heading-line">
                      <h2 class="summary-headings">Processed By</h2>
                    </div>
    
                  <div class="row-display summary-row" *ngIf="request.requestedBy && request.status!=AdjustmentStatus.Incomplete">
                    <div class="width20P summary-label">Requested By: </div>
                    <div class="width20P summary-value">{{ request.requestedBy }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.rejectedBy">
                    <div class="width20P summary-label">Rejected By: </div>
                    <div class="width20P summary-value">{{ request.rejectedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.rejectedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.authorizedBy">
                    <div class="width20P summary-label">Authorised By: </div>
                    <div class="width20P summary-value">{{ request.authorizedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.authorizedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.submittedBy">
                    <div class="width20P summary-label">Submitted By: </div>
                    <div class="width20P summary-value">{{ request.submittedBy }}</div>
                    <div class="width40P summary-value" *ngIf="request.workFlowType == workFlowType.BusinessToHeadFinance">
                      <span class="bold-text">Comments: </span>{{ request.submittedComments }}</div>
                  </div>
    
                  <div class="row-display summary-row" *ngIf="request.approvedBy">
                    <div class="width20P summary-label">Approved By: </div>
                    <div class="width20P summary-value">{{ request.approvedBy }}</div>
                    <div class="width40P summary-value">
                      <span class="bold-text">Comments: </span>{{ request.approvedComments }}</div>
                  </div>
    
                </ng-container>
  
                <div class="summary-heading-line">
                  <h2 class="summary-headings">Supporting Documentation</h2>
                </div>
                <div class="row-display summary-row">
                  <div class="width20P summary-label">Slip: </div>
                  <div class="width20P summary-value">
                    <a href="javascript:void(0);" (click)="onMiscIncomeDownloadUrlClick()" userSessionExists>{{ request.slipExistingFileName }}</a>
                  </div>
                </div>
  
              </ng-container>
            </div>
              <!-- Miscellaneous Income Summary Ends -->
  
              <h2 *ngIf="((tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Authorized)) || ((tier.indexOf(Tiers.Tier1) > -1 && request.status==AdjustmentStatus.Submitted) )">Review / Edit request</h2>
              <form #summaryActionForm="ngForm">
    
                <div class="row-display summary-row" >
                  <mat-form-field class="width50P" *ngIf="((tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Authorized))">
                    <input required matInput type="text" placeholder="Description" name="description" #currentDescriptionField [(ngModel)]="request.description">
                    <mat-error>
                      Description should not be empty</mat-error>
                  </mat-form-field>
    
                  <mat-form-field class="width50P" *ngIf="((tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Authorized)) || ((tier.indexOf(Tiers.Tier1) > -1 && request.status==AdjustmentStatus.Submitted) )">
                    <mat-select placeholder="Journal Type" name="journalType" [(ngModel)]="request.journalType">
                      <mat-option *ngFor="let type of journalType" [value]=type.id>{{type.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
    
                </div>
                
                <div class="row-display summary-row" *ngIf="(adjustmentsViewType=='Current') && ( (tier.indexOf(Tiers.Tier3) > -1 && request.status==AdjustmentStatus.Requested) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Authorized) || (tier.indexOf(Tiers.Tier1) > -1 && request.status==AdjustmentStatus.Submitted) )">
                  <mat-form-field class="width100P">
                    <textarea required matInput placeholder="Enter comments" name="currentComments" #currentCommentsField [(ngModel)]="currentComments"></textarea>
                    <mat-error>
                      Please enter comments in case of rejection/submission</mat-error>
                  </mat-form-field>
                </div>
    
                <div class="row-display btn-row summary-btn-row">
    
                  <button mat-raised-button (click)="backBtnClick($event)">Back</button>
                  <ng-container *ngIf="((adjustmentsViewType=='Current') && ((tier.indexOf(Tiers.Tier4) > -1 && request.workFlowType==1) || (tier.indexOf(Tiers.Tier2) > -1 && request.workFlowType==2)) && (request.status==AdjustmentStatus.Incomplete || request.status==AdjustmentStatus.Rejected))">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="editRequest($event)">Edit</button>
                  </ng-container>
    
                  <ng-container *ngIf="(adjustmentsViewType=='History') && (tier.indexOf(Tiers.Tier4) > -1 || tier.indexOf(Tiers.Tier2) >-1 ) && (request.type==AdjustmentType.Accrual) &&  (request.reAccrualAllowed)">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="reAccrueRequest($event)">Re-Accrual</button>
                  </ng-container>
                  <ng-container *ngIf="(adjustmentsViewType=='History') && (tier.indexOf(Tiers.Tier4) > -1) && (request.type==AdjustmentType.Deferral) &&  (request.reDeferralAllowed)">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="reDeferralRequest($event)">Re-Deferral</button>
                  </ng-container>
                  <ng-container *ngIf="(adjustmentsViewType=='Current') && ( (tier.indexOf(Tiers.Tier3) > -1 && request.status==AdjustmentStatus.Requested) || (tier.indexOf(Tiers.Tier2) > -1 && request.status==AdjustmentStatus.Authorized) || (tier.indexOf(Tiers.Tier1) > -1 && request.status==AdjustmentStatus.Submitted) )">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="submitRequest($event, summaryActionForm, 'reject')">Reject</button>
                  </ng-container>
    
                  <ng-container *ngIf="(adjustmentsViewType=='Current') && (tier.indexOf(Tiers.Tier3) > -1) && (request.status==AdjustmentStatus.Requested)">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="submitRequest($event, summaryActionForm, 'authorize')">Authorise</button>
                  </ng-container>
    
                  <ng-container *ngIf="(adjustmentsViewType=='Current') && (tier.indexOf(Tiers.Tier2) > -1) && (request.status==AdjustmentStatus.Authorized)">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="submitRequest($event, summaryActionForm, 'submit')">Submit</button>
                  </ng-container>
    
                  <ng-container *ngIf="(adjustmentsViewType=='Current') && (tier.indexOf(Tiers.Tier1) > -1) && (request.status==AdjustmentStatus.Submitted)">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-raised-button (click)="submitRequest($event, summaryActionForm, 'approve')">Approve</button>
                  </ng-container>
    
                </div>
    
              </form>
    
            </mat-card-content>
          </mat-card>
    
        </div>
      </div>
    </div>
  </div>
  