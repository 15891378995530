import { OnInit, OnDestroy, Component } from "@angular/core";
import MiscellaneousIncomeModel from "./miscellaneous-income.model";
import { UtilsService } from "src/app/shared/services/utils.service";
import { DataCaptureFormsService } from "./data-capture-forms.service";
import { map } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { WindowRef } from "src/app/shared/services/window-ref.service";
import { AdjustmentStatus, AdjustmentType } from "src/app/shared/models/adjustment.model";
import { Router, ActivatedRoute } from "@angular/router";
import { SnackBarService } from "src/app/snack-bar-service.service";

@Component({
    selector: 'miscellaneous-income',
    templateUrl: './miscellaneous-income.component.html',
    styleUrls: ['./data-capture-forms.scss']
})

export class MiscellaneousIncomeComponent implements OnInit, OnDestroy {
    request: MiscellaneousIncomeModel = new MiscellaneousIncomeModel();
    reasonForAdjustmentOptions: any;
    
    incomeTypeOptions: any;
    currencyTypeOptions: any;

    isCostCenterOptionsExists: boolean = true;

    costCenterOptions: any;

    costCentreCompanyCodes: object[] = [];

    brokerageAmountErrMsg: String;
    userDetails;
    activePeriodID;

    hideSummary = true;

    currentWindow;

    errMsg: string = "";

    subActivatedRoute;

    isSlipUploaded: boolean = false;
    slipUploaded: boolean = false;

    constructor(public utilsService: UtilsService,
                private dataCaptureFormService: DataCaptureFormsService,
                private winRef: WindowRef,
                private snackBarService : SnackBarService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
                  this.currentWindow = winRef.nativeWindow;
                }

    ngOnInit() {
        this.populateDropdown();
        this.dataCaptureFormService.getUserDetails().subscribe(
          data => {
            this.userDetails = data;
            this.activePeriodID = this.utilsService.getFormatedDate(this.userDetails["activePeriodID"]);
          }
        );

        this.subActivatedRoute = this.activatedRoute.params.subscribe(
          (params) => {
            if(+params["id"]) {
              this.utilsService.showLoadingIcon(true);
              this.request.id = +params.id;
              this.dataCaptureFormService.getRequestDetails(+params["id"], AdjustmentType.MiscellaneousIncome)
                  .subscribe(
                    (data) => {
                      this.request.setValuesFromAPIObj(data);
                      //this.utilsService.showLoadingIcon(false);
                    },
                    (error) => {
                      this.utilsService.showMessageTip(error["error"]);
                      this.utilsService.showLoadingIcon(false);
                    }
                  )
            }
          }
        )

        this.snackBarService.currentMessage.subscribe(data => {
          if(data){
            this.saveConfirmMiscellaneousIncomeRequest(null, null, 'confirm',true);
          }
        });
    }

    ngOnDestroy() {}

    populateDropdown() {
        this.dataCaptureFormService.getMiscellaneousIncomeAdjustmentDropdownData(this.request.adjTypeID).subscribe(
            (responseList) => {
                this.reasonForAdjustmentOptions = responseList[0];
                this.incomeTypeOptions = responseList[1];
                this.currencyTypeOptions = responseList[2];
            }
        )
    }

    /**
   * 
   */
  autoCompleteCostCenter() {
    this.isCostCenterOptionsExists = true;
    if (this.request.brokerageDetails[0]['costCentre'].length < 3) {
      return;
    }

    this.costCenterOptions = this.dataCaptureFormService.getMiscellaneousIncomeCostCenter(this.request.brokerageDetails[0]["costCentre"])
        .pipe(
          map(costCenter => this.filterCostCenterOptions(costCenter))
        );
  }

  filterCostCenterOptions(values) {
    let costCenter = this.request.brokerageDetails[0]["costCentre"];
    if(values.length > 0) {
      this.isCostCenterOptionsExists = true;
      return values.filter(costCenterRef =>
        costCenterRef['costCentre'].toLowerCase().includes(costCenter.toLowerCase())
      );
    } else {
      this.isCostCenterOptionsExists = false;
    }
  }

  reasonsForAdjustmentChange() {
    let selectedOptionArr = this.reasonForAdjustmentOptions.filter(
      currentVal => {
        return currentVal['reasonID'] == this.request.reasonForMiscellaneousId;
      }
    )
    this.request.reasonForMiscellaneous = selectedOptionArr[0]['name'];
  }

  incomeTypeChange(e: Event){
    let selectedIncomeType = this.incomeTypeOptions.filter(
      currentVal => {
        return currentVal['incomeTypeId'] == this.request.incomeTypeId;
      }
    );
    this.request.incomeType = selectedIncomeType[0]["name"];
  }

  currencyTypeChange(e: Event): void {

    let selectedOptionArr = this.currencyTypeOptions.filter(
      currentVal => {
        return currentVal['currencyID'] == this.request.currencyTypeId;
      }
    )
    this.request.currencyTypeCode = selectedOptionArr[0]['code'];
  }

  validateAmountField(value) {
    this.brokerageAmountErrMsg = "";
    let amountValidator = this.utilsService.validateIncomeAmount(value);
    if(!amountValidator["valid"]) {
      this.brokerageAmountErrMsg = amountValidator.message;
    }
  }

  onCostCentreBlur() {
    this.costCenterOptions.subscribe(
      (data) => {
        if(data) {
          let enteredCostCentre = data.filter(
             (obj) => {
               return this.request.brokerageDetails[0]["costCentre"] === obj.costCentre;
             }
          );
          if(enteredCostCentre.length > 0){
            this.costCentreCompanyCodes = enteredCostCentre[0]["businessUnitWithCompanyList"];
            this.request.brokerageDetails[0]["base"] = enteredCostCentre[0]["base"];
            this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
            // if company codes are greaterthan 2 clearing previously selected values.
            if(data[0]["businessUnitWithCompanyList"].length < 2) {
              this.request.setBrokerageDetailsValuesFromCostCentre(this.costCentreCompanyCodes);
            } else {
              this.request.setBrokerageDetailsValuesFromCostCentre([]);
            }
          } else {
            this.costCentreCompanyCodes = []; 
            this.request.setBrokerageDetailsValuesFromCostCentre([]);
          }
        } else {
          this.request.setBrokerageDetailsValuesFromCostCentre([]);
        }
      })
  }

  toggleMiscellaneousIncomeFormSummaryDisplay(e: Event, form: NgForm) {
    if(this.validateForm(form)) {
      this.hideSummary = !this.hideSummary;
      this.currentWindow.scrollTo(0,0);
      this.errMsg = "";
    }
  }

  costCenterSelected(e: Event) {
    this.costCenterOptions.subscribe(
      (data) => {
        this.costCentreCompanyCodes = data[0]["businessUnitWithCompanyList"];
        this.request.brokerageDetails[0]["base"] = data[0]["base"];
        this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
        if(this.costCentreCompanyCodes.length < 2){
          this.request.setBrokerageDetailsValuesFromCostCentre(this.costCentreCompanyCodes);
        } else {
          this.request.setBrokerageDetailsValuesFromCostCentre([]);
        }
      }
    )
  }

  companyCodeSelected(e: Event) {
    let selectedCompanyCode: object[] = [];
    if(e["value"]) {
      selectedCompanyCode =  this.costCentreCompanyCodes.filter(
        (value) => {
          return value["fyBusinessUnitID"] === e["value"];
        }
      );
      if(selectedCompanyCode.length > 0) {
        this.request.setBrokerageDetailsValuesFromCostCentre(selectedCompanyCode);
        //this.request.brokerageDetails[0]["companyCode"] = this.costCentreCompanyCodes[0]["companyCode"];
      }
    }
  }

  validateForm(form) {
    let amountValidator = this.utilsService.validateIncomeAmount(this.request.amount);
    let errorMsgArr = [];
    this.brokerageAmountErrMsg = "";
    if(!form.valid) {
      errorMsgArr.push('<br />Provide correct values for all mandatory fields');
    }
    if(!amountValidator["valid"]) {
      this.brokerageAmountErrMsg = amountValidator.message;
    }

    if(!this.request.brokerageDetails[0]["fyBusinessUnitID"]) {
      errorMsgArr.push('<br /> Please Select valid Costcentre / Companycode');
    }

    if (!this.request.slipFileName && !this.request.slipExistingFileName) {
      errorMsgArr.push('<br />Upload a slip.');
    }

    if( errorMsgArr.length > 0 ) {
      this.errMsg = 'Please resolve following errors:<br />' + errorMsgArr.join('');
      return false;
    }
    return true;
  }

  saveConfirmMiscellaneousIncomeRequest(e: Event, form: NgForm, actionType: string, allowDuplicates : boolean = false) {
      this.request.userRoles = this.utilsService.setTierItems.getValue();
      let userEmail = localStorage.getItem("userEmail"),
      expectedSaveObj = this.request.getSaveObject();
      expectedSaveObj['allowDuplicates'] = allowDuplicates;
      let sendTransitionOne = false;
      let isFileExists = false;
      
      let formData
      if (this.request.slipFileName) {
        isFileExists = true;
        formData = new FormData();
        formData.append('file', this.request.slipFile, this.request.slipFileName);
        formData.append('data', JSON.stringify(expectedSaveObj));
      } else {
        isFileExists = false;
        formData = expectedSaveObj;
      }
      if (!this.request.id) {
        sendTransitionOne = true;
      } else if (this.request.status == AdjustmentStatus.Rejected) {
        sendTransitionOne = true;
      }

      this.utilsService.showLoadingIcon(true);

      this.dataCaptureFormService.saveUpdateMiscellaneousIncomeRequest(formData, isFileExists, this.request['id'])
      .subscribe( (response) => {
        if(this.request.id) {
          this.dataCaptureFormService.workflowSaveConfirmUpdate(actionType, sendTransitionOne, userEmail, this.request);
        } else {
          this.request.id = response;
        }
        this.utilsService.showMessageTip("Request saved successfully");
        this.router.navigate(['adjustments']);
      },
      (error) => {
        this.utilsService.showMessageTipOnFormComponent(error['error']['error']);
        this.snackBarService.onChange(null);
        this.utilsService.showLoadingIcon(false);
      })

  }

  slipFileUploadChange(files: FileList) {

    if(this.isSlipUploaded) {
      this.slipUploaded = true;
    }
    if(files.length) {
    this.request.slipFile = files[0];
    this.request.slipFileName = files[0].name;
    this.request.slipExistingFileName = null;
    this.isSlipUploaded = true;
    }
  };

  chooseFile(event) {
    // this.utilsService.isSessionExists().then((isSessionExists)=>{
        if(!this.utilsService.isSessionExists()) {
            event.preventDefault();
            this.utilsService.showTimedOutMessage();
            event.stopPropagation();
        }else {
    let ele: HTMLElement = document.getElementById('slipfileUpload') as HTMLElement;
    ele.click();
    event.preventDefault();
    event.stopPropagation();
    return false;
    }
  // });
  return false;
}

  onDownloadMiscIncomeCurrentFile() {
    this.dataCaptureFormService.downloadAttachment('MiscellaneousIncomeAdjustment', this.request.id);
  }

  slipFileRemove(e: Event, slipFileUploadField) {

    slipFileUploadField.value = '';
    this.request.slipFile = null;
    this.request.slipFileName = null;
  }

}