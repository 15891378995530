import { AdjustmentType, AdjustmentStatus } from '../../shared/models/adjustment.model';

export default class BusinessUnitAdjustmentTransferModel {
    type: string = AdjustmentType.BusinessUnitAdjustmentTransfer;
    id: number;
    requestDate: Date = new Date();

    policyReferenceId: number;
    policyReference: string;

    journalType: number = 2;
    description: string ="";
    workFlowType: number;

    userRoles: string[];

    adjBusinessTransferDetailID: number;

    adjTypeID: number = 4;

    reasonForTransferId:number;
    reasonForTransfer: string;

    brokerageDetails: object[] = [{
        id: <number>null,
        accountCode: <string>null,
        name: <string>null,
        brokerageAmount: <any>null,
        brokerageRecogDate: <Date>null,
        currencyID: <number>null,
        currencyCode: <string>null,
        businessUnitGroup2: <string>null,
        businessUnitGroup3: <string>null,
        businessUnitGroup4: <string>null,
        fyBusinessUnitID: <number>null,
        costCentre: <string>null,
        companyCode: <string>null,
        base: <string>null
      }];

      transferBusinessUnit: object[] = [{
        fyBusinessUnitID: <number> null,
        businessUnitBKey: <number> null,
        financialYear: <number> null,
        businessUnitGroup2: <string>null,
        businessUnitGroup3: <string>null,
        businessUnitGroup4: <string>null,
        base: <string>null,
        costCentre: <string>null
      }];

    incomeTypeId: number;
    incomeType: string;

    currencyTypeId: number;
    currencyTypeCode: string;

    amount: any;

    brokerageEarningDate: Date;

    costCentre: string;

    otherReason: string;

    status: string = AdjustmentStatus.Incomplete;
    statusId: number;

    //@ToDo: move comments out and use updated variables like submittedComments etc.. as per status
    comments: string;
    //@ToDo: move processedBy out and use updated variables like authorizedBy etc..
    processedBy: string;
    requestedBy: string;
    requestedById: number;
    submittedBy: string;
    submittedById: number;
    submittedComments: string;
    authorizedBy: string;
    authorizedById: number;
    authorizedComments: string;
    approvedBy: string;
    approvedById: number;
    approvedComments: string;
    rejectedBy: string;
    rejectedById: number;
    rejectedComments: string;

    constructor() {}

    setValuesFromAPIObj(requestObj: object): void {
        if(requestObj) {
            this.id = requestObj['requestId'];
            this.policyReferenceId = (requestObj['policyID']) ? requestObj['policyID'] : null;
            this.policyReference = (requestObj['riskReference']) ? requestObj['riskReference'] : null;
            this.adjBusinessTransferDetailID = requestObj['adjBusinessTransferDetailID'] ? requestObj['adjBusinessTransferDetailID'] : null; 
            this.adjTypeID = requestObj["adjTypeID"];        
            this.reasonForTransferId = requestObj["reasonId"];
            this.reasonForTransfer = requestObj["reason"];
            this.brokerageDetails =  [requestObj["fyBusinessUnitDetails"]];
            this.transferBusinessUnit = [requestObj["transferBusinessUnitDetails"]];
            this.incomeType = requestObj["incomeName"];
            this.incomeTypeId = requestObj["incomeTypeId"];
            if(requestObj["transRequest"] && requestObj["transRequest"]["currency"]) {
                this.currencyTypeCode = requestObj["transRequest"]["currency"]["code"];
                this.currencyTypeId = requestObj["transRequest"]["currency"]["currencyID"];
            }
            if(requestObj["transRequest"]){
                this.amount = requestObj["transRequest"]["brokerageAmount"];
                this.brokerageEarningDate = requestObj["transRequest"]["brokerageDate"];
            }
            if(requestObj["description"]) this.description = requestObj["description"];
            if(requestObj["journalType"]) this.journalType = requestObj["journalType"];

            if(requestObj["workFlowType"]) this.workFlowType = requestObj["workFlowType"];

            this.otherReason = requestObj["otherReason"];
            this.requestedById = (requestObj['requestedBy'] && requestObj['requestedBy']['id']) ? requestObj['requestedBy']['id'] : null;
            this.requestedBy = (requestObj['requestedBy'] && requestObj['requestedBy']['name']) ? requestObj['requestedBy']['name'] : null;
            this.submittedById = (requestObj['submittedBy'] && requestObj['submittedBy']['id']) ? requestObj['submittedBy']['id'] : null;
            this.submittedBy = (requestObj['submittedBy'] && requestObj['submittedBy']['name']) ? requestObj['submittedBy']['name'] : null;
            this.submittedComments = (requestObj['submittedBy'] && requestObj['submittedBy']['comments']) ? requestObj['submittedBy']['comments'] : null;

            this.authorizedById = (requestObj['authorizedBy'] && requestObj['authorizedBy']['id']) ? requestObj['authorizedBy']['id'] : null;
            this.authorizedBy = (requestObj['authorizedBy'] && requestObj['authorizedBy']['name']) ? requestObj['authorizedBy']['name'] : null;
            this.authorizedComments = (requestObj['authorizedBy'] && requestObj['authorizedBy']['comments']) ? requestObj['authorizedBy']['comments'] : null;

            this.approvedById = (requestObj['approvedBy'] && requestObj['approvedBy']['id']) ? requestObj['approvedBy']['id'] : null;
            this.approvedBy = (requestObj['approvedBy'] && requestObj['approvedBy']['name']) ? requestObj['approvedBy']['name'] : null;
            this.approvedComments = (requestObj['approvedBy'] && requestObj['approvedBy']['comments']) ? requestObj['approvedBy']['comments'] : null;

            this.rejectedById = (requestObj['rejectedBy'] && requestObj['rejectedBy']['id']) ? requestObj['rejectedBy']['id'] : null;
            this.rejectedBy = (requestObj['rejectedBy'] && requestObj['rejectedBy']['name']) ? requestObj['rejectedBy']['name'] : null;
            this.rejectedComments = (requestObj['rejectedBy'] && requestObj['rejectedBy']['comments']) ? requestObj['rejectedBy']['comments'] : null;

            this.status = requestObj["status"];
       }
    }

    setBrokerageDetailsValues(respObj, dataSource = null) {
        if (dataSource){
            respObj = respObj.filter(el => el.dataSource === dataSource);
        }
        if(respObj.length > 0) {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            this.brokerageDetails[0]['name'] = respObj[0].name;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['businessUnitGroup2'] = respObj[0].businessUnitGroup2;
            this.brokerageDetails[0]['businessUnitGroup3'] = respObj[0].businessUnitGroup3;
            this.brokerageDetails[0]['businessUnitGroup4'] = respObj[0].businessUnitGroup4;
            this.brokerageDetails[0]['id'] = respObj[0].id;
            this.brokerageDetails[0]['brokerageAmount'] = respObj[0].brokerageAmount;
            this.brokerageDetails[0]['brokerageRecogDate'] = respObj[0].brokerageRecogDate;
            this.brokerageDetails[0]['currencyID'] = respObj[0].currencyID;
            this.brokerageDetails[0]['currencyCode'] = respObj[0].currencyCode;
            this.brokerageDetails[0]['fyBusinessUnitID'] = respObj[0].fyBusinessUnitID;
            this.brokerageDetails[0]["costCentre"] = respObj[0].costCentre;
            this.brokerageDetails[0]["companyCode"] = respObj[0].companyCode;
            this.brokerageDetails[0]["base"] = respObj[0].base;
        } else {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = null;
            this.brokerageDetails[0]['businessUnitGroup2'] = null;
            this.brokerageDetails[0]['businessUnitGroup3'] = null;
            this.brokerageDetails[0]['businessUnitGroup4'] = null;
            this.brokerageDetails[0]['id'] = null;
            this.brokerageDetails[0]['brokerageAmount'] = null;
            this.brokerageDetails[0]['brokerageRecogDate'] = null;
            this.brokerageDetails[0]['currencyID'] = null;
            this.brokerageDetails[0]['currencyCode'] = null;
            this.brokerageDetails[0]['fyBusinessUnitID'] = null;
            this.brokerageDetails[0]["costCentre"] = null;
            this.brokerageDetails[0]["companyCode"] = null;
            this.brokerageDetails[0]["base"] = null;
            
        }
    }

    setBrokerageDetailsValuesFromCostCentre(respObj) {
        if(respObj.length) {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            this.brokerageDetails[0]['name'] = respObj[0].name;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['businessUnitGroup2'] = respObj[0].businessUnitGroup2;
            this.brokerageDetails[0]['businessUnitGroup3'] = respObj[0].businessUnitGroup3;
            this.brokerageDetails[0]['businessUnitGroup4'] = respObj[0].businessUnitGroup4;
            this.brokerageDetails[0]['id'] = respObj[0].id;
            this.brokerageDetails[0]['brokerageAmount'] = respObj[0].brokerageAmount;
            this.brokerageDetails[0]['brokerageRecogDate'] = respObj[0].brokerageRecogDate;
            this.brokerageDetails[0]['currencyID'] = respObj[0].currencyID;
            this.brokerageDetails[0]['currencyCode'] = respObj[0].currencyCode;
            this.brokerageDetails[0]['fyBusinessUnitID'] = respObj[0].fyBusinessUnitID;
            this.brokerageDetails[0]['companyCode'] = respObj[0].companyCode;
        } else {
            //this.brokerageDetails[0]['name'] = respObj[0].name;
            this.brokerageDetails[0]['name'] = null;
            //this.brokerageDetails[0]['accountCode'] = respObj[0].accountCode;
            this.brokerageDetails[0]['accountCode'] = null;
            this.brokerageDetails[0]['businessUnitGroup2'] = null;
            this.brokerageDetails[0]['businessUnitGroup3'] = null;
            this.brokerageDetails[0]['businessUnitGroup4'] = null;
            this.brokerageDetails[0]['id'] = null;
            this.brokerageDetails[0]['brokerageAmount'] = null;
            this.brokerageDetails[0]['brokerageRecogDate'] = null;
            this.brokerageDetails[0]['currencyID'] = null;
            this.brokerageDetails[0]['currencyCode'] = null;
            this.brokerageDetails[0]['fyBusinessUnitID'] = null;
            //this.brokerageDetails[0]["costCentre"] = null;
            this.brokerageDetails[0]["companyCode"] = null;
            this.brokerageDetails[0]['companyCode'] = null;
        }
    }

    getSaveObject() {
        let brokerageEarningDateObj;
        let brokerageEarningDateateTimeMs;
        let brokerageEarningDateTimeZoneDate;
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let userRoleStatus: string;

        brokerageEarningDateObj = new Date(this.brokerageEarningDate);
        brokerageEarningDateateTimeMs = brokerageEarningDateObj.getTime() - (brokerageEarningDateObj.getTimezoneOffset() * 60 * 1000);
        brokerageEarningDateTimeZoneDate = new Date(brokerageEarningDateateTimeMs);
        this.description = `${monthNames[brokerageEarningDateObj.getMonth()]} transfer - ${this.brokerageDetails[0]['businessUnitGroup3']} to ${this.transferBusinessUnit[0]["businessUnitGroup3"]}`;

        if(this.userRoles && this.userRoles.indexOf('Tier 4') !== -1){
            userRoleStatus = "RequestedToAuthorised";
          } else if( this.userRoles && this.userRoles.indexOf('Tier 2') !== -1) {
            userRoleStatus = "IncompleteToSumitted";
          }

        return {
            "adjBusinessTransferDetailID": this.adjBusinessTransferDetailID || null,
            "policyID": (this.policyReferenceId) ? this.policyReferenceId : null,
            "brokerageAccountID": this.brokerageDetails[0]['id'],
            "fyBusinessUnitID": this.brokerageDetails[0]['fyBusinessUnitID'],
            "transferFYBusinessUnitID": this.transferBusinessUnit[0]["fyBusinessUnitID"],
            "brokerageEarningDate": brokerageEarningDateTimeZoneDate,
            "incomeAmount": this.amount,
            "requestId": this.id ? this.id : null,
            "adjTypeID": this.adjTypeID,
            "reasonID": this.reasonForTransferId,
            "incomeTypeID": this.incomeTypeId,
            "currencyID": this.currencyTypeId,
            "userEmail": localStorage.getItem('userEmail'),
            "otherReason": this.otherReason,
            "requestComment": "",
            "journalType": this.journalType,
            "description": this.description,
            "transition": userRoleStatus,
            "reasonForTransfer": this.reasonForTransfer,
            "brokerageAccountName": this.brokerageDetails[0]["name"],
            "policyReference": this.policyReference,
            "currencyTypeCode": this.currencyTypeCode,
            "currentBUBase": this.brokerageDetails[0]["base"],
            "transferBUBase": this.transferBusinessUnit[0]["base"],
            "workFlowType": this.workFlowType
        }
    }
}