import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarService } from 'src/app/snack-bar-service.service';

@Component({
  selector: 'snackBarDefault',
  templateUrl: './snackBarDefault.component.html',
  styleUrls: ['./snackBarDefault.scss']
})
export class SnackBarComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data : FormData,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    private snackBarService : SnackBarService,
    ){}
  ngOnInit(){
  }
  confirmCreatingMisscelaneousIncome(){
    this.snackBarService.onChange(this.data);
    this.snackBarRef.dismiss();
  }
}

