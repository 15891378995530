<!-- <nav class="docs-navbar-header">
  <a mat-button routerLink="/" aria-label="accelerator">
    <mat-icon>home</mat-icon>
  </a>
  <a mat-button class="docs-navbar-hide-small docs-button" *ngFor="let item of navbarItems" [routerLink]="item.route" aria-label="item.name">{{item.name}}
  </a>
  <div class="flex-spacer"></div>
  <a mat-button class="docs-button docs-navbar-hide-small" aria-label="username" *ngIf="username">
    {{ username }} ({{ tier }})
  </a>

  <a mat-button class="docs-button docs-navbar-hide-small" aria-label="Accelerator">
    <app-svg-viewer src="../../../assets/Howden-rebranding-white.svg" class="accelerator-logo"></app-svg-viewer>
  </a> -->
<!-- <a mat-icon-button class="docs-button docs-navbar-show-small"
     aria-label="Accelerator">
    <img class="docs-accelerator-logo"
         src="../../../assets/Howden-rebranding-white.svg"
         alt="accelerator">
  </a> -->
<!-- </nav> -->

<mat-toolbar color="primary">
  <a mat-button routerLink="/" aria-label="Accelerator">
    <mat-icon>home</mat-icon>
  </a>
  <a mat-button *ngFor="let item of navbarItems" (click)="onAdjustments()" [routerLink]="item.route" aria-label="item.name">{{item.name}}
  </a>
  <div class="flex-spacer"></div>
  <div class="docs-header-start">

      <!-- <mat-icon *ngIf="!isAuthenticated" (click)="login()" routerLink="/login" class="cursorpointer" matTooltip="Login">lock</mat-icon> -->
      <mat-icon  (click)="logout()" matTooltip="Logout" class="cursorpointer">lock_open</mat-icon>
      <!-- *ngIf="isAuthenticated" -->

    <!-- <a mat-raised-button class="docs-button" *ngIf="!isAuthenticated" (click)="utilsService.login()" routerLink="/login">Login</a>
    <a mat-raised-button class="docs-button" *ngIf="isAuthenticated" (click)="utilsService.logout()">Logout</a> -->
  </div>
  <a mat-button aria-label="username">
    {{ username }} ({{ tier }})
  </a>
  <app-svg-viewer src="../../../assets/Howden-rebranding-white.svg" scaleToContainer="true" class="accelerator-logo"></app-svg-viewer>
</mat-toolbar>
