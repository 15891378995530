<div class="docs-primary-header component-page-header">
  <button mat-button class="sidenav-toggle">
    <mat-icon>menu</mat-icon>
  </button>
  <h1>{{pageNotFound.headerTitle}}</h1>
</div>
<div class="col-display content-main-container">
  <div class="nopage-container">
    <div class="col-display">
      <mat-card>
        <mat-card-content>
            <ng-container>
              <div class="row-display nopage-row">
                  <p class="centeralign">
                    <span class="notfound">{{pageNotFound.errorCode}}!</span>
                    <span><strong>{{pageNotFound.errorMessage}}</strong> </span>
                  </p>
              </div>
            </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>



