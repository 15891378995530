import { NgModule } from "@angular/core";
import { userSessionDirective } from "./user-session.directive";

@NgModule({
    declarations: [
        userSessionDirective
    ],
    exports: [
        userSessionDirective
    ]
})
export default class DirectiveModuel{}