import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { WindowRef } from '../../shared/services/window-ref.service';
import { DataCaptureFormsService } from './data-capture-forms.service';

import AccrualModel from './accrual.model';
import { AdjustmentStatus, AdjustmentType } from '../../shared/models/adjustment.model';
import { UtilsService } from '../../shared/services/utils.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogData {
  message: string
}

@Component({
  selector: 'accrual',
  templateUrl: './accrual.component.html',
  styleUrls: ['./data-capture-forms.scss']
})
export class AccrualComponent implements OnInit, OnDestroy {

  request: AccrualModel = new AccrualModel();

  displayInsuredNameAsAutoComplete: boolean = true;
  displayBrokerageAccountAsAutoComplete: boolean = true;

  policyReferenceOptions: any;
  isPolicyReferenceOptionsExists: boolean = true;

  insuredOptions: any;
  isInsuredOptionsExists:boolean = true;
  brokerageAccountOptions: any;

  renewalTypeOptions: any;
  incomeTypeOptions: any;
  currencyTypeOptions: any;
  reasonForAccrualOptions: any;

  hideSummary: boolean = true;
  errorMsg: string = '';

  subActivatedRoute: any;
  currentWindow: any;

  userDetails: object;
  activePeriodID: Date;

  isSlipUploaded = false;
  slipUploaded = false
  isOrderConfirmationUploaded = false;
  OrderConfirmationUploaded = false;

  brokerageAmountErrMsg: string;
  isBrokerageAccountOptionsExist: boolean = true;

  brokerageAccountErrMsg: string;
  saveAccrualObject:object;
  accountCode: string;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private winRef: WindowRef,
    private dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService,
    private dialog: MatDialog) {
    this.currentWindow = winRef.nativeWindow;
  }

  ngOnInit() {
    this.dataCaptureFormsService.getUserDetails().subscribe(
      data => {
        this.userDetails = data;
        this.activePeriodID = this.utilsService.getFormatedDate(this.userDetails["activePeriodID"]);
      }
    )
    //Using below to get id for editing
    this.subActivatedRoute = this.activatedRoute.params.subscribe(params => {
      if (params['actionType'] == 'reAccrual') {
        this.request.actionType = 'reAccrual';
      }
      if (+params['id']) {
        this.utilsService.showLoadingIcon(true);
        this.request.id = +params['id'];
        this.dataCaptureFormsService.getRequestDetails(+params['id'], AdjustmentType.Accrual)
          .subscribe((result) => {
            this.utilsService.showLoadingIcon(true);
            if (result && result['policyID']) {
              this.request.policyReference = result['riskReference'];
              this.request.setValuesFromAPIObj(result);
              this.dataCaptureFormsService
                .getPolicyReferenceDetailsAccrual(this.request.policyReference, result['financialYear'])
                .subscribe(
                  data => {
                    if(data!=null)
                    {
                    this.request.updateFieldsFromPolicyReferenceDetails(false, data, this);
                    }
                    this.utilsService.showLoadingIcon(false);
                  },
                  (error) => {
                    this.utilsService.showMessageTip(error["error"]);
                    this.utilsService.showLoadingIcon(false);
                  }
                );
            } else {
              this.request.setValuesFromAPIObj(result);
              this.utilsService.showLoadingIcon(false);
            }
          },
          (error) => {
            this.utilsService.showMessageTip(error["error"]);
            this.utilsService.showLoadingIcon(false);
          });
      }
    });
    this.populateDropDowns();
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }

  populateDropDowns(): void {
    this.dataCaptureFormsService.getRenewalTypesForAccrual()
      .subscribe(
        (data) => {
          this.renewalTypeOptions = data;
        }
      );

    this.dataCaptureFormsService.getIncomeTypesForAccrual()
      .subscribe(
        (data) => {
          this.incomeTypeOptions = data;
        }
      );

    this.dataCaptureFormsService.getCurrenciesForAccrual()
      .subscribe(
        (data) => {
          this.currencyTypeOptions = data;
        }
      );

    this.dataCaptureFormsService.getReasonsForAdjustment(this.request.adjTypeID)
      .subscribe(
        (data) => {
          this.reasonForAccrualOptions = data;
        }
      );
  }

  toggleFormSummaryDisplay(event: Event, form: NgForm, workDoneCheck: any, isBackClicked?: boolean): void {
    this.utilsService.showLoadingIcon(true);
    this.request.userRoles = this.utilsService.setTierItems.getValue();
    let saveObj = this.request.getSaveObj();
    if(this.formValidations(form, workDoneCheck)){
      if(!isBackClicked) {
      this.dataCaptureFormsService.isAccrualRequestExists(saveObj).subscribe(
        (response) => {
          if(response) {
            const dialogRef = this.dialog.open(IsRequestExists, {
              height: '250px',
              width: '580px',
              data: {message: 'Shown Message'},
              disableClose: true
            },
            );
            dialogRef.afterClosed().subscribe( result => {
              this.utilsService.showLoadingIcon(false);
              if(result) {
                this.errorMsg = null;
                this.hideSummary = !this.hideSummary;
                this.currentWindow.scrollTo(0, 0);
              }
            });
          } else {
            this.utilsService.showLoadingIcon(false);
            this.errorMsg = null;
            this.hideSummary = !this.hideSummary;
            this.currentWindow.scrollTo(0, 0);
          }
        },
        (error) => {
          this.utilsService.showLoadingIcon(false);
          this.utilsService.showMessageTip(error['error']);
        }
      )
    } else {
      this.utilsService.showLoadingIcon(false);
      this.errorMsg = null;
      this.hideSummary = !this.hideSummary;
      this.currentWindow.scrollTo(0, 0);
    }
    } else {
      this.utilsService.showLoadingIcon(false);
    }
  }

  autoCompletePolicyReference(): void {

    this.isPolicyReferenceOptionsExists = true;
    if (this.request.policyReference.length < 3) {
      this.request.updateFieldsFromPolicyReferenceDetails(true, {}, this);
      return;
    }
    this.policyReferenceOptions = this.dataCaptureFormsService
      .getPolicyReferenceAccrual(this.request.policyReference)
      .pipe(
        map(policyReferences => this.filterPolicyReferences(policyReferences))
      )
  }

  filterPolicyReferences(values) {

    let policyReference = this.request.policyReference;
    if(values.length > 0) {
      this.isPolicyReferenceOptionsExists = true;
      return values.filter(policyRef =>
        policyRef['riskReference'].toLowerCase().includes(policyReference.toLowerCase())
      );
    } else {
      this.isPolicyReferenceOptionsExists = false;
    }
  }

  policyReferenceSelected(e: Event): void {

    /*this.dataCaptureFormsService
      .getPolicyReferenceDetailsAccrual(this.request.policyReference)
      .subscribe(
        data => {
          this.request.updateFieldsFromPolicyReferenceDetails(false, data, this);
        }
      );*/
  }

  policyReferenceBlur(): void {

    if (!this.request.policyReference || !this.isPolicyReferenceOptionsExists) {
      this.request.updateFieldsFromPolicyReferenceDetails(true, {}, this);
      return;
    }

    this.dataCaptureFormsService
      .getPolicyReferenceDetailsAccrual(this.request.policyReference)
      .subscribe(
        data => {
          if (data) {
            this.request.updateFieldsFromPolicyReferenceDetails(false, data, this);
          } else {
            this.request.updateFieldsFromPolicyReferenceDetails(true, {}, this);
          }
        }
      );
  }

  autoCompleteInsureds(): void {

    this.isInsuredOptionsExists = true;
    if (this.request.insuredName.length < 3) {
      return;
    }
    this.insuredOptions = this.dataCaptureFormsService
      .getInsuredsAccrual(this.request.insuredName)
      .pipe(
        map(insureds => this.filterInsureds(insureds))
      );
  }

  filterInsureds(values) {

    let insuredName = this.request.insuredName;
    if(values.length) {
      this.isInsuredOptionsExists = true;
      return values.filter(insured =>
        insured['name'].toLowerCase().includes(insuredName.toLowerCase())
      )
    } else {
      this.isInsuredOptionsExists = false;
    }
  }

  insuredSelected(e: Event): void {

    this.insuredOptions.subscribe(
      data => {
        this.request.insuredCode = data[0]['code'];
        this.request.insuredId = data[0]['insuredId'];
      }
    );
  }

  insuredAutoCompleteBlur(){
    if(!this.request.insuredName || !this.isInsuredOptionsExists) {
      this.request.insuredCode = "";
      return
    }

    this.insuredOptions.subscribe(data => {
      if(data) {
        let enteredDetails = data.filter( val => {
          return val.name == this.request.insuredName;
        });
        this.setInsuredValues(enteredDetails);
      } else {
        this.setInsuredValues([])
      }
    })
  }

  setInsuredValues(data) {
    if(data.length > 0) {
      this.request.insuredCode = data[0]['code'];
      this.request.insuredId = data[0]['insuredId'];
    } else {
      this.request.insuredCode = "";
      this.request.insuredId = null;
    }
  }

  insuredDropDownSelected(e: Event): void {

    let selectedOptionArr = this.insuredOptions.filter(
      currentVal => {
        return currentVal['name'] == this.request.insuredName;
      }
    )
    this.request.insuredCode = selectedOptionArr[0]['code'];
    this.request.insuredId = selectedOptionArr[0]['insuredId'];
  }

  autoCompleteBrokerageAccount(): void {
    if (this.request.brokerageDetails[0]['name'].length < 3) {
      return;
    }
    this.brokerageAccountOptions = this.dataCaptureFormsService
      .getBrokerageAccountsAccrual(this.request.brokerageDetails[0]['name'])
      .pipe(map(brokerageAccounts => 
          this.filterBrokerageAccount(brokerageAccounts)
        )
      );
  }

  filterBrokerageAccount(values) {
    let brokerageAccount = this.request.brokerageDetails[0]["name"];
    if(values.length > 0) {
      this.isBrokerageAccountOptionsExist = true;
      return values.filter(insured =>
        insured['name'].toLowerCase().includes(brokerageAccount.toLowerCase())
      )
    } else {
      this.isBrokerageAccountOptionsExist = false;
    }
  }

  brokerageAccountBlur(e: any) {
    if(!this.request.brokerageDetails[0]["name"] || !this.isBrokerageAccountOptionsExist) {
      this.setBrokerageDetails([]);
      return;
    }

    this.brokerageAccountOptions.subscribe(
      (data) => {
        if(data) {
          let enteredDetails = data.filter(
            (obj) =>  {
              if (this.accountCode){
                return this.request.brokerageDetails[0]['name'] === obj.name && obj.accountCode === this.accountCode;
              } else {
                return this.request.brokerageDetails[0]['name'] === obj.name;
              }
            });
            this.setBrokerageDetails(enteredDetails);
        } else {
          this.setBrokerageDetails([]);
        }
      }
    )
  }

  clearData() {
    this.request.brokerageDetails[0]['name'] = '';
    this.request.brokerageDetails[0]['id'] = '';
    this.request.brokerageDetails[0]['accountCode'] = '';
    this.request.brokerageDetails[0]['brokerageAmount'] = '';
    this.request.brokerageDetails[0]['brokerageRecogDate'] = '';
    this.request.brokerageDetails[0]['currencyID'] = '';
    this.request.brokerageDetails[0]['currencyCode'] = '';
    this.request.brokerageDetails[0]['businessUnitGroup2'] = '';
    this.request.brokerageDetails[0]['businessUnitGroup3'] = '';
    this.request.brokerageDetails[0]['businessUnitGroup4'] = '';
  }

  clearField(value) {
    if(!value || value.length < 3 ){
      this.clearData();
    }
  }

  brokerageAccountSelected(e: any): void {
    
    this.brokerageAccountOptions.subscribe(
      data => {
        if(data) {
          let selectedBrokerageDetails;
          selectedBrokerageDetails =  data.filter(
            (val) => {
                return val.name === this.request.brokerageDetails[0]["name"] && val.dataSource === e.id;
            }
          );
          this.accountCode = selectedBrokerageDetails[0].accountCode;
          this.setBrokerageDetails(selectedBrokerageDetails);
        }
      }
    );
  }

  setBrokerageDetails(selectedBrokerageDetails) {
    if(selectedBrokerageDetails.length > 0) {
      this.request.brokerageDetails[0]['id'] = selectedBrokerageDetails[0]['id'];
      this.request.brokerageDetails[0]['accountCode'] = selectedBrokerageDetails[0]['accountCode'];
      this.request.brokerageDetails[0]['brokerageAmount'] = selectedBrokerageDetails[0]['brokerageAmount'];
      this.request.brokerageDetails[0]['brokerageRecogDate'] = selectedBrokerageDetails[0]['brokerageRecogDate'];
      this.request.brokerageDetails[0]['currencyID'] = selectedBrokerageDetails[0]['currencyID'];
      this.request.brokerageDetails[0]['currencyCode'] = selectedBrokerageDetails[0]['currencyCode'];
      this.request.brokerageDetails[0]['businessUnitGroup2'] = selectedBrokerageDetails[0]['businessUnitGroup2'];
      this.request.brokerageDetails[0]['businessUnitGroup3'] = selectedBrokerageDetails[0]['businessUnitGroup3'];
      this.request.brokerageDetails[0]['businessUnitGroup4'] = selectedBrokerageDetails[0]['businessUnitGroup4'];
    } else {
      this.request.brokerageDetails[0]['id'] = null;
      this.request.brokerageDetails[0]['accountCode'] = null;
      this.request.brokerageDetails[0]['brokerageAmount'] = null;
      this.request.brokerageDetails[0]['brokerageRecogDate'] = null;
      this.request.brokerageDetails[0]['currencyID'] = null;
      this.request.brokerageDetails[0]['currencyCode'] = null;
      this.request.brokerageDetails[0]['businessUnitGroup2'] = null;
      this.request.brokerageDetails[0]['businessUnitGroup3'] = null;
      this.request.brokerageDetails[0]['businessUnitGroup4'] = null;
    }
  }

  renewalTypeChange(e: Event): void {

    let selectedOptionArr = this.renewalTypeOptions.filter(
      currentVal => {
        return currentVal['renewalTypeID'] == this.request.renewalTypeID;
      }
    )
    this.request.renewalType = selectedOptionArr[0]['name'];
  }

  incomeTypeChange(e: Event): void {

    let selectedOptionArr = this.incomeTypeOptions.filter(
      currentVal => {
        return currentVal['incomeTypeId'] == this.request.incomeTypeId;
      }
    )
    this.request.incomeType = selectedOptionArr[0]['name'];
  }

  currencyTypeChange(e: Event): void {

    let selectedOptionArr = this.currencyTypeOptions.filter(
      currentVal => {
        return currentVal['currencyID'] == this.request.currencyTypeId;
      }
    )
    this.request.currencyTypeCode = selectedOptionArr[0]['code'];
  }

  reasonsForAccrualChange(e: Event): void {

    let selectedOptionArr = this.reasonForAccrualOptions.filter(
      currentVal => {
        return currentVal['reasonID'] == this.request.reasonForAccrualId;
      }
    )
    this.request.reasonForAccrual = selectedOptionArr[0]['name'];
  }

  slipFileUploadChange(files: FileList) {

    if(this.isSlipUploaded) {
      this.slipUploaded = true;
    }
    if(files.length) {
    this.request.slipFile = files[0];
    this.request.slipFileName = files[0].name;
    this.request.slipExistingFileName = null;
    this.isSlipUploaded = true;
   }
  }

  chooseSlipFile(event) {
    // this.utilsService.isSessionExists().then((isSessionExists)=>{
        if(!this.utilsService.isSessionExists()) {
            event.preventDefault();
            this.utilsService.showTimedOutMessage();
            event.stopPropagation();
        }else {
    let ele: HTMLElement = document.getElementById('slipfileUpload') as HTMLElement;
    ele.click();
    event.preventDefault();
    event.stopPropagation();
    return false;
    }
  // });
  return false;
}

  chooseOrderFile(event) {
    // this.utilsService.isSessionExists().then((isSessionExists)=>{
        if(!this.utilsService.isSessionExists()) {
            event.preventDefault();
            this.utilsService.showTimedOutMessage();
            event.stopPropagation();
        }else {
    let ele: HTMLElement = document.getElementById('orderfileUpload') as HTMLElement;
    ele.click();
    event.preventDefault();
    event.stopPropagation();
    return false;
    }
  // });
  return false;
  }

  confirmationOfOrderFileUploadChange(files: FileList) {

    if(this.isOrderConfirmationUploaded) {
      this.OrderConfirmationUploaded = true;
    }
    if(files.length) {
    this.request.confirmationOfOrderFile = files[0];
    this.request.confirmationOfOrderFileName = files[0].name;
    this.request.confirmationOfOrderExistingFileName = null;
    this.isOrderConfirmationUploaded = true;
    }
  }

  saveConfirmRequest(e: Event, form: NgForm, actionType: string, workDoneCheck: any): void {

    let isRequestIDExists = this.request.id ? true : false;

    if(!this.formValidations(form, workDoneCheck)){
      this.hideSummary = true;
      return;
    }

    let sendTransitionOne = false;//Used to determine if workflow with transition 1 should be called or not
    let userEmail = localStorage.getItem('userEmail');
    this.request.userRoles = this.utilsService.setTierItems.getValue();
    let formData = new FormData();
    if (this.request.slipFileName && !this.request.reAccrualAllowed) {
      formData.append('slipFile', this.request.slipFile, this.request.slipFileName);
    }
    if(this.request.confirmationOfOrderFileName && !this.request.reAccrualAllowed) {
      formData.append("confirmationOrder", this.request.confirmationOfOrderFile, this.request.confirmationOfOrderFileName);
    }
    let expectedSaveObj = this.request.getSaveObj();
    formData.append('data',JSON.stringify(expectedSaveObj));
    if (!this.request.id) {
      sendTransitionOne = true;
    } else if (this.request.status == AdjustmentStatus.Rejected) {
      sendTransitionOne = true;
    }

    this.utilsService.showLoadingIcon(true);
    this.saveAccrualObject=(this.request.reAccrualAllowed==true)?expectedSaveObj:formData;
    this.utilsService.adjustmentsViewType=(this.request.reAccrualAllowed && this.request.reAccrualRequestId!=null)?"Current":this.utilsService.adjustmentsViewType;
    this.dataCaptureFormsService.saveUpdateAccrualRequest(this.saveAccrualObject)
      .subscribe(data => {
        if(isRequestIDExists) {
          this.dataCaptureFormsService.workflowSaveConfirmUpdate(actionType, sendTransitionOne, userEmail, this.request);
        } else {
          this.request.id = data;
        }  

        this.utilsService.showMessageTip("Request saved successfully");
        this.router.navigate(['adjustments']);
      },
        error => {
          this.utilsService.showMessageTip(error['error']['error']);
          this.utilsService.showLoadingIcon(false);
        });
  }

  formValidations(form: NgForm, workDoneCheck: any): boolean{

    let errorMsgArr = [];
    this.errorMsg = '';
    this.brokerageAmountErrMsg = '';
    let incomeAmountValidator = this.utilsService.validateIncomeAmount(this.request.amount);

    if (!form.valid) {
      
      errorMsgArr.push('<br />Provide correct values for all mandatory fields');
    } 
    
    if (!this.request.slipFileName && !this.request.slipExistingFileName && !this.request.confirmationOfOrderFileName && !this.request.confirmationOfOrderExistingFileName) {
      
      errorMsgArr.push('<br />Upload slip or confirmation of order');
    } 
    
    if (workDoneCheck && !workDoneCheck._checked) {

      errorMsgArr.push('<br />Please confirm about work completion and risk by selecting checkbox');
    }

    if(!this.request.brokerageDetails[0]["id"] && !this.request.brokerageDetails[0]["businessUnitGroup3"]){
      this.brokerageAccountErrMsg = "Please Enter Valid Brokerage Account details";
      errorMsgArr.push('<br />Please Enter Valid Brokerage Account details');
    }

    if( !incomeAmountValidator.valid) {
      this.brokerageAmountErrMsg = incomeAmountValidator.message;
    }

    if (errorMsgArr.length > 0) {

      this.errorMsg = 'Please resolve following errors:<br />' + errorMsgArr.join('');
      return false;
    }
    return true;
  }

  onDownloadAccrualCurrentFile(isConfirmationOrder:boolean){
    this.dataCaptureFormsService.downloadAttachment('Accrual', this.request.id, isConfirmationOrder);
  }

  clearFilePath (fileupload: HTMLInputElement, isConfirmationOrder: boolean) {
    fileupload.value = "";
    if(isConfirmationOrder) {
      this.request.confirmationOfOrderFile = undefined;
      this.request.confirmationOfOrderFileName = undefined;
      this.request.confirmationOfOrderExistingFileName = undefined;
    } else {
      this.request.slipFile = undefined;
      this.request.slipFileName = undefined;
      this.request.slipExistingFileName = undefined;
    }
  }

  validateAmountField(value) {
    this.brokerageAmountErrMsg = "";
    let amountValidator = this.utilsService.validateIncomeAmount(value);
    if(!amountValidator["valid"]){
      this.brokerageAmountErrMsg = amountValidator.message;
    }
  }
}


@Component({
  selector: 'accrual-dialog-content',
  templateUrl: './accrual-dialog-content.html'
})
export class IsRequestExists {
  constructor(public dialogRef: MatDialogRef<IsRequestExists>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
