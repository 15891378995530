import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppComponent } from './app.component';
import { NavBarModule } from './shared/nav-bar';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './pages/home';
import { AdjustmentModule } from './pages/adjustment';
import { DataCaptureFormsModule } from './pages/data-capture';

import { AppDateAdapter, APP_DATE_FORMATS } from './app.date-adapter';
import { UtilsService } from './shared/services/utils.service';
import { CacheInterceptor } from './shared/services/cacheinterceptor.service';
import { PageNotFoundModule } from './pages/page-not-found/page-not-found.module';
import { DialogContent } from './shared/nav-bar/nav-bar.component';
import { UserAuthorizationModule } from './shared/user-authorization';

import { IsRequestExists } from './pages/data-capture/accrual.component';
import { SnackBarComponent } from './pages/data-capture/snackBarDefault.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthConfig } from 'src/assets/auth-config';
import { AppInsightService } from './shared/services/app-insight.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

var authConfig;
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(authConfig.msalConfig);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(authConfig.protectedResources.api.endpoint, authConfig.protectedResources.api.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: authConfig.loginRequest
  };
}

export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
  ];


@NgModule({
  declarations: [
    AppComponent,
    SnackBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSidenavModule,
    NavBarModule,
    AdjustmentModule,
    DataCaptureFormsModule,
    PageNotFoundModule,
    UserAuthorizationModule,
    MatButtonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    interceptorProviders,
    UtilsService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AppInsightService,
    AuthConfig,
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogContent, IsRequestExists, SnackBarComponent]
})
export class AppModule {
  constructor(private authConfiguration: AuthConfig) {
    authConfig = authConfiguration;
  }
}
