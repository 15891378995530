import { Pipe, PipeTransform } from "@angular/core"
import { DecimalPipe } from "@angular/common"

@Pipe({
  name: "amountPipe"
})
export class AmountPipe extends DecimalPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let result;
    let valueSplitArrNegative = [];
    let isNegative;
    let valueWithoutComma;

    if(!args) {
      args = '1.2-2';
    }
    if(value && value.split){
      valueSplitArrNegative = value.split('-');
      if (valueSplitArrNegative.length>0 && valueSplitArrNegative[1]){
        isNegative = true;
        valueWithoutComma = valueSplitArrNegative[1];
      }else{
        isNegative = false;
        valueWithoutComma = valueSplitArrNegative[0];
      }      
    }else{
      isNegative = false;
      valueWithoutComma = value;      
    }

    if (valueWithoutComma && valueWithoutComma.replace){
      valueWithoutComma = valueWithoutComma.replace(/,/g, '');
    }

    result = super.transform(valueWithoutComma, args);
    if(isNegative){
      result = '-'+result;
    }
    return result;
  }
}