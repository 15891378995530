import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AdjustmentType, Tiers } from '../../shared/models/adjustment.model';

import { AdjustmentService } from './adjustment.service';
import { DataCaptureFormsService } from '../data-capture/data-capture-forms.service';
import { UtilsService } from '../../shared/services/utils.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-adjustment-list',
  templateUrl: './adjustment-list.component.html',
  styleUrls: ['./adjustment-list.component.scss']
})
export class AdjustmentListComponent implements OnInit {

  statusesToReviewSummary: object;
  statusesInProgressSummary: object;

  statusesHistoryApprovedSummary: object;
  statusesHistoryOthersSummary: object;

  headerColumns: string[] = ['Id', 'Type', 'Insured','Currency','Amount','Description','Cost Centre','Status', 'Processed By'];
  displayedColumns: string[] = ['requestId', 'type', 'insuredName','currency','amount','description','costCentre', 'status', 'processedBy'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  impactViewData: object[] = [];
  impactViewCurrentMonth: string;
  impactViewNextMonth: string;

  impactViewHeaderColumns: string[];
  impactViewDisplayedColumns: string[] = ['businessUnit', 'settCurrency', 'curBudgeSett', 'curSystemRevenueSett', 'curAdjustedRevenueSett', 'curVarianceSett', 'nextBudgeSett', 'nextSystemRevenueSett', 'nextAdjustedRevenueSett', 'nextVarianceSett'];
  
  viewType: string = "Current";

  monthOptions: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  monthFullNameOptions: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  historyMonth: string;
  historyYear: number;
  historyMonthOptions: string[] = [];
  historyYearOptions: number[] = [];
  isHistoryYearOptionDisabled: boolean = true;
  isHistoryMonthOptionDisabled: boolean = true;
  
  transactionNumberOptions: any;
  isTransactionNumberOptionsExists: boolean =true;
  searchTransactionNumber: string;

  insuredOptions: any;
  isInsuredOptionsExists: boolean = true;
  searchInsuredName: string;

  searchAdjustmentType: number[] = [1,2,3,4,5];

  AdjustmentType: typeof AdjustmentType = AdjustmentType;
  Tiers: typeof Tiers = Tiers;
  
  filteredImpactViewData: object[];
  statusInReveiwPaginationOptions: object = {};
  statusInProgressPaginationOptions: object = {};
  statusHistoryOtherSummaryOptions: object = {
    pageSize: 10
  };
  statusHistoryApprovedSummaryOptions: object = {
    pageSize: 10
  };

  statusToReviewDataSource;
  statusToReviewspans = [];

  statusInProgressDataSource;
  statusInProgressspans = [];

  approvedHistoryAdjustments;
  approvedHistoryAdjSapns = [];

  otherHistoryAdjustmentsDataSource;
  otherHistoryAdjSpans = [];



  constructor(private adjService: AdjustmentService,
    private router: Router,
    private dataCaptureFormsService: DataCaptureFormsService,
    public utilsService: UtilsService) { }

    @ViewChild('statusInReview') statusInReviewPaginatior: MatPaginator;
    @ViewChild('statusInProgress') statusInProgressPaginatior: MatPaginator;
    @ViewChild('historyOthersSummary') statusHistoryOthersSummaryPaginator: MatPaginator;
    @ViewChild('historyApprovedSummary') statusHistoryApprovedSummaryPaginator: MatPaginator;

  ngOnInit() {

    this.initializePaginators();

    //this.utilsService.adjustmentsViewType = null;
    //this.utilsService.adjustmentsHistoryYear = null;
    //this.utilsService.adjustmentsHistoryMonth = null;
    this.utilsService.adjustmentsSearchTransactionNumber = null;
    this.utilsService.adjustmentsSearchInsuredName = null;

   
    this.viewType = (this.utilsService.adjustmentsViewType) ? this.utilsService.adjustmentsViewType : 'Current';

    this.historyYear = (this.utilsService.adjustmentsHistoryYear) ? this.utilsService.adjustmentsHistoryYear : null;
    this.historyMonth = (this.utilsService.adjustmentsHistoryMonth) ? this.utilsService.adjustmentsHistoryMonth : null;
    
    this.searchTransactionNumber = (this.utilsService.adjustmentsSearchTransactionNumber) ? this.utilsService.adjustmentsSearchTransactionNumber : null;
    this.searchInsuredName = (this.utilsService.adjustmentsSearchInsuredName) ? this.utilsService.adjustmentsSearchInsuredName : null;

    this.utilsService.adjustmentsViewType = this.viewType;//Doing this to maintain the value of view type in case selection change of view type never fires  

    if(this.viewType === "Current") {
      this.loadCurrentViewTables();
    } else {
      this.getHistoryRecords();
    }
      
  }

  newRequestRoute(e: Event, requestType: string): void{
    
    switch (requestType) {
      case AdjustmentType.Deferral:
        this.router.navigate(['deferral-data-capture']);
        break;
      case AdjustmentType.Accrual:
        this.router.navigate(['accrual-data-capture']);
        break;
      case AdjustmentType.BusinessUnitAdjustment:
        this.router.navigate(['business-unit-adjustment-data-capture']);
        break;
      case AdjustmentType.BusinessUnitAdjustmentTransfer:
        this.router.navigate(['business-unit-adjustment-transfer']);
        break;
      case AdjustmentType.MiscellaneousIncome: 
        this.router.navigate(['miscellaneous-income']);
        break;
      default:
        this.router.navigate(['adjustments']);
    }    
  }

  viewSummary(e: Event, row: object): void {
    this.router.navigate(['summary-action', row['requestId'], row['type']]);
  }

  setCurrentNextMonthImpactView(data: object): void{
    
    this.impactViewCurrentMonth = data['currentMonth'];
    this.impactViewNextMonth = data['nextMonth'];
  }

  setImpactViewHeaderColumns(): void{
    let impactViewCurrentMonthBudget, impactViewCurrentMonthSysRevenue, impactViewCurrentMonthAdjRevenue, impactViewCurrentMonthVariance,
        impactViewNextMonthBudget, impactViewNextMonthSysRevenue, impactViewNextMonthAdjRevenue, impactViewNextMonthVariance;
        if(this.impactViewCurrentMonth) {
          impactViewCurrentMonthBudget =  this.impactViewCurrentMonth + ' - Budget (Sett)';
          impactViewCurrentMonthSysRevenue = this.impactViewCurrentMonth + ' - System Revenue (Sett)';
          impactViewCurrentMonthAdjRevenue = this.impactViewCurrentMonth + ' - Adjusted Revenue (Sett)';
          impactViewCurrentMonthVariance = this.impactViewCurrentMonth + ' - Variance';
        } else {
          impactViewCurrentMonthBudget =  'Budget (Sett)';
          impactViewCurrentMonthSysRevenue = 'System Revenue (Sett)';
          impactViewCurrentMonthAdjRevenue = 'Adjusted Revenue (Sett)';
          impactViewCurrentMonthVariance = 'Variance';
        }

        if(this.impactViewNextMonth) {
          impactViewNextMonthBudget =  this.impactViewCurrentMonth + ' - Budget (Sett)';
          impactViewNextMonthSysRevenue = this.impactViewCurrentMonth + ' - System Revenue (Sett)';
          impactViewNextMonthAdjRevenue = this.impactViewCurrentMonth + ' - Adjusted Revenue (Sett)';
          impactViewNextMonthVariance = this.impactViewCurrentMonth + ' - Variance';
        } else {
          impactViewNextMonthBudget =  'Budget (Sett)';
          impactViewNextMonthSysRevenue = 'System Revenue (Sett)';
          impactViewNextMonthAdjRevenue = 'Adjusted Revenue (Sett)';
          impactViewNextMonthVariance = 'Variance';
        }
    this.impactViewHeaderColumns = [
      'Business Unit',
      'Settlement Currency',
      impactViewCurrentMonthBudget,
      impactViewCurrentMonthSysRevenue,
      impactViewCurrentMonthAdjRevenue,
      impactViewCurrentMonthVariance,
      impactViewNextMonthBudget,
      impactViewNextMonthSysRevenue,
      impactViewNextMonthAdjRevenue,
      impactViewNextMonthVariance
    ];
  }

  autoCompleteTransactionNumbers(): void {
    let viewType, historyYear, historyMonth;
    
    this.isTransactionNumberOptionsExists = true;
    if (this.searchTransactionNumber.length < 3) {
      return;
    }
    if(this.viewType == 'Current') {
      viewType = 0;
    } else {
      viewType = 1;
      historyYear = this.historyYear;
      historyMonth = this.monthFullNameOptions.indexOf(this.historyMonth) + 1;
    }
    this.transactionNumberOptions = this.dataCaptureFormsService
      .getTransactionNumbers(this.searchTransactionNumber, 'adjustment', viewType, historyYear, historyMonth)
      .pipe(
        map(transactionNumbers => this.filterTransactionNumbers(transactionNumbers))
      );
  }

  filterTransactionNumbers(values) {
    if(values.length) {
      this.isTransactionNumberOptionsExists = true;
      return values.filter(trNo =>
        trNo.transactionNumber.toLowerCase().includes(this.searchTransactionNumber.toLowerCase())
      )
    } else {
      this.isTransactionNumberOptionsExists = false;
    }
  }

  transactionNumberBlur(e: Event): void {

    /*if (this.searchTransactionNumber) {
      this.dataCaptureFormsService
        .getTransactionNumberDetails(this.searchTransactionNumber, 'Deferral')
        .subscribe(
          data => {
            console.log(data);
          }
        );
    } else {
      
    }*/
  }

  autoCompleteInsureds(): void {

    if (this.searchInsuredName.length < 3) {
      this.isInsuredOptionsExists = true;
      return;
    }
    this.insuredOptions = this.adjService
      .getSummaryInsureds(this.searchInsuredName)
      .pipe(
        map(insureds => this.filterInsureds(insureds))
      );
  }

  filterInsureds(values) {
    let insuredName = this.searchInsuredName;
    if(values.length) {
      this.isInsuredOptionsExists = true;
      return values.filter(insured =>
        insured['name'].toLowerCase().includes(insuredName.toLowerCase())
      )
    } else {
      this.isInsuredOptionsExists = false;
    }
  }

  insuredBlur(e: Event): void {

    /*this.insuredOptions.subscribe(
      data => {
        console.log(data)
      }
    );*/
  }

  filterBusinessUnit(filterValue: string) {
    this.filteredImpactViewData = this.impactViewData.filter(
      (data: any) => {
        return data.businessUnit.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
      }
    );
  }

  viewSelectionChange(e: Event): void{
    this.utilsService.adjustmentsViewType = this.viewType;
    this.searchInsuredName = "";
    this.searchTransactionNumber = "";
    this.searchAdjustmentType = [1,2,3,4,5];
    if( this.viewType === 'History') {
      this.getHistoryRecords();
    } else {
      this.loadCurrentViewTables();
    }
  }

  yearSelectionChange(e: Event): void{

    //@ToDo: Remove this if not used as this value is being captured on button clicks for search and get history records
    // this.utilsService.adjustmentsHistoryYear = this.historyYear;
    this.adjService.getHistoryMonths(this.historyYear)
      .subscribe(
        data => {
           this.historyMonthOptions = data;
           this.isHistoryMonthOptionDisabled = this.historyMonthOptions.length > 0 ? false: true;
        }
      );
  }

  monthSelectionChange(e: Event): void {
    
    //@ToDo: Remove this if not used as this value is being captured on button clicks for search and get history records
    //this.utilsService.adjustmentsHistoryMonth = this.historyMonth;
    this.searchTransactionNumber = null;
    this.searchInsuredName = null;
    this.searchAdjustmentType = [1,2,3,4,5];
  }

  async loadHistoryViewTables() {
      this.otherHistoryAdjSpans = [];
      this.approvedHistoryAdjSapns = [];
      this.utilsService.showLoadingIcon(true);
      let statusHistoryOthersSummary: object = {
      resultFor: 0, 
      year: this.historyYear, 
      month: this.historyMonth, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: 10, 
      page: 1
    };
    
    let statusHistoryApprovedSummary: object = {
      resultFor: 1, 
      year: this.historyYear, 
      month: this.historyMonth, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: 10, 
      page: 1
    };

    this.adjService.getHistoryTablesData(statusHistoryOthersSummary, statusHistoryApprovedSummary)
      .subscribe(
        responseList => {
          this.loadHistoryOtherAdjustemntTable(responseList[0]);
          this.loadHistoryApprovedadjustmentTable(responseList[1]);
          this.utilsService.showLoadingIcon(false);
        },
        error => {
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  loadCurrentViewTables(): void{
    this.statusInProgressspans = [];
    this.statusToReviewspans = [];
    this.utilsService.adjustmentsHistoryMonth=null;
    this.utilsService.adjustmentsHistoryYear=null;
    this.utilsService.showLoadingIcon(true);
    let statusToReviewRequestData = {
      resultFor: 0, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: 10, 
      page:1
    };

    let statusInProgressRequestData = {
      resultFor: 1, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: 10,
      page:1
    };

    this.adjService.getAdjustmentTablesData(statusToReviewRequestData, statusInProgressRequestData, true)
    .subscribe(
      responseList => {
        if(responseList[0]) {
          this.loadStatusToReviewSummayTable(responseList[0]);
        }
        if(responseList[1]) {
          this.loadStatusInProgressSummayTable(responseList[1]);
        }
        if(responseList[2]) {
          this.loadImpactViewTable(responseList[2]);
        }
        this.utilsService.showLoadingIcon(false);
    },
    error => {
      this.utilsService.showLoadingIcon(false);
    }); 
  }

  loadStatusToReviewSummayTable(responseData) {
    let data = responseData.body;
    this.statusToReviewDataSource = this.dataFormatter(data);
    this.cacheSpan('Id', d => d.requestId, this.statusToReviewDataSource, this.statusToReviewspans);
    this.statusesToReviewSummary = new MatTableDataSource(this.statusToReviewDataSource);
    // Paginatior get header details
    this.statusInReveiwPaginationOptions['page'] = +responseData.headers.get('x-page');
    this.statusInReveiwPaginationOptions['pageSize'] = +responseData.headers.get('x-page-size');
    this.statusInReveiwPaginationOptions['totalCount'] = +responseData.headers.get('x-total-count');
    this.statusInReveiwPaginationOptions['totalPages'] = +responseData.headers.get('x-total-pages');
  }

  loadStatusInProgressSummayTable(responseData) {
    let data = responseData.body;
    this.statusInProgressDataSource = this.dataFormatter(data);
    this.cacheSpan('Id', d => d.requestId, this.statusInProgressDataSource, this.statusInProgressspans);
    this.statusesInProgressSummary = new MatTableDataSource(this.statusInProgressDataSource);
    //Paginator get Header Details
    this.statusInProgressPaginationOptions['page'] = +responseData.headers.get('x-page');
    this.statusInProgressPaginationOptions['pageSize'] = +responseData.headers.get('x-page-size');
    this.statusInProgressPaginationOptions['totalCount'] = +responseData.headers.get('x-total-count');
    this.statusInProgressPaginationOptions['totalPages'] = +responseData.headers.get('x-total-pages');
  }

  loadImpactViewTable(data) {
    this.impactViewData = data['impactList'];
    this.filteredImpactViewData = data['impactList'];
    this.setCurrentNextMonthImpactView(data);
    this.setImpactViewHeaderColumns();
  }

  loadHistoryApprovedadjustmentTable(response) {
    this.approvedHistoryAdjSapns = [];
    let data = response.body;
    this.approvedHistoryAdjustments = this.dataFormatter(data);
    this.cacheSpan('Id', d => d.requestId, this.approvedHistoryAdjustments, this.approvedHistoryAdjSapns);
    this.statusesHistoryApprovedSummary = new MatTableDataSource(this.approvedHistoryAdjustments);
    //Paginator get Header Details
    this.statusHistoryApprovedSummaryOptions['page'] = +response.headers.get('x-page');
    this.statusHistoryApprovedSummaryOptions['pageSize'] = +response.headers.get('x-page-size');
    this.statusHistoryApprovedSummaryOptions['totalCount'] = +response.headers.get('x-total-count');
    this.statusHistoryApprovedSummaryOptions['totalPages'] = +response.headers.get('x-total-pages');
  }

  loadHistoryOtherAdjustemntTable(response) {
    this.otherHistoryAdjSpans = [];
    let data = response.body;
    this.otherHistoryAdjustmentsDataSource = this.dataFormatter(data);
    this.cacheSpan('Id', d => d.requestId, this.otherHistoryAdjustmentsDataSource, this.otherHistoryAdjSpans);
    this.statusesHistoryOthersSummary = new MatTableDataSource(this.otherHistoryAdjustmentsDataSource);
    //Paginator get Header Details
    this.statusHistoryOtherSummaryOptions['page'] = +response.headers.get('x-page');
    this.statusHistoryOtherSummaryOptions['pageSize'] = +response.headers.get('x-page-size');
    this.statusHistoryOtherSummaryOptions['totalCount'] = +response.headers.get('x-total-count');
    this.statusHistoryOtherSummaryOptions['totalPages'] = +response.headers.get('x-total-pages');
  }

  getHistoryRecordsBtnClick(e: Event): void{
    this.utilsService.showLoadingIcon(true);
    this.utilsService.adjustmentsHistoryYear = this.historyYear;
    this.utilsService.adjustmentsHistoryMonth = this.historyMonth;
    this.searchTransactionNumber = null;
    this.searchInsuredName = "";
    this.searchAdjustmentType = [1,2,3,4,5];
    this.utilsService.adjustmentsSearchTransactionNumber = this.searchTransactionNumber;
    this.utilsService.adjustmentsSearchInsuredName = this.searchInsuredName;

    if(this.viewType=='History'){
      if(!this.historyYear || !this.historyMonth){
        this.statusesHistoryOthersSummary = null;
        this.statusesHistoryApprovedSummary = null;
        this.utilsService.showMessageTip('Please provide value of Year and Month both to view History records');
        return;
      }else{
        this.loadHistoryViewTables();
      }
    }else{
      this.loadCurrentViewTables();
    }
  }

  getHistoryRecords() {
    this.utilsService.showLoadingIcon(true);
    this.adjService.getHistoryYears()
      .subscribe(
        data => {
          this.historyYearOptions = data.reverse();
          this.isHistoryYearOptionDisabled = this.historyYearOptions.length > 0 ? false : true;
          if(this.historyYearOptions.length > 0) {
            this.historyYear=(this.utilsService.adjustmentsHistoryYear!=null)?this.utilsService.adjustmentsHistoryYear: this.historyYearOptions[0];
           
           // this.historyYear = this.historyYearOptions[0];
            this.adjService.getHistoryMonths(this.historyYear)
            .subscribe(
              data => {
                this.historyMonthOptions = data.reverse();
                this.isHistoryMonthOptionDisabled = this.historyMonthOptions.length > 0 ? false: true;
                if( this.historyMonthOptions.length > 0 ) {
                  this.historyMonth = (this.utilsService.adjustmentsHistoryMonth!=null)?this.utilsService.adjustmentsHistoryMonth: this.historyMonthOptions[0];
                  this.loadHistoryViewTables();
                } else {
                  this.utilsService.showLoadingIcon(false);
                }
              }
            )
          } else {
            this.utilsService.showLoadingIcon(false);
          }
        }
      );
  }

  searchRecordsBtnClick(e: Event, form:NgForm): void {
    if(form.valid) {
      this.searchRecords();
    }
  }

  searchRecords() {
    this.utilsService.adjustmentsHistoryYear = this.historyYear;
      this.utilsService.adjustmentsHistoryMonth = this.historyMonth;
      this.utilsService.adjustmentsSearchTransactionNumber = this.searchTransactionNumber;
      this.utilsService.adjustmentsSearchInsuredName = this.searchInsuredName;
  
      if (this.viewType == 'History') {
        if (!this.historyYear || !this.historyMonth) {
          this.statusesHistoryOthersSummary = null;
          this.statusesHistoryApprovedSummary = null;
          this.utilsService.showMessageTip('Please provide value of Year and Month both to view History records');
          return;
        } else {
          this.loadHistoryViewTables();
        }
      } else {
        this.loadCurrentViewTables();
      }
  }

  resetSearchFields (e: Event) {
    this.searchInsuredName = "";
    this.searchTransactionNumber = "";
    this.searchAdjustmentType = [1,2,3,4,5];
    this.searchRecords();
  }

  isRecordsFound (obj: object) {
    let recordsFound: boolean = false;
    if(obj && obj['data']) {
      recordsFound = obj['data'].length > 0 ? true : false;
    } else {
      recordsFound = false;
    }
    return recordsFound;
  }

  adjustMentToReviewPageEvent (e: Event) {
    this.utilsService.showLoadingIcon(true);
    let statusToReviewRequestData = {
      resultFor: 0, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: e['pageSize'], 
      page: e['pageIndex']+1
    };
    this.adjService.getAdjustmentTablesData(statusToReviewRequestData, {}, false). subscribe(
        (responseList) => {
          this.statusToReviewspans = [];
          let data = responseList[0]['body'];
          this.statusToReviewDataSource = this.dataFormatter(data);
          this.cacheSpan('Id', d => d.requestId, this.statusToReviewDataSource, this.statusToReviewspans);
          this.statusesToReviewSummary = new MatTableDataSource(this.statusToReviewDataSource);
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  adjustMentInProgressPageEvent (e: Event) {
    this.utilsService.showLoadingIcon(true);
    let statusInProgressRequestData = {
      resultFor: 1, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: e['pageSize'], 
      page: e['pageIndex']+1
    };
    this.adjService.getAdjustmentTablesData( {},statusInProgressRequestData, false).subscribe(
        (responseList) => {
          this.statusInProgressspans = [];
          let data = responseList[0]['body'];
          this.statusInProgressDataSource = this.dataFormatter(data);
          this.cacheSpan('Id', d => d.requestId, this.statusInProgressDataSource, this.statusInProgressspans);
          this.statusesInProgressSummary = new MatTableDataSource(this.statusInProgressDataSource);
          this.utilsService.showLoadingIcon(false);
        },
        error => {
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  statusHistoryOtherSummaryPageEvent (e: Event) {
    this.utilsService.showLoadingIcon(true);
    let statusHistoryOthersSummary: object = {
      resultFor: 0, 
      year: this.historyYear, 
      month: this.historyMonth, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: e['pageSize'], 
      page: e['pageIndex']+1
    };

    this.adjService.getHistoryTablesData(statusHistoryOthersSummary, {}).subscribe(
        response => {
          this.otherHistoryAdjSpans = [];
          let data = response[0]['body'];
          this.otherHistoryAdjustmentsDataSource = this.dataFormatter(data);
          this.cacheSpan('Id', d => d.requestId, this.otherHistoryAdjustmentsDataSource, this.otherHistoryAdjSpans);
          this.statusesHistoryOthersSummary = new MatTableDataSource(this.otherHistoryAdjustmentsDataSource);
          this.utilsService.showLoadingIcon(false);
        },
        error => {
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  statusHistoryApprovedSummaryPageEvent (e: Event) {
    this.utilsService.showLoadingIcon(true);
    let statusHistoryApprovedSummary: object = {
      resultFor: 1, 
      year: this.historyYear, 
      month: this.historyMonth, 
      transactionNumber: this.searchTransactionNumber, 
      insuredName: this.searchInsuredName, 
      type: this.searchAdjustmentType.join(','), 
      pageSize: e['pageSize'], 
      page: e['pageIndex']+1
    };
    this.adjService.getHistoryTablesData({}, statusHistoryApprovedSummary).subscribe(
        response => {
          this.approvedHistoryAdjSapns = [];
          let data = response[0]['body'];
          this.approvedHistoryAdjustments = this.dataFormatter(data);
          this.cacheSpan('Id', d => d.requestId, this.approvedHistoryAdjustments, this.approvedHistoryAdjSapns);
          this.statusesHistoryApprovedSummary = new MatTableDataSource(this.approvedHistoryAdjustments);
          this.utilsService.showLoadingIcon(false);
        },
        error => {
          this.utilsService.showLoadingIcon(false);
        }
      );
  }

  cacheSpan(key, accessor, matDataSource, spans) {
    for (let i = 0; i < matDataSource.length;) {
      let currentValue = accessor(matDataSource[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < matDataSource.length; j++) {
        if (currentValue != accessor(matDataSource[j])) {
          break;
        }

        count++;
      }

      if (!spans[i]) {
        spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      spans[i][key] = count;
      i += count;
    }
    console.log(key, spans)
  }

  getRowSpan(col, index, spans) {    
    return spans[index] && spans[index][col];
  }

  dataFormatter(data) {
    let formattedData;
    formattedData = data.reduce((current, next) => {
      next.currenciesAmounts.forEach(b => {
        current.push({ requestId: next.requestId, type: next.type, insuredName: next.insuredName, currency: b.code, amount : b.amount.toFixed(2),description : next.description,costCentre : b.costCentre, status :next.status, processedBy :next.processedBy })
      });
      return current;
    }, []);
    return formattedData;
  }

  initializePaginators() {

    this.statusesToReviewSummary = new MatTableDataSource([]);
    this.statusesToReviewSummary['paginator'] = this.statusInReviewPaginatior;

    this.statusesInProgressSummary = new MatTableDataSource([]);
    this.statusesInProgressSummary['paginator'] = this.statusInProgressPaginatior;

    this.statusesHistoryOthersSummary = new MatTableDataSource([]);
    this.statusesHistoryOthersSummary['paginator'] = this.statusHistoryOthersSummaryPaginator;

    this.statusesHistoryApprovedSummary = new MatTableDataSource([]);
    this.statusesHistoryApprovedSummary['paginator'] = this.statusHistoryApprovedSummaryPaginator;
  }

  AddBackground(rowData:any){
    let elements:any = document.getElementsByClassName('active'+rowData.requestId);
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add('add-background')
    }
  }
  RemoveBackground(rowData:any){
    let elements:any = document.getElementsByClassName('active'+rowData.requestId);
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('add-background')
    }
  }

  exportCurrentViewTables(viewType) {
    let exportCurrentViewTables = {
      type: this.searchAdjustmentType.join(','), 
    };

    let exportHistoryViewTables = {
      type: this.searchAdjustmentType.join(','), 
      year: this.historyYear, 
      month: this.historyMonth, 
    };
    this.utilsService.showLoadingIcon(true)
    if(viewType == 'Current') {
      this.adjService.exportTableData(exportCurrentViewTables, viewType).subscribe((response) => {
        this.downloadFile(response);
      });
    } else {
      this.adjService.exportTableData(exportHistoryViewTables, viewType).subscribe((response) => {
        this.downloadFile(response);
      });
    }
    
  }

  downloadFile(response) {
    const blob = new Blob([response.body]);
    const fileName = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace('"', '').replace('"', '');
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('visibility', 'hidden');
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    this.utilsService.showLoadingIcon(false);
  }
}
